import React from 'react';
import { Link } from 'react-router-dom';
import SEO from './../../layouts/SEO';
import JoiningMemberCard from './../Homepage/JoiningMemberCard';
import Testimonials from './../Homepage/Testimonials';
import DownloadApp from './../Homepage/DownloadApp';



function AboutUs() {
  return (
    <>
      <SEO
        title="Welcome to – irevize"
        description="We are dedicated to providing high-quality educational resources and services to learners of all ages and backgrounds."
        keywords="Download LEARNING MATERIAL, Download PRACTICE TESTS"
        canonical="http://irevize.com/about-us"
      >
      </SEO>
      <div className="breadcrumb_section  page-title-mini">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="/" title='Home'>Home</Link></li>
                <li className="breadcrumb-item active" title='About Us'>About Us</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row align-items-center prod-detail">
                  <div className="col-12">
                    <h1 className="btn-shine text-center">About Us</h1>
                    <h2 className="btn-shine mt-30">Welcome to www.irevize.com</h2>
                    <p>
                      We are dedicated to providing high-quality educational resources and services to
                      learners of all ages and backgrounds.
                    </p>

                    <p>
                      Our mission is to empower individuals to reach their full potential through education.
                      We believe that education is a lifelong journey that should be accessible to everyone.
                      That's why we offer a wide range of practice tests and learning material that cater to the diverse needs and interests of our learners.
                    </p>

                    <p>
                      Whether you are looking to prepare for your school's exam, entrance exams,
                      competitive exam or university exams we have all kinds of preparation material for you.
                    </p>

                    <p>
                      Our team is composed of experienced educators, administrators,
                      and support staff who are committed to providing the best possible learning experience for our students.
                    </p>

                    <p>
                      Thank you for considering www.irevize.com as your partner in learning.
                      We look forward to helping you achieve your educational goals!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <JoiningMemberCard />
        </div>

        <Testimonials></Testimonials>
        {/* <DownloadApp /> */}
      </div>
    </>
  );
}
export default AboutUs;
