import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Exams() {
    const [exams, setExams] = useState(false);
    useEffect(() => {
        getExams()
    }, []);

    /**
     * Fetching data from server.
     */
    const getExams = async () => {
        try {
            axios.post('/api/frontend/get-exams')
                .then(response => {
                    if (response.data.response_code === 200) {
                        setExams(response.data.data);
                    } else if (response.data.response_code === 401) {
                    } else {
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (e) {
        }
    }
    const options = {
        nav: false,
        dots: false,
        // autoplay: true,
        loop: true,
        // padding: 20,
        margin: 20,
        responsiveClass: true,
        navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 3,
                dots: true,
                nav: false,
            },
            1000: {
                items: 10,
            }
        },
    };
    return (
        <>
            {exams.length ? (
                <div className="section shop-top-cat-sec">
                    <div className="container sctnheader">
                        <h3 className="btn-shine">Elevate Your Learning with Premium Sample Papers and Notes!</h3>
                        <p>Choose material in English and many other languages</p>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {exams && (
                                    <OwlCarousel className='professional_skills product_slider procat owl-theme'
                                        {...options}
                                    >
                                        {
                                            exams.map((row, index) => (
                                                <div className="item" key={index}>
                                                    <Link to={`/search?q=&selected_organization=${row.selected_organization}&selected_competitive_exam=${row.id}`} title={row.exam_name} >
                                                        <div className="product">
                                                            <div className="product_img">
                                                                <img src={row.icon_image} alt={''} style={{ maxWidth: "120px" }} />
                                                            </div>
                                                            <div className="product_info">
                                                                <h6 className="product_title">
                                                                    {row.exam_name && row.exam_name.split('(', 1)}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                    </OwlCarousel>
                                )}
                            </div>
                        </div>
                    </div>
                </div >
            ) : ''
            }
        </>
    );
}
export default Exams;
