/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@mui/material';
import Input from '../../../Input/Input';

import swal from 'sweetalert';
import axios from 'axios';

// import './EditProfile.scss';

function EditBankingInfo({ userProfileInfo, open, setOpen, setBankingInfoUpdate }) {

  const handleClose = () => setOpen(false);
  const [formData, setFormData] = useState(userProfileInfo);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setFormData(userProfileInfo);
  }, [userProfileInfo]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/user/update-user-banking-info', formData).then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.status_code === 200) {
            swal({
              text: data.message,
              icon: "success",
              buttons: false,
              timer: 2000
            });
            setOpen(false);
            setBankingInfoUpdate(true);
          } else if (data.status_code === '401' || data.status_code === 401) {
            swal({
              text: data.message,
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <div className="editProfile">
        <div className="editProfile__head">
          <h3>Edit Banking Info</h3>
        </div>
        <div className="editProfile__content">
          <div className="editProfile__details">
            <div className="editProfile__details__row">
              <div className="editProfile__details__query">
                <p>
                  Account Holder Name
                  <span>*</span>
                </p>
                <Input name="account_holder_name" label="Account Holder Name" value={formData.account_holder_name} handleChange={handleChange} />
              </div>
              <div className="editProfile__details__query">
                <p>
                  Account Number
                </p>
                <Input type="number" name="account_number" label="Account Number" value={formData.account_number}
                  // handleChange={handleChange}
                  handleChange={(e) => {
                    if (e.target.value.length > 17) {
                      return false;
                    } else {
                      handleChange(e)
                    }
                  }}
                />

              </div>
            </div>
            <div className="editProfile__details__row">
              <div className="editProfile__details__query">
                <p>
                  Bank Name
                </p>
                <Input name="bank_name" label="Bank Name" value={formData.bank_name} handleChange={handleChange} />
              </div>
              <div className="editProfile__details__query">
                <p>
                  IFSC code
                </p>
                <Input name="ifsc_code" label="IFSC code" value={formData.ifsc_code}
                  handleChange={(e) => {
                    if (e.target.value.length > 11) {
                      return false;
                    } else {
                      handleChange(e)
                    }
                  }}
                />
              </div>
            </div>
            <div className="editProfile__details__row">
              <div className="editProfile__details__query" style={{ width: '100%' }}>
                <p>GST Number</p>
                <Input name="gst_number" label="GST Number" value={(formData.gst_number) ? formData.gst_number : ''}
                  handleChange={(e) => {
                    if (e.target.value.length > 15) {
                      return false;
                    } else {
                      handleChange(e)
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="editProfile__buttons">
            <button type="button" className="editProfile__cancelbtn" onClick={handleClose}>Cancel</button>
            <button type="button" className="editProfile__savebtn" onClick={handleSubmit}>Save</button>
          </div>
        </div>
      </div>
    </Dialog>

  );
}

export default EditBankingInfo;
