import React from 'react';
import { Link } from 'react-router-dom';
import SEO from './../../layouts/SEO';

function Faq() {
  return (
    <>
      <SEO
        title="FAQ – irevize"
        description="FAQ about irevize!"
        keywords="Download LEARNING MATERIAL, Download PRACTICE TESTS"
        canonical="http://irevize.com/faqs"
      >
      </SEO>
      <div className="breadcrumb_section  page-title-mini">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="/" title='Home'>Home</Link></li>
                <li className="breadcrumb-item active" title='Faq`s'>Faq`s</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row align-items-center prod-detail">
                  <div className="col-12">
                    <h1 className="btn-shine text-center">Faq`s</h1>
                    <h2 className="btn-shine mt-30">Why you should make an FAQ page</h2>
                    <p>This reason might be the most obvious, but it’s a clear benefit of an FAQ page. Not only does the FAQ page save customers
                      time, but it also saves employees time as well. Having an FAQ page ensures customers don’t have to sit on the
                      phone for hours waiting for simple answers and employees don’t have to individually answer all
                      questions.
                      Companies dedicate a large portion of their budgets to customer service teams. When these teams don’t have to focus on answering the frequently asked questions, they can serve other customer issues in a more timely manner.
                    </p>

                    <h3 className="btn-shine mt-30">FAQ</h3>
                    <ul className="check-prolist">
                      <li>
                        Saves time
                        <div className="mt-30 prodbgbox">
                          <h3 className="btn-shine mt-30 text-center">Mathematics Skills you will learn</h3>
                          <p>This reason might be the most obvious, but it’s a clear benefit of an FAQ page. Not only does the FAQ page save customers
                            time, but it also saves employees time as well. Having an FAQ page ensures customers don’t have to sit on the
                            phone for hours waiting for simple answers and employees don’t have to individually answer all
                            questions.
                            Companies dedicate a large portion of their budgets to customer service teams. When these teams don’t have to focus on answering the frequently asked questions, they can serve other customer issues in a more timely manner.
                          </p>
                        </div>
                      </li>
                      <li>Earns trust

                        <div className="mt-30 prodbgbox">
                          <h3 className="btn-shine mt-30 text-center">Mathematics Skills you will learn</h3>
                          <p>This reason might be the most obvious, but it’s a clear benefit of an FAQ page. Not only does the FAQ page save customers
                            time, but it also saves employees time as well. Having an FAQ page ensures customers don’t have to sit on the
                            phone for hours waiting for simple answers and employees don’t have to individually answer all
                            questions.
                            Companies dedicate a large portion of their budgets to customer service teams. When these teams don’t have to focus on answering the frequently asked questions, they can serve other customer issues in a more timely manner.
                          </p>
                        </div>
                      </li>
                      <li>Provides new insights
                        <div className="mt-30 prodbgbox">
                          <h3 className="btn-shine mt-30 text-center">Mathematics Skills you will learn</h3>
                          <p>This reason might be the most obvious, but it’s a clear benefit of an FAQ page. Not only does the FAQ page save customers
                            time, but it also saves employees time as well. Having an FAQ page ensures customers don’t have to sit on the
                            phone for hours waiting for simple answers and employees don’t have to individually answer all
                            questions.
                            Companies dedicate a large portion of their budgets to customer service teams. When these teams don’t have to focus on answering the frequently asked questions, they can serve other customer issues in a more timely manner.
                          </p>
                        </div>
                      </li>
                      <li>Drives internal page views
                        <div className="mt-30 prodbgbox">
                          <h3 className="btn-shine mt-30 text-center">Mathematics Skills you will learn</h3>
                          <p>This reason might be the most obvious, but it’s a clear benefit of an FAQ page. Not only does the FAQ page save customers
                            time, but it also saves employees time as well. Having an FAQ page ensures customers don’t have to sit on the
                            phone for hours waiting for simple answers and employees don’t have to individually answer all
                            questions.
                            Companies dedicate a large portion of their budgets to customer service teams. When these teams don’t have to focus on answering the frequently asked questions, they can serve other customer issues in a more timely manner.
                          </p>
                        </div>
                      </li>
                      <li>Prevents negative reviews
                        <div className="mt-30 prodbgbox">
                          <h3 className="btn-shine mt-30 text-center">Mathematics Skills you will learn</h3>
                          <p>This reason might be the most obvious, but it’s a clear benefit of an FAQ page. Not only does the FAQ page save customers
                            time, but it also saves employees time as well. Having an FAQ page ensures customers don’t have to sit on the
                            phone for hours waiting for simple answers and employees don’t have to individually answer all
                            questions.
                            Companies dedicate a large portion of their budgets to customer service teams. When these teams don’t have to focus on answering the frequently asked questions, they can serve other customer issues in a more timely manner.
                          </p>
                        </div>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Faq;
