import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Button, Grid, Card, CardHeader, Divider, CardContent,
  styled, Table, TableBody, TableRow, TableCell,
  Alert, AlertTitle
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Moment from 'moment';
import axios from 'axios';
import { useCart } from '../../../contexts/CartContext';
const StyledTable = styled(Table)(() => ({
  minWidth: 200,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  // '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  '& th': { background: "#003152", color: "#FFF" },
}));

function OrderSuccess() {
  const params = useParams();
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const { order, setOrder } = useCart();
  const [order_id, setOrderId] = useState(params.order_id);
  useEffect(() => {
    fetchData();
  }, [order_id]);
  /**
     * Fetching data from server.
     */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/get-order-details', {
          order_id: order_id
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setOrder(response.data.data);
            } if (response.data.response_code === 401) {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  const permalink = (row) => {
    var subject = row.subject_name;
    var subject_slug = (row.subject_slug) ? row.subject_slug : subject.replace(' ', '-').toLowerCase();
    if (row.type === 'practice') {
      var url = `/samplepaper/paper-detail/${subject_slug}/${row.slug}`;
  } else {
      var url = `/notes/paper-detail/${subject_slug}/${row.slug}`;
  }
    return url;
  }
  return (
    <>
      <div className="breadcrumb_section  page-title-mini">
        <div className="container">
          <div className="row">

            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Order Success</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content checkout">
        <div className="section">
          <div className="container">
            {order_id && order ? (
              <>
                <h1 className="btn-shine">Order Success</h1>
                <Grid container item rowSpacing={2} columnSpacing={4}>
                  <Grid item lg={8} sm={12} xs={12}>
                    <Card>
                      <CardHeader
                        title={`${(order.order_items) ? order.order_items.length : '0'} items in this order`}
                        titleTypographyProps={{
                          variant: "h2",
                          fontSize: '16px',
                          fontWeight: '600',
                        }}
                        sx={{ background: "#f4f4f4" }} />
                      <Divider />
                      <CardContent style={{ padding: "0px" }}>
                        <StyledTable>
                          <TableBody>
                            {order && order.order_items && (
                              order.order_items.map((row, index) => (
                                <TableRow key={`tbl-cart-${index}`}>
                                  <TableCell align="left" sx={{ width: "120px" }}>
                                    <Link to={permalink(row)}>
                                      <img src={(row.thumb_nail_url ? row.thumb_nail_url : ((row.thumb_nail_image) ?? row.thumb_nail_image))} alt={row.topic_title} />
                                    </Link>
                                  </TableCell>
                                  <TableCell align="left" title={row.topic_title}>
                                    <h6 className="product_title">
                                      <Link to={permalink(row)} title={row.topic_title}>
                                        {row.topic_title.replace(/(.{50})..+/, "$1…")}
                                      </Link>
                                    </h6>
                                    <span>{row.author_name}</span>
                                    <div className="rating_wrap">
                                      <span className="product_title">
                                        {(() => {
                                          switch (row.organization_type_id) {
                                            case (1 || '1'):
                                              return `${row.class_name}`;
                                            case (2 || '2'):
                                              return `${row.university_name}`;
                                            case (3 || '3'):
                                              return `${row.entrance_exam_name}`;
                                            case (4 || '4'):
                                              return `${row.competitive_exam_name}`;
                                            default:
                                              return null;
                                          }
                                        })()}
                                      </span>
                                      {/* <span className="rating_num">({row.avg_rating})</span>
                                      <div className="rating">
                                        <div className="product_rate" style={{ width: "80%" }}></div>
                                      </div>
                                      <Button disableElevation sx={{ color: "rgb(0 49 82) !important", textTransform: "capitalize" }}>
                                        ({row.total_rating} Rating)
                                      </Button> */}
                                    </div>
                                  </TableCell>
                                  <TableCell align="right">
                                    <br />
                                    {/* <Button title='Add to Wishlist' sx={{ color: "rgb(0 49 82) !important", textTransform: "capitalize" }}> Save For Later</Button> */}
                                  </TableCell>
                                  <TableCell align="center" sx={{ width: "60px" }}>
                                    <span className="price" >
                                      {process.env.REACT_APP_CURRENCY} {row.gst_total_selling_price}
                                    </span>
                                    <br />
                                    <span className="price cart-price-dsc" >
                                      {process.env.REACT_APP_CURRENCY} {row.gst_total_price}
                                    </span>
                                    <span>{row.discount_percentage ? `(${row.discount_percentage}%)` : ''}</span>
                                  </TableCell>
                                </TableRow>
                              ))
                            )}
                          </TableBody >
                        </StyledTable>

                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item lg={4} sm={12} xs={12}>
                    <Card style={{ background: "rgb(237 247 237)" }}>
                      <CardHeader
                        title="You order is completed !!!"
                        titleTypographyProps={{
                          variant: "h2",
                          fontSize: '16px',
                          fontWeight: '600',
                          textAlign: "center",
                          color: "green"
                        }}
                      />
                      <Divider />
                      <CardContent style={{ padding: "0px" }}>
                        <Alert severity="success" icon={false} sx={{ textAlign: "center", width: "100%", display: "block", overflow: 'hidden' }}>
                          <br />
                          <CheckCircleIcon color="success" sx={{ textAlign: "center", margin: "0px auto", display: "block", fontSize: "60px" }} />
                          <AlertTitle color="success" sx={{ textAlign: "center", fontSize: "25px" }}>
                            Thank you for your order!
                          </AlertTitle>
                          {/* <p><strong>The order confirmation has been sent to your mobile number.</strong></p> */}
                          <br />
                          <StyledTable>
                            {order && (
                              <>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="left" sx={{ width: "120px" }}>
                                      Order ID:
                                    </TableCell>
                                    <TableCell align="right">
                                      <strong>{order.order_id}</strong>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left" sx={{ width: "120px" }}>
                                      Order Date:
                                    </TableCell>
                                    <TableCell align="right">
                                      <strong>{Moment(order.created_at).format('DD MMM YYYY')}</strong>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left" sx={{ width: "120px" }}>
                                      Original Price:
                                    </TableCell>
                                    <TableCell align="right">
                                      <strong>{process.env.REACT_APP_CURRENCY} {order.total_price}</strong>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left" sx={{ width: "120px" }}>
                                      <span className="price">Order Total: </span>
                                    </TableCell>
                                    <TableCell align="right">
                                      <span className="price">{process.env.REACT_APP_CURRENCY} {order.total_selling_price}</span>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left" sx={{ width: "120px" }}>
                                      Transaction ID
                                    </TableCell>
                                    <TableCell align="right">
                                      <strong>{(order.transaction_id) ? order.transaction_id : '-'}</strong>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="left" sx={{ width: "120px" }}>
                                      Payment Status:
                                    </TableCell>
                                    <TableCell align="right">
                                      <strong>{order.payment_status}</strong>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </>)}
                          </StyledTable>
                        </Alert>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </>
            ) : (
              <div>
                <Alert severity="success">Order details is loading!</Alert>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            )}
          </div >
        </div >
      </div >
    </>
  );
}
export default OrderSuccess;
