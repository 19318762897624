import React, { useState } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import {
  Link 
} from 'react-router-dom';
import {
  CardContent,
  Box,
  Dialog,
  DialogTitle, DialogContent, DialogActions,
  IconButton,
  Button,
} from "@mui/material";
// import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
const ContractAgreement = ({ open, setOpen, agreeBtn = true }) => {
  const [loading, setLoading] = useState();
  const handleClose = () => {
    setOpen(false);
  };
  const handleContractAgreement = () => {
    try {
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/user-agree-with-contract', {
          'contract_agree': '1'
        })
          .then(response => {
            if (response.data.response_code === 200) {
              swal({
                title: "Good job!",
                text: response.data.message,
                icon: "success",
              });
              localStorage.setItem('profile', JSON.stringify(response.data.data));
              setOpen(false);
            } else {
              swal({
                title: "Warning!",
                text: response.data.message,
                icon: "warning",
              });
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const handleDownloadContractAgreement = () => {

  };
  return (
    <Dialog
      // fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}>
      {/* {!agreeBtn ?? (
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Teacher Contract
        </BootstrapDialogTitle>
      )} */}
      <DialogContent dividers style={{ height: '600px', overflowY: 'auto' }}>
        <CardContent>
          <Box width="100%" overflow="auto" style={{ textAlign: 'justify' }}>
            <h2 className='btn-shine text-center'>Teacher Contract</h2>
            <p>
              This Freelancer Teacher Contract  is made between Irevize Services Private Limited and Agreeing freelancer.
            </p>
            <h3 className="btn-shine mt-30">Services</h3>
            <p>
              The Teacher agrees to provide educational content, including but not limited to course materials, lectures, videos, and assessments (collectively, “Content”), to the Platform for publication and use by students of the Platform.
            </p>

            <h3 className="btn-shine mt-30">Ownership</h3>
            <p>
              The Teacher represents and warrants that the Content is original and does not infringe any third-party rights, including but not limited to copyright, trademark, or trade secret. The Teacher retains ownership of the Content, subject to the license granted to the Platform under this Contract.
            </p>
            <h3 className="btn-shine mt-30">Rights to Data</h3>
            <p>
              All the uploaded data will be only visible on www.Irevize.com portal once its approved by Irevize Services Pvt. Ltd. Irevize Services Pvt. Ltd. has all the rights to reject or approve any material which is submitted for sale on www.irevize.com
            </p>
            <p>
              Irevize Services Pvt. Ltd. has rights to remove any data which has misleading, inappropriate, and false information regarding any topic or subject.
            </p>
            <h3 className="btn-shine mt-30">Compensation</h3>
            <p>
              The Platform agrees to pay the Teacher 70% for each sale of the Content uploaded by him on the Platform, as tracked by the Platform’s reporting system. The Platform may deduct any applicable taxes from the compensation.

            </p>
            <h3 className="btn-shine mt-30">Refund</h3>
            <p>
              In case a refund is requested by the buyer within 7 days of buying time. Refunds will be processed and the teacher is not liable for pay of that refund transaction. Refunds will only be processed in case the material uploaded by the Instructor has misleading, irrelevant or incorrect information. Teacher will be informed before any refund is processed and holds all the right to fight against the requested refund.
            </p>
            <h3 className="btn-shine mt-30">Payout</h3>
            <p>
              Payout for each transaction will be transferred to the teachers bank account 14 days after the sale date. In case of refund the sale amount will not be transferred.Your payout will only be credited to your account once your balance is over 500 INR.
            </p>

            <h3 className="btn-shine mt-30">Representations and Warranties</h3>
            <p>The Teacher represents and warrants that:</p>
            <ol className='contract-order-list'>
              <li>The Content is original and does not infringe any third-party rights.</li>
              <li>The Content is accurate, complete, and up-to-date.</li>
              <li>The Teacher has the right to enter into this Contract and grant the license to the Platform.</li>
            </ol>


            <h3 className="btn-shine mt-30">Term and Termination</h3>
            <p>
              This Contract shall commence on the day the teacher accepts the terms and condition and shall continue until terminated by Irevize Services Private Limited. Irevize Services Pvt. Ltd. hold the right to terminate this Contract immediately in the event of the Teacher’s material breach of this Contract or for other cause deemed sufficient by the Platform.
            </p>
            <h3 className="btn-shine mt-30">Confidentiality</h3>
            <p>
              During the term of this Contract and thereafter, the Teacher shall not disclose or use any confidential or proprietary information of the Platform, including but not limited to student records, without the prior written consent of the Platform.

            </p>
            <h3 className="btn-shine mt-30">Entire Agreement</h3>
            <p>
              This Contract constitutes the entire agreement between the parties and supersedes all prior agreements and understandings, whether written or oral, relating to the subject matter of this Contract.
            </p>
            <h3 className="btn-shine mt-30">License</h3>
            <p>
              The Teacher grants to the Platform a non-exclusive, worldwide, royalty-free license to use, reproduce, distribute, display, and perform the Content in connection with the Platform’s educational services, including but not limited to hosting, streaming, and downloading the Content by students of the Platform.
            </p>

          </Box>
        </CardContent>
      </DialogContent>
      <DialogActions>
        <Button  title="Download Teachers Contract" component={Link}
          to={`https://irevize.com/app-api/public/teachers-contract.pdf`}
          download="teachers-contract.pdf"
          target='_blank'
          color="success" variant="outlined" sx={{ m: 1 }}>
          Download
        </Button>
        
        {agreeBtn ? (
          <Button onClick={handleContractAgreement} variant="contained" autoFocus>
            Agree
          </Button>
        ) : (
          <Button onClick={handleClose} variant="contained" autoFocus > 
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default ContractAgreement;