import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import CartProvider from '../../contexts/CartContext';
import SearchFilterProvider from '../../contexts/SearchFilterContext';

import Header from '../../components/Frontend/Header/Header';
import Footer from '../../components/Frontend/Footer/Footer';

import SearchFilterLists from './../frontend/Search/SearchFilterLists';

import PaperDetail from './../frontend/PaperDetail/PaperDetail';
import ViewCart from './../frontend/Cart/ViewCart';
import Checkout from './../frontend/Checkout/Checkout';
import OrderSuccess from './../frontend/Checkout/OrderSuccess';
import OrderFailed from './../frontend/Checkout/OrderFailed';

import HomePage from './../frontend/Homepage/Home';
import TeachOnIrevizePage from './../frontend/Pages/TeachOnIrevizePage';


import AboutUs from './../frontend/Pages/AboutUs';
import ContactUs from './../frontend/Pages/ContactUs';
import RefundPolicy from './../frontend/Pages/RefundPolicy';
import PrivacyPolicy from './../frontend/Pages/PrivacyPolicy';
import TermsAndConditions from './../frontend/Pages/TermsAndConditions';
import Faq from './../frontend/Pages/Faq';

const FrontendLayout = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

  return (
    <>
      <CartProvider>
        <SearchFilterProvider>
          <Header />
          <div>
            {children}
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/teach-on-irevize" element={<TeachOnIrevizePage />} />
              <Route exact path="/join-now" element={<TeachOnIrevizePage />} />
              <Route exact path="/password/reset/:reset_token" element={<HomePage />} />
              <Route exact path="/about-us" element={<AboutUs />} />
              <Route exact path="/contact-us" element={<ContactUs />} />
              <Route exact path="/refund-policy" element={<RefundPolicy />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route exact path="/faqs" element={<Faq />} />
              <Route exact path="/search" element={<SearchFilterLists />} />
              <Route exact path="/search/:type" element={<SearchFilterLists />} />
              <Route exact path="/samplepaper/paper-detail/:slug" element={<PaperDetail />} />
              <Route exact path="/samplepaper/paper-detail/:subject/:slug" element={<PaperDetail />} />
              <Route exact path="/notes/paper-detail/:slug" element={<PaperDetail />} />
              <Route exact path="/notes/paper-detail/:subject/:slug" element={<PaperDetail />} />
              <Route exact path="/view-cart" element={<ViewCart />} />
              <Route exact path="/checkout" element={<Checkout />} />
              <Route exact path="/checkout/order-success/:order_id" element={<OrderSuccess />} />
              <Route exact path="/checkout/order-failed/:order_id" element={<OrderFailed />} />

            </Routes>
          </div>
          <Footer />
        </SearchFilterProvider>
      </CartProvider>
    </>
  );
};
export default FrontendLayout;