import axios from 'axios';

/**
 * Axios request without auth required!
 */

const baseURL =process.env.REACT_APP_API_BASE_PATH;

const API = axios.create({ baseURL: baseURL });
API.defaults.headers.post['Content-Type'] = 'application/json';
API.defaults.headers.post['Accept'] = 'application/json';



/**
 * Axios request with auth required! Passing login token to each request.
 */
const AUTHAPI = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});
AUTHAPI.defaults.headers.post['Content-Type'] = 'application/json';
AUTHAPI.defaults.headers.post['Accept'] = 'application/json';
AUTHAPI.interceptors.request.use(function (config) {
  const profile = JSON.parse(localStorage.getItem('profile'));
  const token = profile.token;
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

// API.interceptors.request.use((req) => {
//   if (localStorage.getItem('profile')) {
//     req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
//   }
//   return req;
// });

/**
 * SignIn api request.
 */
export const gmailSigninSignup = (userData) => {
  // API.get('/sanctum/csrf-cookie').then(async response => {
  return API.post('api/user/social-login', userData);
  // });
};


/**
 * SignIn api request.
 */
export const signIn = (formData) => {
  // API.get('/sanctum/csrf-cookie').then(async response => {
  return API.post('api/user/login', formData);
  // });
};

/**
 * SignOut api request.
 */
export const signOut = (formData) => {
  // API.get('/sanctum/csrf-cookie').then(async response => {
  return AUTHAPI.post('api/user/logout');
  // });
};

/**
 * SignUp api request.
 */
export const signUp = (formData) => {
  API.post('/user/signup', formData);
}


/**
 * SignIn api request.
 */
export const getProfessionalSkills = () => {
  // API.get('/sanctum/csrf-cookie').then(async response => {
  return API.post('api/frontend/get-professional-skills');
  // });
};
