import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import {
    Card,
    Divider,
    CardContent,
    CardHeader,
    Box, Grid, Dialog,
    DialogTitle, DialogContent, DialogActions,
    FormControl,
    Icon,
    IconButton,
    Button,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    CircularProgress,
    Avatar,
    MenuItem,
    Alert,
    useTheme,
    Rating,
    TextField,
    capitalize,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';
import StarsIcon from '@mui/icons-material/Stars';
// import { LoadingButton } from '@mui/lab';
import GroupIcon from '@mui/icons-material/Group';
import StarIcon from '@mui/icons-material/Star';

import RatingReviewModel from './RatingReview';
import Moment from 'moment';

const Title = styled('span')(() => ({
    fontSize: '1rem',
    fontWeight: '500',
    textTransform: 'capitalize',
}));

const Small = styled('small')(({ bgcolor }) => ({
    width: 50,
    height: 15,
    color: '#fff',
    padding: '2px 8px',
    borderRadius: '4px',
    overflow: 'hidden',
    background: bgcolor,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));
const Item = styled("div")(({ theme }) => (
    {
        marginBottom: "5px",
        fontSize: '16px',
        fontWeight: '400',
        color: '#484747'
    }
));
const ItemContent = styled("div")(({ theme }) => (
    {
        marginBottom: "15px",
        fontSize: '16px',
        fontWeight: '600',
    }
));

const StyledTable = styled(Table)(() => ({
    minWidth: 400,
    whiteSpace: 'pre',
    '& small': {
        width: 50,
        height: 15,
        borderRadius: 500,
        boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
    },
    // '& td': { borderBottom: 'none' },
    '& td:first-of-type': { paddingLeft: '16px !important' },
    '& th': { background: "#003152", color: "#FFF" },
}));

const Container = styled("div")(({ theme }) => (
    {
        margin: "20px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }
));
const ContentBox = styled(Box)(() => ({
    height: '100%',
    padding: '32px',
    position: 'relative',
    // background: 'rgba(0, 0, 0, 0.01)',
}));

const OrderHistory = () => {

    const { palette } = useTheme();
    const bgError = palette.error.main;
    const bgPrimary = palette.primary.main;
    const bgSecondary = palette.secondary.main;
    const bgSuccess = palette.success.main;
    const bgWarning = palette.warning.main;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [tableData, setTableData] = useState(false);
    const [open, setOpen] = useState(false);
    const [paper, setPaper] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rating_product_id, setRatingProductId] = useState(false);

    const [openRatingModal, setOpenRatingModal] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    useEffect(() => {
        if (rating_product_id) {
            setOpenRatingModal(true);
        } else {
            fetchData();
        }

    }, [rating_product_id]);

    /**
     * Fetching data from server.
     */
    const fetchData = async () => {
        setLoading(true);
        try {
            await axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post('/api/dashboard/get-order-history').then((response) => {
                    var data = response.data;
                    if (data !== undefined) {
                        if (data.response_code === 200) {
                            setTableData(data.data);
                        } else if (data.response_code === '401') {
                            swal({
                                title: "Server not responding!",
                                text: 'Please try again!',
                                icon: "warning",
                            });
                        } else {
                            swal({
                                title: "Server not responding!",
                                text: 'Please try again!',
                                icon: "warning",
                            });
                        }
                    }
                });
            });
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    const __rowDelete = (e, row_id, name) => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover \"" + name + "\" Topic!",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                try {
                    axios.get('/sanctum/csrf-cookie').then(response => {
                        axios.post('/api/dashboard/remove-study-material', {
                            'row_id': row_id
                        })
                            .then(response => {
                                if (response.data.response_code === 200) {
                                    fetchData();
                                    swal({
                                        title: 'Done!',
                                        text: response.data.message,
                                        icon: 'success'
                                    })
                                } else if (response.data.response_code === 412) {
                                    swal({
                                        title: "Errors!",
                                        text: response.data.message,
                                        icon: "error",
                                    });
                                } else {
                                    swal({
                                        title: "Errors!",
                                        text: response.data.message,
                                        icon: "error",
                                    });
                                }
                                setLoading(false);
                            })
                            .catch(function (error) {
                                setLoading(false);
                                console.error(error);
                            });
                    });
                } catch (e) {
                    setLoading(false);
                }
            } else {
                swal("Cancelled", "Your data is safe :)", "error");
            }
        });
    }

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    interface DialogTitleProps {
        id: string;
        children?: React.ReactNode;
        onClose: () => void;
    }

    function BootstrapDialogTitle(props: DialogTitleProps) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }
    const visiblityModal = (rowData) => {
        setPaper(rowData)
        setOpen(true);
    };
    const ratingReviewModal = (product_id) => {
        setRatingProductId(product_id)

    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="pagetitle">
                <h1>Order History</h1>
            </div>
            <section className="section dashboard">
                <Card title="Order History">
                    <CardHeader
                        // title="Your all papers orders"
                        titleTypographyProps={{
                            variant: "h4",
                            fontSize: '16px',
                            fontWeight: '500',
                            // textTransform: 'uppercase',
                        }}
                    // action={
                    //     <Button to="/dashboard/learning/add-learning-material" component={Link} variant="contained" startIcon={<AddIcon />}>
                    //         Add Material
                    //     </Button>
                    // }
                    />
                    <Divider />
                    <CardContent style={{ padding: "0px" }}>
                        <Box width="100%" overflow="auto">
                            <StyledTable sx={{ minWidth: 1000 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ width: '50px' }}>Sr.No.</TableCell>
                                        <TableCell align="center">Order ID</TableCell>
                                        <TableCell align="center">Topic Title</TableCell>
                                        {/* <TableCell align="center" title="Total Price">Price/Selling Price</TableCell> */}
                                        <TableCell align="center" title="Total Selling Price">Selling Price</TableCell>

                                        <TableCell align="center">Transaction ID</TableCell>
                                        <TableCell align="center" title="Payment Status">Payment</TableCell>
                                        {(user.auth_type === "3" || user.auth_type === 3) ? (
                                            <TableCell align="center">Rating/Review</TableCell>
                                        ) : ''}
                                        <TableCell align="center">Order Date</TableCell>
                                        <TableCell align="center" sx={{ width: '100px' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData ? (tableData.length) ? (
                                        <>
                                            {
                                                tableData
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row, index) => (
                                                        <TableRow key={index} hover>
                                                            <TableCell align="center">{index + 1}</TableCell>
                                                            <TableCell align="center">
                                                                <Link title="Order Details"
                                                                    to={`/dashboard/order/order-history/details/${row.order_id}`}
                                                                    color="warning" variant="outlined" size="small"
                                                                >
                                                                    {row.hash_order_id}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell align="center" title={row.topic_title}>
                                                                {row.topic_title.replace(/(.{30})..+/, "$1…")}
                                                            </TableCell>
                                                            {/* <TableCell align="center">{process.env.REACT_APP_CURRENCY}. {row.total_price}/ {row.total_selling_price}</TableCell> */}

                                                            <TableCell align="center">{process.env.REACT_APP_CURRENCY}. {row.gst_total_selling_price}</TableCell>
                                                            <TableCell align="center">
                                                                {(row.total_selling_price) ? row.transaction_id : (
                                                                    <>
                                                                        <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Free">Free</Small>
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                            <TableCell title="View" align="center">
                                                                {(row.total_selling_price) ? (
                                                                    (() => {
                                                                        switch (row.payment_status) {
                                                                            case 'success':
                                                                                return <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Success">Success</Small>
                                                                            case 'pending':
                                                                                return <Small bgcolor={bgWarning} variant="contained" size="small" sx={{ m: 1 }}>Pending</Small>
                                                                            case 'failed':
                                                                                return <Small bgcolor={bgError} variant="contained" size="small" sx={{ m: 1 }}>Failed</Small>
                                                                            default:
                                                                                return <Small bgcolor={bgError} variant="contained" size="small" sx={{ m: 1}}>{(row.payment_status)?row.payment_status:'Cancel'}</Small>
                                                                        }
                                                                    })()
                                                                ) : (
                                                                    <>
                                                                        <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Free">Free</Small>
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                            {(user.auth_type === "3" || user.auth_type === 3) ? (
                                                                <TableCell title="View" align="center">
                                                                    <Link title="Rating and review"
                                                                        to={``}
                                                                        color="warning" variant="outlined" size="small"
                                                                        onClick={() => ratingReviewModal(row.product_id)}
                                                                    >
                                                                        Rating and review<StarsIcon color="warning" sx={{ m: .2 }} />
                                                                    </Link>
                                                                </TableCell>
                                                            ) : ''}
                                                            <TableCell align="center">{Moment(row.created_at).format('DD/MM/YYYY')}</TableCell>
                                                            <TableCell title="View" align="right">
                                                                <Link title="Preview"
                                                                    to={`/dashboard/order/order-history/detail/${row.id}`}
                                                                    color="warning" variant="outlined" size="small"
                                                                >
                                                                    <PreviewIcon color="secondary" sx={{ m: 1 }} />
                                                                </Link>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <TableRow>
                                                <TableCell align="center" colSpan={10}>
                                                    <Alert severity="error" align="center">No Data found!</Alert>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ) : <TableRow><TableCell align="center" colSpan={10}>
                                        {/* <CircularProgress /> */}
                                    </TableCell></TableRow>}
                                </TableBody>
                            </StyledTable>
                            {(tableData && tableData.length) ?
                                <TablePagination
                                    sx={{ px: 2 }}
                                    page={page}
                                    component="div"
                                    rowsPerPage={rowsPerPage}
                                    count={tableData.length}
                                    onPageChange={handleChangePage}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                />
                                : <>

                                </>
                            }

                            <RatingReviewModel
                                openRatingModal={openRatingModal}
                                setOpenRatingModal={setOpenRatingModal}
                                product_id={rating_product_id}
                                setRatingProductId={setRatingProductId}>
                            </RatingReviewModel>
                        </Box>
                    </CardContent>
                </Card >
            </section >
        </>

    );
};

export default OrderHistory;
