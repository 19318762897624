import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';

import swal from 'sweetalert';
import axios from 'axios';
import Box from '@mui/material/Box';
import {
  Stepper, Step,
  StepLabel,
  Card, CardHeader, CardContent,
  Divider, Button,
} from '@mui/material';

import * as Yup from 'yup';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import EducationalInformation from './../UploadingTest/EducationalInformation';
import UploadQuestionPaper from './../UploadingTest/UploadQuestionPaper';
import PricingDetails from './../UploadingTest/PricingDetails';


const validationSchema = Yup.object().shape({
  organization_type_id: Yup.number()
    .required('Organization Type is required!'),
});

const fileTypes = ["JPG", "PNG", "PDF"];

function EditLearningMaterial() {
  const { row_id } = useParams();
  const steps = [
    'Enter your educational information',
    'Upload question paper',
    'Enter your pricing details',
  ];
  const initialValues = {
    type: 'learning',
    organization_type_id: 1,
    board_id: '',
    class_id: '',
    subject_id: '',
    topic_title: '',
    sub_topic_title: '',
    description: '',
    what_you_will_learn_keywords: '',
    board_id: '',
    university_id: '',
    college_course_id: '',
    entrance_exam_id: '',
    competitive_exam_id: '',
    year: '',
    number_of_questions: '',
    difficulty_level_id: '',
    question_paper_file: '',
    answer_paper_file: '',
    paper_thumbnail_file: '',
    learning_material_file: '',
    price: '',
    gst_price: '',
    discount_percentage: '',
    selling_price: '',
    gst_selling_price: '',
    material_owner: false,
  };
  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialValues);
  const [fileData, setFileData] = useState({
    question_paper_file: '',
    answer_paper_file: '',
    learning_material_file: '',
    paper_thumbnail_file: ''
  });

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  /**
   * 
   */
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/dashboard/get-study-material', { row_id: row_id }).then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            setFormData(data.data);
            var files = {
              question_paper_file: data.data.paper_file_url,
              answer_paper_file: data.data.answer_file_url,
              learning_material_file: data.data.learning_material_file_url,
              paper_thumbnail_file: data.data.thumb_nail_url
            }
            setFileData(files);
          } else if (data.response_code === '401') {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  }
  const handleNextStep = (newFormData, finalStep = false) => {
    setFormData((prev) => ({ ...prev, ...newFormData }));
    if (finalStep) {
      // console.log('formData', newFormData);
      handleFormSubmit(newFormData);
      return ''
    }
    setCurrentStep((prev) => prev + 1);
  }
  const handlePrevStep = (newFormData) => {
    setFormData((prev) => ({ ...prev, ...newFormData }));
    setCurrentStep((prev) => prev - 1);
  }

  const handleFormSubmit = (values, errors) => {
    try {
      axios.get('/sanctum/csrf-cookie').then(response => {
        const uploadFormData = new FormData();
        // console.log('values', values);
        // console.log('fileData', fileData);
        for (let iKey in values) {
          if (iKey === 'learning_material_file' || iKey === 'question_paper_file' || iKey === 'answer_paper_file' || iKey === 'paper_thumbnail_file') {
            if (iKey === 'undefined') {
            } else {
              uploadFormData.append(iKey, fileData[iKey])
            }
          } else {
            if (iKey === 'undefined') {
            } else {
              uploadFormData.append(iKey, values[iKey])
            }
          }
        }
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        axios.post('/api/dashboard/update-study-material', uploadFormData, config)
          .then(response => {
            if (response.data.response_code === 200) {
              swal({
                // title: "Good job!",
                text: response.data.message,
                icon: "success",
                buttons: false,
                timer: 2000
              });
              navigate('/dashboard/learning/learning-materials');
            } else {
              swal({
                title: "Warning!",
                text: response.data.message,
                icon: "warning",
              });
              errors.setErrors(response.data.errors);
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const saveAsDraftSubmit = (values, errors) => {
    try {
      axios.get('/sanctum/csrf-cookie').then(response => {
        const uploadFormData = new FormData();
        setFormData({ ...formData, values })
        for (let iKey in formData) {
          if (iKey === 'learning_material_file' || iKey === 'question_paper_file' || iKey === 'answer_paper_file' || iKey === 'paper_thumbnail_file') {
            uploadFormData.append(iKey, fileData[iKey])
          } else {
            uploadFormData.append(iKey, formData[iKey])
          }
        }
        for (let iKey in values) {
          if (iKey === 'learning_material_file' || iKey === 'question_paper_file' || iKey === 'answer_paper_file' || iKey === 'paper_thumbnail_file') {
            uploadFormData.append(iKey, fileData[iKey])
          } else {
            uploadFormData.append(iKey, values[iKey])
          }
        }
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        axios.post('/api/dashboard/draft-study-material', uploadFormData, config)
          .then(response => {
            if (response.data.response_code === 200) {
              setFormData(response.data.data);
              swal({
                text: response.data.message,
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              navigate('/dashboard/learning/edit-learning-material/' + response.data.data.id);
            } else {
              swal({
                title: "Warning!",
                text: response.data.message,
                icon: "warning",
              });
              errors.setErrors(response.data.errors);
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const formSteps = [
    <EducationalInformation next={handleNextStep} formData={formData} saveAsDraftSubmit={saveAsDraftSubmit} />,
    <UploadQuestionPaper next={handleNextStep} prev={handlePrevStep} formData={formData} setFileData={setFileData} fileData={fileData} saveAsDraftSubmit={saveAsDraftSubmit} />,
    <PricingDetails next={handleNextStep} prev={handlePrevStep} formData={formData} saveAsDraftSubmit={saveAsDraftSubmit} />
  ];
  return (
    <>
      <div className="pagetitle">
        <h1>Uploading Learning Material</h1>
        {/* <h6>Basic info, about the test paper</h6> */}
      </div>
      <section className="section dashboard">
        <Card title="Learning Material">
          <CardHeader
            title="Basic info, about the test paper"
            titleTypographyProps={{
              variant: "h4",
              fontSize: '16px',
              fontWeight: '500',
            }}
            action={
              <Button to="/dashboard/learning/learning-materials" component={Link} variant="contained" color="inherit" startIcon={<NavigateBeforeIcon />}>
                Back
              </Button>
            }
          />
          <Divider />
          <CardContent>
            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={currentStep} alternativeLabel sx={{ my: '30px' }}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel color="inherit">
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                {(formData.id) && formSteps[currentStep]}
              </div>
            </Box>
          </CardContent>
        </Card>
      </section >
    </>

  );
}
export default EditLearningMaterial;
