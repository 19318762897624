import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import {
  Card,
  Divider,
  CardContent,
  CardHeader,
  Box, Grid, Dialog,
  DialogTitle, DialogContent, DialogActions,
  IconButton,
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  useTheme, Alert,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
// import { LoadingButton } from '@mui/lab';

import Moment from 'moment';

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
}));

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  margin: "0px !important",
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));
const Item = styled("div")(({ theme }) => (
  {
    marginBottom: "5px",
    fontSize: '16px',
    fontWeight: '400',
    color: '#484747'
  }
));
const ItemContent = styled("div")(({ theme }) => (
  {
    marginBottom: "15px",
    fontSize: '16px',
    fontWeight: '600',
  }
));

const StyledTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  // '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  '& th': { background: "#003152", color: "#FFF" },
}));

const Container = styled("div")(({ theme }) => (
  {
    margin: "20px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }
));
const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  // background: 'rgba(0, 0, 0, 0.01)',
}));

const Wishlists = () => {

  const { palette } = useTheme();
  const bgError = palette.error.main;
  const bgSuccess = palette.success.main;
  const bgWarning = palette.warning.main;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [tableData, setTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [paper, setPaper] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-wishlists').then((res) => {
          var data = res.data;
          if (data !== undefined) {
            if (data.response_code === 200) {
              setTableData(data.data);
            } else if (data.response_code === '401') {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
                buttons: false,
                timer: 2000,
              });
            } else {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
                buttons: false,
                timer: 2000,
              });
            }
          }
        });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Delete row data from server.
   */
  const __removeFromWishlist = (row_id, name) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove from wishlist!",
      icon: "warning",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        try {
          axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('/api/dashboard/remove-from-wishlist', {
              'product_id': row_id
            })
              .then(response => {
                if (response.data.response_code === 200) {
                  fetchData();
                  swal({
                    // title: 'Done!',
                    text: response.data.message,
                    icon: 'success',
                    buttons: false,
                    timer: 2000,
                  })
                } else if (response.data.response_code === 412) {
                  swal({
                    // title: "Errors!",
                    text: response.data.message,
                    icon: "error",
                  });
                } else {
                  swal({
                    // title: "Errors!",
                    text: response.data.message,
                    icon: "error",
                  });
                }
                setLoading(false);
              })
              .catch(function (error) {
                setLoading(false);
                console.error(error);
              });
          });
        } catch (e) {
          setLoading(false);
        }
      } else {
        // swal("Cancelled", "Your data is safe :)", "error");
      }
    });
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  const visiblityModal = (rowData) => {
    setPaper(rowData)
    setOpen(true);
  };
  const visiblityDetailModal = (rowData) => {
    setDetailModalOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const permalink = (row) => {
    var subject = row.subject_name;
    var subject_slug = (row.subject_slug) ? row.subject_slug : subject.replace(' ', '-').toLowerCase();
    if (row.type === 'practice') {
      var url = `/samplepaper/paper-detail/${subject_slug}/${row.slug}`;
    } else {
      var url = `/notes/paper-detail/${subject_slug}/${row.slug}`;
    }
    return url;
  }
  return (
    <>
      <div className="pagetitle">
        <h1>My Wishlist</h1>
      </div>
      <section className="section dashboard">
        <Card title="My Wishlist">
          <CardHeader
            // title="Your all papers related to learning material"
            titleTypographyProps={{
              variant: "h4",
              fontSize: '16px',
              fontWeight: '500',
              // textTransform: 'uppercase',
            }}
          // action={
          //     <Button to="/dashboard/learning/add-learning-material" component={Link} variant="contained" startIcon={<AddIcon />}>
          //         Add Learning Material
          //     </Button>
          // }
          />
          <Divider />
          <CardContent style={{ padding: "0px" }}>
            <Box width="100%" overflow="auto">
              <StyledTable sx={{ minWidth: 1000 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ width: '50px' }}>Sr.No.</TableCell>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Topic/Title</TableCell>
                    <TableCell align="left">Subject</TableCell>
                    <TableCell align="center">Listing Price</TableCell>
                    <TableCell align="left">Posted Date</TableCell>
                    <TableCell align="center" sx={{ width: '100px' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData ? (tableData.length) ? (
                    <>
                      {
                        tableData
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                            <TableRow key={index} hover>
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell align="left">{row.organization_type_name}</TableCell>
                              <TableCell align="left" title={row.topic_title}>
                                <Link target="_blank" to={permalink(row)} title={row.topic_title}>
                                  {row.topic_title.replace(/(.{50})..+/, "$1…")}
                                </Link>
                              </TableCell>
                              <TableCell align="left" title={row.subject_name}>{row.subject_name.replace(/(.{50})..+/, "$1…")}</TableCell>
                              <TableCell align="center">{row.price}</TableCell>
                              <TableCell align="left">{Moment(row.created_at).format('DD/MM/YYYY')}</TableCell>
                              <TableCell title="View" align="right">
                                <Link title="Preview"
                                  to={`#`}
                                  color="warning" variant="outlined" size="small"
                                  onClick={() => visiblityModal(row)}
                                >
                                  <PreviewIcon color="secondary" sx={{ m: 1 }} />
                                </Link>

                                <Link title="Remove From Wishlist"
                                  to={`#`}
                                  variant="outlined" size="small"
                                  onClick={(e) => __removeFromWishlist(row.id, row.topic_title)}
                                >
                                  <DeleteIcon color="error" />
                                </Link>

                              </TableCell>
                            </TableRow>
                          ))
                      }
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell align="center" colSpan={9}>
                          <Alert severity="error" align="center">No Data found!</Alert>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : <TableRow><TableCell align="center" colSpan={9}><CircularProgress /></TableCell></TableRow>}
                </TableBody>
              </StyledTable>
              {(tableData && tableData.length) ?
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={tableData.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
                : <>

                </>
              }
              <Dialog
                fullWidth
                maxWidth="lg"
                open={open}
                onClose={handleClose}>
                <div>
                  <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Details
                  </BootstrapDialogTitle>
                  <DialogContent dividers style={{ height: '550px', overflowY: 'auto' }}>
                    {/* <Card> */}
                    <CardContent>
                      <Box width="100%" overflow="auto">
                        {paper && (
                          <>
                            <Grid container>
                              <Grid item sm={12} xs={12} lg={8}>
                                <Item>Topic/Title</Item>
                                <ItemContent>{paper.topic_title}</ItemContent>
                              </Grid>
                              <Grid item sm={12} xs={12} lg={4}>
                                <Item>Status</Item>
                                <ItemContent>{(() => {
                                  switch (paper.status) {
                                    case 1:
                                      return <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Approved">Approved</Small>
                                    case 0:
                                      return <Small bgcolor={bgWarning} variant="contained" size="small" sx={{ m: 1 }}>Pending</Small>
                                    case 2:
                                      return <Small bgcolor={bgError} variant="contained" size="small" sx={{ m: 1 }}>Rejected</Small>
                                    default:
                                      return null
                                  }
                                })()}
                                </ItemContent>
                              </Grid>
                              <Grid item sm={12} xs={12} lg={12}>
                                <Divider />
                                <br />
                              </Grid>

                              <Grid item sm={12} xs={12} lg={4}>
                                <Item>School/College/University/Institute</Item>
                                <ItemContent>{paper.organization_type_name}</ItemContent>
                              </Grid>

                              {paper.organization_type_id && (paper.organization_type_id == 1) && (
                                <>
                                  <Grid item sm={12} xs={12} lg={4}>
                                    <Item>Board</Item>
                                    <ItemContent>{paper.board_name}</ItemContent>
                                  </Grid>
                                  <Grid item sm={12} xs={12} lg={4}>
                                    <Item>Class</Item>
                                    <ItemContent>{paper.class_name}</ItemContent>
                                  </Grid>
                                </>
                              )}
                              {paper.organization_type_id && (paper.organization_type_id !== 1) && (
                                <>
                                  <Grid item sm={6} xs={12} lg={4}>
                                    <Item>Year</Item>
                                    <ItemContent>{paper.year}</ItemContent>
                                  </Grid>
                                </>
                              )}
                              {paper.organization_type_id && (paper.organization_type_id == 2) && (
                                <>
                                  <Grid item sm={6} xs={12} lg={4}>
                                    <Item>University</Item>
                                    <ItemContent>{paper.university_name}</ItemContent>
                                  </Grid>
                                  <Grid item sm={6} xs={12} lg={4}>
                                    <Item>Course</Item>
                                    <ItemContent>{paper.course_name}</ItemContent>
                                  </Grid>
                                </>
                              )}
                              {paper.organization_type_id && (paper.organization_type_id == 3) && (
                                <>
                                  <Grid item sm={6} xs={12} lg={4}>
                                    <Item>Entrance Exam</Item>
                                    <ItemContent>{paper.entrance_exam_name}</ItemContent>
                                  </Grid>
                                </>
                              )}
                              {paper.organization_type_id && (paper.organization_type_id == 4) && (
                                <>
                                  <Grid item sm={6} xs={12} lg={4}>
                                    <Item>Competitive Exam</Item>
                                    <ItemContent>{paper.competitive_exam_name}</ItemContent>
                                  </Grid>
                                </>
                              )}
                              <Grid item sm={12} xs={12} lg={4}>
                                <Item>Subject</Item>
                                <ItemContent>{paper.subject_name}</ItemContent>
                              </Grid>

                              <Grid item sm={6} xs={12} lg={4}>
                                <Item>No. of Questions</Item>
                                <ItemContent>{paper.number_of_questions}</ItemContent>
                              </Grid>
                              <Grid item sm={6} xs={12} lg={4}>
                                <Item>Difficulty Level</Item>
                                <ItemContent>{paper.difficulty_level}</ItemContent>
                              </Grid>
                            </Grid>
                            <br />
                            <Divider />
                            <br />
                            <Grid container>
                              <Grid item sm={6} xs={12} lg={2}>
                                <Item>Listing Price</Item>
                                <ItemContent>{paper.price}</ItemContent>
                              </Grid>
                              <Grid item sm={6} xs={12} lg={2}>
                                <Item>Discount(%)</Item>
                                <ItemContent>{paper.discount_percentage}</ItemContent>
                              </Grid>
                              <Grid item sm={6} xs={12} lg={2}>
                                <Item title="Selling Price">Selling Price</Item>
                                <ItemContent>{paper.selling_price}</ItemContent>
                              </Grid>
                              <Grid item sm={6} xs={12} lg={3}>
                                <Item title="Submitted Date">Submitted Date</Item>
                                <ItemContent>{Moment(paper.created_at).format('DD/MM/YYYY')}</ItemContent>
                              </Grid>
                              <Grid item sm={6} xs={12} lg={3}>
                                <Item>Updated Date</Item>
                                <ItemContent>{Moment(paper.updated_at).format('DD/MM/YYYY')}</ItemContent>
                              </Grid>
                            </Grid>
                            <Divider />
                            <br />
                            <Grid container>
                              <Grid item sm={12} xs={12} lg={12}>
                                <Item>Description</Item>
                                <ItemContent>{paper.description}</ItemContent>
                              </Grid>
                              <br />
                              <Grid item sm={12} xs={12} lg={12}>
                                <Item>Original Owner </Item>
                                <ItemContent>
                                  <Alert severity="success" color="success">
                                    I am the original owner of this material!
                                  </Alert>
                                </ItemContent>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Box>
                    </CardContent>
                    {/* </Card> */}
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                      Close
                    </Button>
                  </DialogActions>
                </div>
              </Dialog>
            </Box>
          </CardContent>
        </Card >
      </section >
    </>

  );
};

export default Wishlists;
