import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import {
  Button
} from '@mui/material';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import Demo from "./Tours/Demo";
import Tour from "reactour";
import Text from "./Tours/Text";
import Glitch from "./Tours/Glitch";
import Tooltip from "./Tours/Tooltip";
import "./Tours/styles.css";

function Dashboard() {

  const [earningData, setEarningData] = useState(false);
  const [learningData, setLearningData] = useState(false);
  const [practiceData, setPracticeData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isShowingMore, setIsShowingMore] = useState(false);




  useEffect(() => {
    fetchData();
  }, []);
  /**
  * Fetching data from server.
  */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-earning-states').then((res) => {
          var data = res.data;
          if (data !== undefined) {
            if (data.response_code === 200) {
              setEarningData(data.data);
            } else if (data.response_code === '401') {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            } else {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            }
          }
        });
        axios.post('/api/dashboard/get-learning-states').then((res) => {
          var data = res.data;
          if (data !== undefined) {
            if (data.response_code === 200) {
              setLearningData(data.data);
            } else if (data.response_code === '401') {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            } else {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            }
          }
        });
        axios.post('/api/dashboard/get-practice-states').then((res) => {
          var data = res.data;
          if (data !== undefined) {
            if (data.response_code === 200) {
              setPracticeData(data.data);
            } else if (data.response_code === '401') {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            } else {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            }
          }
        });
      });
    } catch (e) {
      setLoading(false);
    }
  }
  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  const toggleShowMore = () => {
    setIsTourOpen((isTourOpen) ? false : true);
  };

  const closeTour = () => {
    setIsTourOpen(false);
  };

  const openTour = () => {
    setIsTourOpen(true);
  };
  const tourConfig = [
    {
      selector: '.dashboard',
      content: `Ok, let's start with the name of the Tour that is about to begin.`
    },
    {
      selector: '.practice-pests',
      content: `And this is our cool bus...`
    },
    {
      selector: 'learning-material',
      content: `Keep in mind that you could try and test everithing during the Tour. 
        For example, try selecting the highlighted text…`
    },
    {
      selector: '.order-history',
      content: () => (
        <div>
          <Glitch data-glitch="Styled">Styled</Glitch>
          <Text color="#e5e5e5">
            The <Tooltip data-tooltip="this helper ⬇">tourist guide</Tooltip>{" "}
            could be dressed in any way, using custom components, styles and so
            on…
          </Text>
          <Text color="#373737" size=".7em" style={{ marginTop: ".7em" }}>
            <Link
              href="http://codepen.io/lbebber/full/ypgql/"
              color="dark"
              nospaces
            >
              Text effect
            </Link>{" "}
            by{" "}
            <Link href="https://twitter.com/lucasbebber" color="dark" nospaces>
              Lucas Bebber
            </Link>
          </Text>
        </div>
      ),
      style: {
        backgroundColor: "black",
        color: "white"
      }
    },
    {
      selector: '.current-sale',
      content: ({ goTo }) => (
        <div>
          If you wanna go anywhere, skipping places, it is absolutely possible.
          <br /> "Oh, I forgot something inside the bus…"{" "}
          <button
            style={{
              border: "1px solid #f7f7f7",
              background: "none",
              padding: ".3em .7em",
              fontSize: "inherit",
              display: "block",
              cursor: "pointer",
              margin: "1em auto"
            }}
            onClick={() => goTo(1)}
          >
            Please go back to{" "}
            <span aria-label="bus" role="img">
              🚌
            </span>
          </button>
        </div>
      )
    },
    {
      selector: '.withdrawable-sale',
      content: () => (
        <Text>
          The <Tooltip data-tooltip="this helper ⬇">tourist guide</Tooltip> could
          be positioned where you want.
          <br /> In this case will try to stay in the <strong>
            left side
          </strong>{" "}
          if there's available space, otherwise will{" "}
          <strong>auto position</strong>.
        </Text>
      ),
      position: "left"
    },
    {
      selector: '.withdrawable-requested',
      content:
        "Probably you noted that the Tour scrolled directly to the desired place, and you could control the time also…"
    },
    {
      selector: '.total-sale',
      content: "Also when places are pretty hidden…"
    },
    {
      selector: '.sales-card',
      content:
        "When arrived on each place you could fire an action, like… (look at the console)",
      action: () =>
        console.log(`
                    ------------🏠🏚---------
        🚌 Arrived to explore these beautiful buildings! 🚌
                    ------------🏠🏚---------
     🚧 This action could also fire a method in your Component 🚧
      `)
    },
    {
      selector: '.support',
      content:
        "And the Tour could be observing changes to update the view, try clicking the button…",
      observe: '.sales-card'
    },
    {
      selector: '.sales-card',
      content:
        "And the Tour could be observing changes to update the view, try clicking the button…",
      observe: '.sales-card'
    },
    {
      selector: '.profile-settings',
      content:
        "And the Tour could be observing changes to update the view, try clicking the button…",
      observe: '.sales-card'
    }
    
  ];

  const accentColor = "#ccc";
  return (
    <>
      <Tour
        onRequestClose={closeTour}
        steps={tourConfig}
        isOpen={isTourOpen}
        // maskClassName="mask"
        // className="helper"
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
      />
      <div className="pagetitle">
        <h1>Dashboard</h1>
      </div>
      {/* <Button onClick={openTour}>Open Tour</Button > */}
      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <div className="col-md-12">
              <h3 className="card-smallhead">Earning</h3>
            </div>
            <div className="row">
              <div className="col-md-3">
                <Link to={'/dashboard/learning/learning-materials'} title="Total Earning">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <div className="text-right">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-currency-rupee"></i>
                        </div>
                        <div className="ps-3 pull-right">
                          <h5 className="card-title">
                            Total Earning
                          </h5>
                          <h6>{process.env.REACT_APP_CURRENCY}. {(earningData.total_earning) ? earningData.total_earning : 0}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className=" col-md-3">
                <Link to={'/dashboard/learning/learning-materials'} title="Hold Amount">
                  <div className="card info-card course-card">
                    <div className="card-body">
                      <div className="text-right">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-currency-rupee"></i>
                        </div>
                        <div className="ps-3 pull-right">
                          <h5 className="card-title">Hold Amount </h5>
                          <h6>{process.env.REACT_APP_CURRENCY}. {(earningData.hold_amount) ? earningData.hold_amount : 0}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-3">
                <Link to={'/dashboard/order/order-history'} title="Total Requested Amount">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <div className="text-right">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-currency-rupee"></i>
                        </div>
                        <div className="ps-3 pull-right">
                          <h5 className="card-title">Requested Amount</h5>
                          <h6>{process.env.REACT_APP_CURRENCY}. {(earningData.requested_amount) ? earningData.requested_amount : 0}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-3">
                <Link to={'/dashboard/earning/my-earning'} title="Released Amount">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <div className="text-right">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-currency-rupee"></i>
                        </div>
                        <div className="ps-3 pull-right">
                          <h5 className="card-title">Released Amount </h5>
                          <h6>{process.env.REACT_APP_CURRENCY}. {(earningData.released_amount) ? earningData.released_amount : 0}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="col-md-12">
              <h3 className="card-smallhead">Learning Materials</h3>
            </div>
            <div className="row">
              <div className="col-md-3">
                <Link to={'/dashboard/learning/learning-materials'} title="Learning Materials">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <div className="text-right">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-shop"></i>
                        </div>
                        <div className="ps-3 pull-right">
                          <h5 className="card-title">
                            Learning Materials
                          </h5>
                          <h6>{(learningData.courses) ? learningData.courses : 0}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className=" col-md-3">
                <Link to={'/dashboard/learning/learning-materials'} title="Pending Learning Materials">
                  <div className="card info-card course-card">
                    <div className="card-body">
                      <div className="text-right">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-shop-window"></i>
                        </div>
                        <div className="ps-3 pull-right">
                          <h5 className="card-title">Pending Materials </h5>
                          <h6>{(learningData.pending_courses) ? learningData.pending_courses : 0}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-3">
                <Link to={'/dashboard/order/order-history'} title="Total Orders">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <div className="text-right">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-peoplebi bi-cart-check-fill"></i>
                        </div>
                        <div className="ps-3 pull-right">
                          <h5 className="card-title">Total Orders </h5>
                          <h6>{(learningData.total_order) ? learningData.total_order : 0}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-3">
                <Link to={'/dashboard/earning/my-earning'} title="Total Sale">
                  <div className="card info-card customers-card">
                    <div className="card-body">
                      <div className="text-right">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-currency-rupee"></i>
                        </div>
                        <div className="ps-3 pull-right">
                          <h5 className="card-title">Total Sale </h5>
                          <h6>{process.env.REACT_APP_CURRENCY}. {(learningData.total_earning) ? learningData.total_earning : 0}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <h3 className="card-smallhead">Practice Tests</h3>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Link to={'/dashboard/practice/practice-tests'} title="Practice Tests">
              <div className="card info-card sales-card">
                <div className="card-body">
                  <div className="text-right">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-shop"></i>
                    </div>
                    <div className="ps-3 pull-right">
                      <h5 className="card-title">Practice Tests</h5>
                      <h6>{(practiceData.courses) ? practiceData.courses : 0}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className=" col-md-3">
            <Link to={'/dashboard/practice/practice-tests'} title="Practice Tests">
              <div className="card info-card course-card">
                <div className="card-body">
                  <div className="text-right">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-shop-window"></i>
                    </div>
                    <div className="ps-3 pull-right">
                      <h5 className="card-title">Pending Tests</h5>
                      <h6>{(practiceData.pending_courses) ? practiceData.pending_courses : 0}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to={'/dashboard/order/order-history'} title="Total Orders">
              <div className="card info-card revenue-card">
                <div className="card-body">
                  <div className="text-right">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-peoplebi bi-cart-check-fill"></i>
                    </div>
                    <div className="ps-3 pull-right">
                      <h5 className="card-title">Total Orders </h5>
                      <h6>{(practiceData.total_order) ? practiceData.total_order : 0}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to={'/dashboard/earning/my-earning'} title="Total Sale">
              <div className="card info-card customers-card">
                <div className="card-body">
                  <div className="text-right">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-currency-rupee"></i>
                    </div>
                    <div className="ps-3 pull-right">
                      <h5 className="card-title">Total Sale</h5>
                      <h6>{process.env.REACT_APP_CURRENCY}. {(practiceData.total_earning) ? practiceData.total_earning : 0}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/* <div className="row">
          <div className="card recent-sales overflow-auto">
            <div className="col-md-12">
              <h3 className="card-smallhead">Help Videos
                 // <Link to="#" className="btn btn-green pull-right">More Videos</Link> 
              </h3>
            </div>
            <div className="row hpvideosctn">
              <div className="col-md-4">
                <iframe width="100%" height="250" src="https://www.youtube.com/embed/aun8fica7tw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <p>How to compose a newsletter</p>
              </div>
              <div className="col-md-4">
                <iframe width="100%" height="250" src="https://www.youtube.com/embed/aun8fica7tw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <p>How to compose a newsletter</p>
              </div>
              <div className="col-md-4">
                <iframe width="100%" height="250" src="https://www.youtube.com/embed/aun8fica7tw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <p>How to compose a newsletter</p>
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
}

export default Dashboard;
