import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Dialog } from '@mui/material';
import Input from '../Input/Input';

import './Auth.scss';
import swal from 'sweetalert';
import axios from 'axios';

function ForgotPassword() {
  const initialState = {
    email: '', password: '', confirmPassword: '',
  };
  const { reset_token } = useParams();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

  const handleShowConfirmPassword = () => setShowConfirmPassword((prevConfirmShowPassword) => !prevConfirmShowPassword);

  useEffect(() => {
    if (reset_token) {
      fetchData(reset_token);
    }

  }, []);
  /**
    * Fetching data from server.
    */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/frontend/user/check-reset-token', { 'reset_token': reset_token })
          .then(response => {
            var data = response.data;
            if (data.response_code === 200) {
              setOpen(true);
            } else if (data.response_code === 401) {
              swal({
                title: 'Reset Password',
                text: data.message,
                icon: "warning",
              });
            } else {
              swal({
                title: 'Reset Password',
                text: data.message,
                icon: "warning",
              });
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  const handleClose = () => {
    setOpen(false);
    setFormData(initialState);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleResetPaswordSubmit = (e) => {
    e.preventDefault(e);
    if (formData.password !== formData.confirmPassword) {
      swal({
        title: 'Warning!',
        text: 'Password and Confirm Password does not match!',
        icon: "warning",
      });
      return false;
    }
    axios.get('/sanctum/csrf-cookie').then(async response => {
      setLoading(true);
      axios.post('/api/frontend/user/reset-password', {
        reset_token: reset_token,
        password: formData.password,
        confirmPassword: formData.confirmPassword
      }).then((res) => {
        var data = res.data;
        if (data.response_code === 200) {
          swal({
            title: "Done!",
            text: data.message,
            icon: "success",
          });
          handleClose()
          setLoading(false);
        } else if (data.response_code === '401') {
          swal({
            title: 'Password assistance',
            text: data.message,
            icon: "warning",
          });
        } else {
          swal({
            title: "Server not responding!",
            text: 'Please try again!',
            icon: "warning",
          });
        }
        setLoading(false);
      });
    });

  }

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <div className="auth">
        <form className="auth__otp" onSubmit={handleResetPaswordSubmit}>
          <h3 className='text-center'>Reset Password <hr /> </h3>
          <div>
            <span>New Password</span>
            <Input name="password" placeholder="Enter your new password" value={formData.password} handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
          </div>

          <div>
            <span>Confirm Password</span>
            <Input name="confirmPassword" placeholder="Confirm password" value={formData.confirmPassword} handleChange={handleChange} type={showConfirmPassword ? 'text' : 'password'} handleShowPassword={handleShowConfirmPassword} />
          </div>
          <br />
          <button type="submit" className="auth__email">Update</button>
          <br />
          <p>
            By signing up, you agree to our
            {' '}
            <Link to="/terms-and-conditions"><span>Terms of Use</span></Link>
            {' '}
            and
            {' '}
            <Link to="/privacy-policy"><span>Privacy Policy.</span></Link>
          </p>
        </form>
      </div>
    </Dialog>
  );
}

export default ForgotPassword;
