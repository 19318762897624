import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SEO from './../../layouts/SEO';
import SearchPageSEO from './SearchPageSEO';
import {
  Avatar, Backdrop, CircularProgress, Button, Rating, Alert,
} from '@mui/material';
import { useCart } from '../../../contexts/CartContext';
import { useAuth } from '../../../contexts/AuthContext';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useSearchFilter } from '../../../contexts/SearchFilterContext';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";
import LeftFilter from './LeftFilter';
import PreviewIcon from '@mui/icons-material/Preview';
import PreviewModalDialog from '../PreviewModalDialog';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

function SearchFilterLists() {
  const { filterOptions, setFilterOptions, filteTableData, fetchFilteredProduct } = useSearchFilter();

  const [searchPapers, setSearchPapers] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { addToCart, isItemInCart, addToWishlist, removeFormWishlist } = useCart();
  const { isAuthenticated, checkUserAuth } = useAuth();
  const [paper, setPaper] = useState([]);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  /**
  * Add Product in to wishlist
  */
  const productAddToWishlist = (paper) => {
    if (checkUserAuth()) {
      addToWishlist(paper.id);
      const newSearchPapers = searchPapers.data.map((item, i) => {
        if (paper.id === item.id) {
          return { ...item, ['in_wishlist']: 1 };
        } else {
          return item;
        }
      });
      setSearchPapers({ ...searchPapers, ['data']: newSearchPapers });
    } else {
      swal({
        text: 'Please logged out from current login and Signin or Signup as student or user for Add To Wishlist!',
        icon: 'warning',
        buttons: false,
        timer: 2000
      });
      return false;
    }

  }

  /**
   * Remove Product from to wishlist
   */
  const productRemoveFormWishlist = (paper) => {
    removeFormWishlist(paper.id);
    const newSearchPapers = searchPapers.data.map((item, i) => {
      if (paper.id === item.id) {
        return { ...item, ['in_wishlist']: 0 };
      } else {
        return item;
      }
    });
    setSearchPapers({ ...searchPapers, ['data']: newSearchPapers });
  }

  const permalink = (row) => {
    var subject = row.subject_name;
    var subject_slug = (row.subject_slug) ? row.subject_slug : subject.replace(' ', '-').toLowerCase();
    if (row.type === 'practice') {
      var url = `/samplepaper/paper-detail/${subject_slug}/${row.slug}`;
    } else {
      var url = `/notes/paper-detail/${subject_slug}/${row.slug}`;
    }
    return url;
  }

  // Product Preview
  const visiblityPreviewModal = (rowData) => {
    setPaper(rowData)
    setPreviewModalOpen(true);
  };

  return (
    <>
      <SearchPageSEO />
      <div className="breadcrumb_section  page-title-mini">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ol className="breadcrumb ">
                {/* <li className="breadcrumb-item"><Link to="/">Home</Link></li> */}
                {/* <li className="breadcrumb-item"><Link to="#">Development</Link></li>
                <li className="breadcrumb-item"><Link to="#">Web Development</Link></li>
                <li className="breadcrumb-item active">JavaScript</li> */}
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="main_content">
        <Backdrop
          sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={setLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="row align-items-center">
                  <div className="col-12">
                    <ul className="nav nav-tabs tabstyle" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className={(filterOptions.selected_type === 'learning') ? 'nav-link active' : 'nav-link'} id="home-tab"
                          data-bs-toggle="tab" data-bs-target="#search-product"
                          type="button" role="tab" aria-controls="search-product"
                          aria-selected="true"
                          onClick={e => {
                            setFilterOptions({ ...filterOptions, ['selected_type']: 'learning' });
                          }}
                        >
                          Learning Material
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className={(filterOptions.selected_type === 'practice') ? 'nav-link active' : 'nav-link'} id="profile-tab"
                          data-bs-toggle="tab" data-bs-target="#search-product"
                          type="button" role="tab" aria-controls="search-product"
                          aria-selected="false"
                          onClick={e => {
                            setFilterOptions({ ...filterOptions, ['selected_type']: 'practice' });
                          }}
                        >
                          Practice Tests
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="search-product" role="tabpanel" aria-labelledby="home-tab">
                    <div className="row shop_container grid product_list_wrap">
                      {filteTableData && filteTableData.data.length ? (
                        filteTableData.data.map((row, index) => (
                          <div className="item col-md-4 col-6" key={index}>
                            <div className="product">
                              <Link to={permalink(row)} title={row.topic_title}>
                                <div className="product_img">
                                  <img src={(row.thumb_nail_url ? row.thumb_nail_url : ((row.thumb_nail_image) ?? row.thumb_nail_image))} alt={row.topic_title} />
                                </div>
                              </Link>
                              <div className="product_info">
                                <div className='author-info-wrap'>
                                  <h6 className='product-subject'>
                                    <div className='auther-profile'>
                                      {row.profile_image ?
                                        <img src={row.profile_image} alt={row.author_name} />
                                        :
                                        <Avatar src="/broken-image.jpg" />
                                      }
                                    </div>
                                    <Link to={permalink(row)} title={row.subject_name}>
                                      {row.subject_name.split(' ')[0]}{(row.subject_name.split(' ')[1]) ? '...' : ''}
                                    </Link>
                                    <small>{row.author_name}</small>
                                  </h6>
                                </div>

                                <h6 className="product_title">
                                  <Link to={permalink(row)} title={row.topic_title}>
                                    {row.topic_title.replace(/(.{70})..+/, "$1…")}
                                  </Link>
                                </h6>
                                {/* <small>{row.author_name}</small> */}
                                <div className="rating_wrap">
                                  <span className="product_title">
                                    {(() => {
                                      switch (row.organization_type_id) {
                                        case (1 || '1'):
                                          return `${row.class_name.replace(/(.{30})..+/, "$1…")}`;
                                        case (2 || '2'):
                                          return `${row.university_name.replace(/(.{30})..+/, "$1…")}`;
                                        case (3 || '3'):
                                          return `${row.entrance_exam_name.replace(/(.{30})..+/, "$1…")}`;
                                        case (4 || '4'):
                                          return `${row.competitive_exam_name.replace(/(.{30})..+/, "$1…")}`;
                                        default:
                                          return null;
                                      }
                                    })()}
                                  </span>
                                  <br />
                                  <div className="rating">
                                    <span className="rating_num">({row.total_review})</span>
                                    <Rating className="rating" value={row.avg_rating} name="size-large" size="large" readOnly />
                                  </div>
                                  {isAuthenticated && row.in_wishlist ? (
                                    <Button color='secondary' aria-label="Name" onClick={() => productRemoveFormWishlist(row)} size='small' style={{ float: "right" }}>
                                      <FavoriteIcon></FavoriteIcon>
                                    </Button>
                                  ) : (
                                    <Button color='secondary' aria-label="Name" onClick={() => productAddToWishlist(row)} size='small' style={{ float: "right" }}>
                                      <FavoriteBorderIcon></FavoriteBorderIcon>
                                    </Button>
                                  )}
                                </div>
                                <div className="prdt_price">
                                  <div>
                                    <small className="price1" style={{ textDecoration: "line-through" }}>{process.env.REACT_APP_CURRENCY} {row.gst_price}</small>
                                    <small>{row.discount_percentage ? ` ( -${row.discount_percentage}%)` : ' ( -0%)'}</small>
                                    {/* <small>{` (Inclusive GST)`}</small> */}
                                  </div>
                                  {(row.price) ? (
                                    <>
                                      <span className="price">
                                        {process.env.REACT_APP_CURRENCY} {row.gst_selling_price}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="price">Free</span>
                                    </>
                                  )}
                                  {isItemInCart(row.id) ? (
                                    <Button aria-label="Name" variant="contained" size='small' className="added-to-cart">
                                      <ShoppingCartIcon />
                                    </Button>
                                  ) : (
                                    <Button aria-label="Name" onClick={() => addToCart(row.id)} variant="contained" size='small' className="add-to-cart">
                                      <AddShoppingCartIcon />
                                    </Button>
                                  )}
                                  {(row.pdf_preview) ?
                                    <PreviewIcon
                                      className='pdf-preview-icon'
                                      onClick={() => visiblityPreviewModal(row)}
                                    />
                                    :
                                    ''
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                        )
                      ) : (
                        <Alert severity="error">No results match your search criteria — check it out!</Alert>
                      )
                      }
                      {(filteTableData && filteTableData.data.length) ? (
                        <div className="row">
                          <div className="col-12">
                            <Pagination
                              innerClass="pagination mt-3 justify-content-center pagination_style1"
                              activePage={filteTableData.current_page}
                              itemsCountPerPage={filteTableData.per_page}
                              totalItemsCount={filteTableData.total}
                              pageRangeDisplayed={5}
                              onChange={(pageNumber) => fetchFilteredProduct(pageNumber)}
                              itemClass={"page-item"}
                              linkClass={"page-link"}
                            />
                          </div>
                        </div>
                      ) : ''}
                      <PreviewModalDialog previewModalOpen={previewModalOpen} paper={paper} setPreviewModalOpen={setPreviewModalOpen} />
                    </div>
                  </div>
                  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    Hello how are you?
                  </div>
                </div>
              </div>
              <div className="col-lg-3 order-lg-first mt-4 pt-2 mt-lg-0 pt-lg-0">
                <LeftFilter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SearchFilterLists;
