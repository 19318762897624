import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import {
    Card,
    Divider,
    CardContent,
    CardHeader,
    Box,
    Button,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    CircularProgress,
    useTheme, Alert,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import PreviewIcon from '@mui/icons-material/Preview';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import DetailModalDialog from './DetailModalDialog';
import PreviewModalDialog from './PreviewModalDialog';

import Moment from 'moment';

const Title = styled('span')(() => ({
    fontSize: '1rem',
    fontWeight: '500',
    textTransform: 'capitalize',
}));

const Small = styled('small')(({ bgcolor }) => ({
    width: 50,
    height: 15,
    color: '#fff',
    padding: '2px 8px',
    borderRadius: '4px',
    overflow: 'hidden',
    background: bgcolor,
    margin: "0px !important",
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));

const StyledTable = styled(Table)(() => ({
    minWidth: 400,
    whiteSpace: 'pre',
    '& small': {
        width: 50,
        height: 15,
        borderRadius: 500,
        boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
    },
    // '& td': { borderBottom: 'none' },
    '& td:first-of-type': { paddingLeft: '16px !important' },
    '& th': { background: "#003152", color: "#FFF" },
}));

const Container = styled("div")(({ theme }) => (
    {
        margin: "20px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }
));
const ContentBox = styled(Box)(() => ({
    height: '100%',
    padding: '32px',
    position: 'relative',
    // background: 'rgba(0, 0, 0, 0.01)',
}));

const LearningMaterial = () => {

    const { palette } = useTheme();
    const bgError = palette.error.main;
    const bgSuccess = palette.success.main;
    const bgWarning = palette.warning.main;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [tableData, setTableData] = useState(false);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [paper, setPaper] = useState([]);

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchData();
    }, []);

    /**
     * Fetching data from server.
     */
    const fetchData = async () => {
        setLoading(true);
        try {
            await axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post('/api/dashboard/get-learning-material').then((res) => {
                    var data = res.data;
                    if (data !== undefined) {
                        if (data.response_code === 200) {
                            setTableData(data.data);
                        } else if (data.response_code === '401') {
                            swal({
                                title: "Server not responding!",
                                text: 'Please try again!',
                                icon: "warning",
                            });
                        } else {
                            swal({
                                title: "Server not responding!",
                                text: 'Please try again!',
                                icon: "warning",
                            });
                        }
                    }
                });
            });
        } catch (e) {
            setLoading(false);
        }
    }

    /**
     * Delete row data from server.
     */
    const __rowDelete = (e, row_id, name) => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover \"" + name + "\" Topic!",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                try {
                    axios.get('/sanctum/csrf-cookie').then(response => {
                        axios.post('/api/dashboard/remove-study-material', {
                            'row_id': row_id
                        })
                            .then(response => {
                                if (response.data.response_code === 200) {
                                    fetchData();
                                    swal({
                                        // title: 'Done!',
                                        text: response.data.message,
                                        icon: 'success'
                                    })
                                } else if (response.data.response_code === 412) {
                                    swal({
                                        // title: "Errors!",
                                        text: response.data.message,
                                        icon: "error",
                                    });
                                } else {
                                    swal({
                                        title: "Errors!",
                                        text: response.data.message,
                                        icon: "error",
                                    });
                                }
                                setLoading(false);
                            })
                            .catch(function (error) {
                                setLoading(false);
                                console.error(error);
                            });
                    });
                } catch (e) {
                    setLoading(false);
                }
            } else {
                swal("Cancelled", "Your data is safe :)", "error");
            }
        });
    }

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const visiblityDetailModal = (rowData) => {
        setPaper(rowData)
        setDetailModalOpen(true);
    };
    // Product Preview
    const visiblityPreviewModal = (rowData) => {
        setPaper(rowData)
        setPreviewModalOpen(true);
    };


    return (
        <>
            <div className="pagetitle">
                <h1>Learning Material</h1>
            </div>
            <section className="section dashboard">
                <Card title="Learning Material">
                    <CardHeader
                        // title="Your all papers related to learning material"
                        titleTypographyProps={{
                            variant: "h4",
                            fontSize: '16px',
                            fontWeight: '500',
                            // textTransform: 'uppercase',
                        }}
                        action={
                            <Button to="/dashboard/learning/add-learning-material" component={Link} variant="contained" startIcon={<AddIcon />}>
                                Add Learning Material
                            </Button>
                        }
                    />
                    <Divider />
                    <CardContent style={{ padding: "0px" }}>
                        <Box width="100%" overflow="auto">
                            <StyledTable sx={{ minWidth: 1000 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ width: '50px' }}>Sr.No.</TableCell>
                                        <TableCell align="left">Type</TableCell>
                                        <TableCell align="left">Topic/Title</TableCell>
                                        <TableCell align="left">Subject</TableCell>
                                        {/* <TableCell align="center">Year</TableCell> */}
                                        {/* <TableCell align="center" title="Nnumber Of Questions">NOQ</TableCell> */}
                                        <TableCell align="center">Listing Price</TableCell>
                                        <TableCell align="left">Posted Date</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center" sx={{ width: '100px' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData ? (tableData.length) ? (
                                        <>
                                            {
                                                tableData
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row, index) => (
                                                        <TableRow key={index} hover>
                                                            <TableCell align="center">{index + 1}</TableCell>
                                                            <TableCell align="left">{row.organization_type_name}</TableCell>
                                                            <TableCell align="left" title={row.topic_title}>{(row.topic_title) ? row.topic_title.replace(/(.{50})..+/, "$1…") : row.topic_title}</TableCell>
                                                            <TableCell align="left" title={row.subject_name}>{(row.subject_name) ? row.subject_name.replace(/(.{50})..+/, "$1…") : row.subject_name}</TableCell>
                                                            {/* <TableCell align="center">{row.year}</TableCell> */}
                                                            {/* <TableCell align="center">{row.number_of_questions}</TableCell> */}
                                                            <TableCell align="center">{row.gst_selling_price}</TableCell>
                                                            <TableCell align="left">{Moment(row.created_at).format('DD/MM/YYYY')}</TableCell>
                                                            <TableCell title="View" align="center">
                                                                {(() => {
                                                                    switch (row.status) {
                                                                        case 1:
                                                                            return <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Approved">Approved</Small>
                                                                        case 0:
                                                                            return <Small bgcolor={bgWarning} variant="contained" size="small" sx={{ m: 1 }}>Pending</Small>
                                                                        case 2:
                                                                            return <Small bgcolor={bgError} variant="contained" size="small" sx={{ m: 1 }}>Rejected</Small>
                                                                        case 3:
                                                                            return <Small bgcolor={'#9c1515'} variant="contained" size="small" sx={{ m: 1 }}>Draft</Small>
                                                                        default:
                                                                            return null
                                                                    }
                                                                })()}
                                                            </TableCell>
                                                            <TableCell title="View" align="right">
                                                                <Link title="View Detail"
                                                                    to={`#`}
                                                                    color="warning" variant="outlined" size="small"
                                                                    onClick={() => visiblityDetailModal(row)}
                                                                >
                                                                    <RemoveRedEyeIcon color="secondary" sx={{ m: 1 }} />
                                                                </Link>
                                                                <Link title="Preview"
                                                                    to={`#`}
                                                                    color="warning" variant="outlined" size="small"
                                                                    onClick={() => visiblityPreviewModal(row)}
                                                                >
                                                                    <PreviewIcon color="secondary" sx={{ m: 1 }} />
                                                                </Link>
                                                                <Link title="Edit"
                                                                    to={`/dashboard/learning/edit-learning-material/${row.id}`}
                                                                    variant="outlined" size="small" >
                                                                    <EditIcon color="primary" sx={{ m: 1 }} />
                                                                </Link>
                                                                <Link title="Delete"
                                                                    to={`#`}
                                                                    variant="outlined" size="small"
                                                                    onClick={(e) => __rowDelete(e, row.id, row.topic_title)}
                                                                >
                                                                    <DeleteIcon color="error" />
                                                                </Link>

                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <TableRow>
                                                <TableCell align="center" colSpan={8}>
                                                    <Alert severity="error" align="center">No Data found!</Alert>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ) : <TableRow><TableCell align="center" colSpan={8}><CircularProgress /></TableCell></TableRow>}
                                </TableBody>
                            </StyledTable>
                            {(tableData && tableData.length) ?
                                <TablePagination
                                    sx={{ px: 2 }}
                                    page={page}
                                    component="div"
                                    rowsPerPage={rowsPerPage}
                                    count={tableData.length}
                                    onPageChange={handleChangePage}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                />
                                : <>

                                </>
                            }
                            <PreviewModalDialog previewModalOpen={previewModalOpen} paper={paper} setPreviewModalOpen={setPreviewModalOpen} />
                            <DetailModalDialog detailModalOpen={detailModalOpen} paper={paper} setDetailModalOpen={setDetailModalOpen} />
                        </Box>
                    </CardContent>
                </Card >
            </section >
        </>

    );
};

export default LearningMaterial;
