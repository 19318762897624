import React, { useEffect, useState } from 'react';

import axios from 'axios';
import OwlCarousel from 'react-owl-carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


function Testimonials() {

    const [professionalSkills, setProfessionalSkills] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
    }, []);

    const options = {
        nav: false,
        dots: true,
        // autoplay: true,
        loop: true,
        // padding: 20,
        margin: 20,
        responsiveClass: true,
        navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
                dots: true,
            },
            1000: {
                items: 2,
            }
        },
    };
    return (
        <>
            <div className="section">
                <div className="container sctnheader">
                    <h3 className="btn-shine">People like irevize, No joking - here’s the proof!</h3>
                    {/* <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p> */}
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <OwlCarousel className='hot_seller product_slider carousel_slider owl-carousel owl-theme nav_style1'
                                {...options}>

                                <div className="testimonialbox">
                                    <div className="testimonialhdr">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/aryan.jpeg`} alt="Aryan Profile" />
                                        <h3>Aryan</h3>
                                        <h4>GMAT Exam</h4>
                                    </div>
                                    <p>
                                        I must commend the website for its affordability. Unlike many other online educational platforms, this
                                        website offers a significant amount of its material for a very cheap price. The content is extensive enough
                                        to cater to most students' needs and definitely helped me to prepare for my GMAT Exams.
                                    </p>
                                    <br />
                                    <p>This website is a blessing in disguise for all self-reliant students.</p>
                                </div>
                                <div className="testimonialbox">
                                    <div className="testimonialhdr">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/vinod.jpeg`} alt="Vinod Profile" />
                                        <h3>Vinod</h3>
                                        <h4>Bank PO EXAM</h4>
                                    </div>
                                    <p>
                                        One outstanding aspect of this website is the impeccable quality of its content. The materials are carefully
                                        curated and developed by knowledgeable educators and subject matter experts. I was able to prepare for
                                        my Bank Clerk exam with the help of material available on the website. They are thoroughly researched,
                                        up-to-date, and presented in an engaging and comprehensible manner. Thank you Irevize.
                                    </p>
                                </div>
                                <div className="testimonialbox">
                                    <div className="testimonialhdr">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/navya.jpeg`} alt="Navya Profile" />
                                        <h3>Navya</h3>
                                        <h4>Class 10 Student</h4>
                                    </div>
                                    <p>I recently discovered Irevize.com which has completely transformed my approach to studying. Irevize, is
                                        an invaluable resource for students of all ages and academic levels. It immensely helped me in preparing
                                        for my class 10th board Exams, it had enough practice tests and learning material for all the subjects.
                                    </p>
                                    <p>I wholeheartedly recommend it!</p>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Testimonials;
