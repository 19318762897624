import React, { useState, useEffect } from 'react';
import { Dialog } from '@mui/material';
import {
  Avatar, Button
} from '@mui/material';

import swal from 'sweetalert';
import axios from 'axios';
import { FileUploader } from "react-drag-drop-files";
import { Formik } from 'formik';
import * as Yup from 'yup';

import './EditProfile.scss';
const validationSchema = Yup.object().shape({
});
const progileFileTypes = ["JPG", "PNG", "JPEG"];
function ProfileImage({ userProfile, openProfileImagePopup, setOpenProfileImagePopup, setUpdateProfileIamge }) {
  const handleClose = () => setOpenProfileImagePopup(false);
  const [formData, setFormData] = useState({
    profile_image_file: "",
  });

  const handleFormSubmit = (values, errors) => {
    if (values.profile_image_file) {
      if (values.profile_image_file.size > 10000000) {
        return errors.setErrors({ 'profile_image_file': 'Max allowed size is 10MB!' });
      }
    } else {
      return errors.setErrors({ 'profile_image_file': 'You must be upload profile image!' });
    }

    axios.get('/sanctum/csrf-cookie').then(async response => {
      const uploadFormData = new FormData();
      for (let iKey in values) {
        if (iKey === 'profile_image_file') {
          uploadFormData.append(iKey, formData[iKey])
        } else {
          uploadFormData.append(iKey, values[iKey])
        }
      }
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      axios.post('/api/dashboard/update-profile-image', uploadFormData, config).then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            swal({
              // title: "Done!",
              text: data.message,
              icon: "success",
              buttons: false,
              timer: 2000
            });
            localStorage.setItem('profile', JSON.stringify(data.data));
            setUpdateProfileIamge(true);
            setOpenProfileImagePopup(false);
          } else if (data.response_code === '401') {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  };

  return (
    <Dialog
      maxWidth="md"
      open={openProfileImagePopup}
      onClose={handleClose}
    >
      <div className="editProfile">
        <div className="editProfile__head">
          <h3>Update Profile Image</h3>
        </div>
        <div className="editProfile__content">
          <Formik
            onSubmit={(values, errors) => {
              handleFormSubmit(values, errors);
            }
            }
            initialValues={formData}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, inputName }) => (
              <form onSubmit={handleSubmit}>
                <div className="editProfile__details">
                  <div className='center text-center' style={{ textAlign: "center", margin: "0px auto" }}>
                    <Avatar src={userProfile.profile_image} alt={userProfile.username}>
                      {/* {userProfile.name[0]} */}
                    </Avatar>
                  </div>
                  <label htmlFor="profile_image_file" className='form-label'>Profile Image <span className='req-star'>*</span></label>
                  <div className='file-grag-drop-wrap'>
                    <FileUploader
                      fullWidth
                      multiple={false}
                      label={'Drag & drop file here'}
                      handleChange={(file) => {
                        setFieldValue('profile_image_file', file);
                        setFormData({ ...formData, ['profile_image_file']: file })
                      }
                      }
                      value={values.profile_image_file}
                      name="profile_image_file"
                      types={progileFileTypes}
                    />

                    {(errors.profile_image_file) && <div style={{ color: '#ff3d57' }}>{`${errors.profile_image_file}`}</div>}
                  </div>
                </div>
                <div className="editProfile__buttons">
                  <Button
                    variant="outlined"
                    type="button"
                    size="small" onClick={handleClose}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="sublit"
                    size="small" onClick={handleSubmit}>
                    Update
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Dialog >

  );
}

export default ProfileImage;
