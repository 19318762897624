import React from 'react';
import { Link } from 'react-router-dom';
import SEO from './../../layouts/SEO';
import DownloadApp from './../Homepage/DownloadApp';

function TermsAndConditions() {
  return (
    <>
      <SEO
        title="Terms And Conditions – irevize"
        description="All payments for products or services must be made in full at the time of purchase."
        keywords="Terms,Conditions,Terms And Conditions"
        canonical="http://irevize.com/terms-and-conditions"
      >
      </SEO>
      <div className="breadcrumb_section  page-title-mini">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item">
                  <Link to="/" title='Home'>Home</Link>
                </li>
                <li className="breadcrumb-item active" title='Terms And Conditions'>Terms And Conditions</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row align-items-center prod-detail" style={{ textAlign: 'justify' }}>
                  <div className="col-12">
                    <h1 className="btn-shine text-center">Terms And Conditions</h1>
                    <p>
                      Irevize Services Pvt. Ltd (“us”, “we”, or “our”) operates www.irevize.com. These terms and conditions govern your use of our Service and any products, services, or content available on or through the Service.
                      By using our Service, you agree to these terms and conditions. If you do not agree with these terms and conditions, please do not use our Service.

                    </p>
                    <h2 className="btn-shine mt-30">Payments and Refunds</h2>
                    <p>
                      All payments for products or services must be made in full at the time of purchase. If you are not satisfied with the product or service you purchased, you may request a return within 7 days of the purchase date. To be eligible for a return, the product or service must hold incorrect data/information which is not relevant to the topic of your purchase.
                    </p>
                    <p>
                      We will issue a refund for the purchase price of the product or service, minus any applicable fees, within 7 days of receiving your request for return.
                    </p>

                    <h2 className="btn-shine mt-30">Intellectual Property</h2>
                    <p>
                      All content, materials, and products available on or through our Service are the intellectual property of respective companies or individuals. Irevize services Pvt. Ltd. does not hold any authority on any data whatsoever. You may not use or distribute our content, materials, or products without our prior written permission.
                    </p>

                    <h2 className="btn-shine mt-30">Disclaimer of Representation</h2>
                    <p>
                      We make no representations about the accuracy or completeness of the content, materials, or products available on or through our Service. We are not responsible for any errors or omissions in the content, materials, or products.
                    </p>
                    <h2 className="btn-shine mt-30">Limitation of Liability</h2>
                    <p>
                      In no event shall Irevize services Pvt. Ltd. be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of our Service, including but not limited to any loss of profits, data, or other intangible losses.
                    </p>
                    <h2 className="btn-shine mt-30">Indemnification</h2>
                    <p>
                      You agree to indemnify, defend, and hold harmless Irevize services Pvt. Ltd. , its officers, directors, employees, and agents from any claim, demand, or damage, including reasonable attorneys’ fees, arising out of or in connection with your use of our Service.
                    </p>
                    <h2 className="btn-shine mt-30">Changes to Our Terms and Conditions</h2>
                    <p>
                      We may update our terms and conditions from time to time. If we make changes to our terms and conditions, we will post the updated terms and conditions on this page.
                    </p>
                    <h2 className="btn-shine mt-30">Contact Us</h2>
                    <p>
                      If you have any questions or concerns about our terms and conditions, please contact us at <Link to={'mailto:support@irevize.com'}> support@irevize.com</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <DownloadApp /> */}
      </div>
    </>
  );
}
export default TermsAndConditions;
