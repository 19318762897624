import React, { useReducer, createContext, useEffect, useContext, ReactNode, useState } from "react";
import axios from 'axios';
import swal from 'sweetalert';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Backdrop, CircularProgress, Dialog, IconButton, Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import googlelogin from './../images/googlelogin.svg';

import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { signin, signup, gmailSigninSignup } from './../actions/auth';
import { AUTH } from './../constants/actionTypes';
import Input from './../components/Input/Input';

const AuthStateContext = createContext({
  isAuthenticated: false,
  isInitialised: false,
  user: null,
});

export function useAuth() {
  return useContext(AuthStateContext);
}

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [loading, setLoading] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [isAuthenticated, setIsAuthenticated] = useState((user) ? user.token : false);
  const [auth_user_type, setAuthUserType] = useState((user) ? user.auth_type : false);

  const initialState = {
    name: '', mobile_no: '', email: '', otp: '', password: '', confirmPassword: '', level: '', user_type: '3',
  };
  const [formData, setFormData] = useState(initialState);
  const [mainAuth, setMainAuth] = useState(true);
  const [otpVerification, setOtpVerification] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userType, setUserType] = useState(3);
  const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {

    /**
     * Check User logged IN.
     * @author Pavan Ji <dropmail2pavan@gmail.com>
     */
    checkAuth()

  }, [navigate, openAuth, location]);

  useEffect(() => {
    if (formData.user_type !== userType) {
      setFormData({ ...formData, ['user_type']: userType });
    }
  }, [userType]);

  const handleClose = () => {
    setMainAuth(true);
    setOpenAuth(false);
    setOtpVerification(false);
    setForgotPasswordPopup(false);
    setResetPassword(false);
    setFormData(initialState);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /**
   * This function is user to signout from login user.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const authSignOut = async () => {
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('api/user/logout')
          .then(response => {
            if (response.data.status_code === 200) {
              localStorage.removeItem('profile');
              localStorage.clear();
              setUser(false);
              setIsAuthenticated(false);
              setAuthUserType(false);
              // swal({
              //   title: "Sign Out",
              //   text: response.data.message,
              //   icon: "success",
              //   timer: 1000,
              //   button: false,
              // }).then(() => {
              //   if (currentPath.includes("/dashboard")) {
              //     navigate('/');
              //   }
              // });
            } else {
              setUser(false);
              setIsAuthenticated(false);
              setAuthUserType(false);
              localStorage.removeItem('profile');
              localStorage.clear();
            }
            swal({
              title: "Sign Out",
              text: response.data.message,
              icon: "success",
              timer: 1000,
              button: false,
            }).then(() => {
              if (currentPath.includes("/dashboard")) {
                navigate('/');
              }
            });
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
            setUser(false);
            setIsAuthenticated(false);
            setAuthUserType(false);
            localStorage.removeItem('profile');
            localStorage.clear();
            swal({
              title: "Sign Out",
              text: response.data.message,
              icon: "success",
              timer: 1000,
              button: false,
            }).then(() => {
              if (currentPath.includes("/dashboard")) {
                navigate('/');
              }
            });
          });

      });
    } catch (e) {
      setLoading(false);
    }
  }
  // log out function to log the user out of google and set the profile array to null
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      /**
       * Login In 
       */
      axios.get('/sanctum/csrf-cookie').then(async response => {
        setLoading(true);
        axios.post('/api/user/social-login', codeResponse).then((response) => {
          var data = response.data;
          if (data !== undefined) {
            if (data.status_code === 200) {
              localStorage.setItem('profile', JSON.stringify({ ...data.data }));
              setUser(data.data);
              setIsAuthenticated(true);
              setAuthUserType(data.data.auth_type);
              swal({
                title: "Sign In",
                text: data.message,
                icon: "success",
                button: false,
                timer: 1000,
              }).then(() => {
                if (data.data.auth_type === '2' || data.data.auth_type === 2) {
                  navigate('/dashboard');
                } else {
                }
                handleClose();
              });
              setLoading(false);
            } else if (data.status_code === '401' || data.status_code === 401) {
              /**
               * Credentials failed
               */
              var messageText = '';
              if (data.errors.email) {
                messageText = data.errors.email[0];
              }
              if (data.errors.password) {
                messageText = messageText + '\n' + data.errors.password[0];
              }
              swal({
                title: data.message,
                text: messageText,
                icon: "warning",
              });
              setLoading(false);
            }
          }
        });
        setLoading(false);
      });

      // dispatch(gmailSigninSignup(codeResponse, navigate)).then((data) => {
      //   if (data !== undefined) {
      //     if (data.status_code === 200) {
      //       handleClose();
      //     } else if (data.status_code === '401') {
      //       /**
      //        * Login failed
      //        */
      //       var messageText = '';
      //       if (data.errors.email) {
      //         messageText = data.errors.email[0];
      //       }
      //       if (data.errors.password) {
      //         messageText = messageText + '\n' + data.errors.password[0];
      //       }
      //       swal({
      //         title: data.message,
      //         text: messageText,
      //         icon: "warning",
      //       });
      //     } else {
      //       swal({
      //         title: "Server not responding!",
      //         text: 'Please try again!',
      //         icon: "warning",
      //       });
      //     }
      //   }
      // });
    },
    onError: (error) => {
      console.log('Login Failed:', error)
    }
  });

  const handleShowOtp = () => setShowOtp((prevShowOtp) => !prevShowOtp);

  const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

  const handleShowConfirmPassword = () => setShowConfirmPassword((prevConfirmShowPassword) => !prevConfirmShowPassword);

  const goBackToMainAuth = () => {
    setMainAuth(true);
    setOtpVerification(false);
    setForgotPasswordPopup(false);
    setResetPassword(false);
  };

  const mainAuthToOtpVerification = (e) => {
    e.preventDefault();
    if (isSignUp) {
      setOtpVerification(false);
      axios.get('/sanctum/csrf-cookie').then(async response => {
        axios.post('/api/user/signup', formData).then((res) => {
          var data = res.data;
          if (data !== undefined) {
            if (data.status_code === 200) {
              localStorage.setItem('profile', JSON.stringify({ ...data.data }));
              setUser(data.data);
              setIsAuthenticated(true);
              setAuthUserType(data.data.auth_type);
              swal({
                title: "Sign Up!",
                text: data.message,
                icon: "success",
                timer: 1000,
                button: false,
              }).then(() => {
                navigate('/');
                handleClose();
              });
            } else if (data.status_code === '401') {

              var messageText = '';
              if (data.errors.email) {
                messageText = data.errors.email[0];
              }
              if (data.errors.password) {
                messageText = messageText + '\n' + data.errors.password[0];
              }
              swal({
                title: data.message,
                text: messageText,
                icon: "warning",
              });
            } else {
              swal({
                // title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            }
          }
        });
      });
    } else {
      /**
       * Login In 
       */
      axios.get('/sanctum/csrf-cookie').then(async response => {
        setLoading(true);
        axios.post('/api/user/login', formData).then((response) => {
          var data = response.data;
          if (data !== undefined) {
            if (data.status_code === 200) {
              localStorage.setItem('profile', JSON.stringify({ ...data.data }));
              setUser(data.data);
              setIsAuthenticated(true);
              setAuthUserType(data.data.auth_type);
              swal({
                title: "Sign In",
                text: data.message,
                icon: "success",
                timer: 1000,
                button: false,
              }).then(() => {
                if (data.data.auth_type === '2' || data.data.auth_type === 2) {
                  navigate('/dashboard');
                } else {
                }
                handleClose();
              });
              // dispatch(signin(formData, navigate));
              setLoading(false);
            } else if (data.status_code === '401' || data.status_code === 401) {
              /**
               * Credentials failed
               */
              var messageText = '';
              if (data.errors.email) {
                messageText = data.errors.email[0];
              }
              if (data.errors.password) {
                messageText = messageText + '\n' + data.errors.password[0];
              }
              swal({
                title: data.message,
                text: messageText,
                icon: "warning",
              });
              setLoading(false);
            }
          }
        });
        setLoading(false);
      });

    }
  };

  // Submit after OTP confirm
  const handleOTPSubmit = (e) => {
    e.preventDefault();
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/frontend/user/check-otp', formData).then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            swal({
              text: data.message,
              icon: "success",
              timer: 2000,
              button: false,
            });
            setOtpVerification(false);
            setResetPassword(true);
          } else if (data.response_code === '401') {
            var messageText = '';
            if (data.errors.email) {
              messageText = data.errors.email[0];
            }
            if (data.errors.password) {
              messageText = messageText + '\n' + data.errors.password[0];
            }
            swal({
              title: data.message,
              text: messageText,
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  };


  /**
   * Check user/Student SignIn.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const resendOTP = () => {
    console.log('formData', formData);
    axios.post('/api/frontend/user/forgot-password', formData).then((response) => {
      var data = response.data;
      if (data !== undefined) {
        if (data.response_code === 200) {
          swal({
            text: data.message,
            icon: "success",
            timer: 1500,
            button: false,
          })
          setLoading(false);
        } else if (data.status_code === '401' || data.status_code === 401) {
          /**
           * Credentials failed
           */
          var messageText = '';
          if (data.errors.mobile_no) {
            messageText = data.errors.mobile_no;
          }
          swal({
            title: data.message,
            text: messageText,
            icon: "warning",
            timer: 1500,
          });
          setLoading(false);
        }
      }
    });
    setLoading(false);
    return false;
  }

  const handleResetPassword = (e) => {
    e.preventDefault();
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/frontend/user/reset-password', formData).then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            swal({
              text: data.message,
              icon: "success",
              timer: 2000,
              button: false,
            });
            setMainAuth(true);
            handleClose();
          } else if (data.response_code === '401') {

            var messageText = '';
            if (data.errors.email) {
              messageText = data.errors.email[0];
            }
            if (data.errors.password) {
              messageText = messageText + '\n' + data.errors.password[0];
            }
            swal({
              title: data.message,
              text: messageText,
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  };

  // Submit after OTP confirm
  const handleUserType = (e) => {
    e.preventDefault();
    if (userType === 3) {
      setUserType(2);
      setFormData({
        ...formData, ['user_type']: 2
      });
    } else {
      setUserType(3);
      setFormData({
        ...formData, ['user_type']: 3
      });
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault(e);
    setMainAuth(false);
    setForgotPasswordPopup(true);
  }
  const handleForgotPaswordSubmit = (e) => {
    e.preventDefault(e);
    setMainAuth(false);
    setForgotPasswordPopup(true);
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/frontend/user/forgot-password', formData).then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            setOtpVerification(true);
            setForgotPasswordPopup(false);
            swal({
              text: data.message,
              icon: "success",
              timer: 2000,
              button: false,
            });
          } else if (data.response_code === '401') {
            swal({
              text: data.message,
              icon: "warning",
            });
          } else {
            swal({
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });

  }

  /**
   * Check user login and user type.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const checkAuth = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile !== undefined && profile !== null) {
      const token = profile.token;
      if (token) {
        setIsAuthenticated(profile.token);
        setAuthUserType(((profile) ? profile.auth_type : false));
      } else {
        setIsAuthenticated(false);
        setAuthUserType(false);
      }
      return token ? true : false;
    }
  }

  /**
   * Check user/Student SignIn.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const checkUserAuth = () => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile !== undefined && profile !== null) {
      const token = profile.token;
      if (token) {
        setIsAuthenticated(profile.token);
        if (profile.auth_type === '3' || profile.auth_type === 3) {
          setAuthUserType(profile.auth_type);
          return true;
        }
      } else {
        setIsAuthenticated(false);
        setAuthUserType(false);
        return false;
      }
    }
    return false;
  }



  return (
    <>
      <AuthStateContext.Provider value={{
        // authSignIn,
        authSignOut,
        isAuthenticated,
        auth_user_type,
        setOpenAuth,
        setIsSignUp,
        checkAuth,
        checkUserAuth,
        setUserType,
      }}>
        {children}
      </AuthStateContext.Provider>
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        maxWidth="md"
        open={openAuth}
        onClose={handleClose}
      >
        <div className="auth">
          {mainAuth && (
            <form className="auth__main" onSubmit={mainAuthToOtpVerification}>
              {isSignUp && (
                <>
                  <ul className="flex p-1 mx-auto mb-8 border border-dashed rounded-md w-60 border-slate-300 dark:border-darkmode-300" role="tablist" aria-orientation="horizontal">
                    <li className={(userType === 3) ? "active-type focus-visible:outline-none flex-1" : "focus-visible:outline-none flex-1"}>
                      <button onClick={handleUserType} className={(userType === 3) ? 'cursor-pointer block appearance-none border-transparent dark:text-white rounded-md border-0 btnstrong text-white font-medium w-full py-1.5 px-2' : 'cursor-pointer block appearance-none border-transparent dark:text-white rounded-md border-0  font-medium w-full py-1.5 px-2'}>
                        Student
                      </button>
                    </li>
                    <li className={(userType === 2) ? "active-type focus-visible:outline-none flex-1" : "focus-visible:outline-none flex-1"} style={{ marginLeft: '-20px' }}>
                      <button onClick={handleUserType} className={(userType === 2) ? 'cursor-pointer block appearance-none border-transparent dark:text-white rounded-md border-0 btnstrong text-white font-medium w-full py-1.5 px-2' : 'cursor-pointer block appearance-none border-transparent dark:text-white rounded-md border-0  font-medium w-full py-1.5 px-2'}>
                        Teacher
                      </button>
                    </li>
                  </ul>
                </>
              )}

              {(isSignUp && userType === 2) ? (
                <>
                </>
              ) : (
                <>
                  <button type="button" className="auth__google" onClick={() => login()}>
                    <img src={googlelogin} alt="Login with Google" />
                    {isSignUp ? 'Signup' : 'Login'}
                    {' '}
                    with Google
                  </button>
                  <p className="auth__divider">
                    Or
                    {' '}
                    {isSignUp ? 'Signup' : 'Login'}
                    {' '}
                    With
                  </p>
                </>
              )}

              {isSignUp && (
                <>
                  <div>
                    <span>Name</span>
                    <Input name="name" placeholder="Your Name" value={formData.name}
                      // handleChange={handleChange}
                      handleChange={(e) => {
                        if (e.target.value.length > 50) {
                          return false;
                        } else {
                          handleChange(e)
                        }
                      }}
                      type="text" />
                  </div>
                </>
              )}
              <div className={isSignUp ? "auth__name" : ''}>
                <div>
                  <span>Email</span>
                  <Input name="email" placeholder="Enter email" value={formData.email}
                    handleChange={(e) => {
                      if (e.target.value.length > 45) {
                        return false;
                      } else {
                        handleChange(e)
                      }
                    }} type="email" />
                </div>
                {isSignUp && (
                  <>
                    <div>
                      <span>Mobile Number</span>
                      <Input name="mobile_no" placeholder="Enter Mobile Number" value={formData.mobile_no}
                        handleChange={(e) => {
                          if (e.target.value.length > 10) {
                            return false;
                          } else {
                            handleChange(e)
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className={isSignUp ? "auth__pwd" : ''}>
                <div>
                  <span>Password</span>
                  <Input name="password" placeholder="Enter your password" value={formData.password}
                    handleChange={(e) => {
                      if (e.target.value.length > 25) {
                        return false;
                      } else {
                        handleChange(e)
                      }
                    }} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
                  {!isSignUp && (
                    <p className='forgot-pwd'>
                      <Link to="/" onClick={handleForgotPassword}>
                        <span>Forgot Password ?</span>
                      </Link>
                    </p>
                  )}
                </div>
                {isSignUp && (
                  <>
                    <div>
                      <span>Confirm Password</span>
                      <Input name="confirmPassword" placeholder="Confirm password" value={formData.confirmPassword}
                        handleChange={(e) => {
                          if (e.target.value.length > 25) {
                            return false;
                          } else {
                            handleChange(e)
                          }
                        }}
                        type={showConfirmPassword ? 'text' : 'password'} handleShowPassword={handleShowConfirmPassword} />
                    </div>
                  </>
                )}
              </div>
              {/* <button type="submit" className="auth__email">{isSignUp ? 'Sign up' : 'Login'}</button> */}
              <Button disabled={loading}
                className="auth__email"
                type="submit"
              >{isSignUp ? 'Sign up' : 'Login'}</Button>
              <p>
                By signing up, you agree to our
                {' '}
                <Link to="/terms-and-conditions"><span>Terms of Use</span></Link>
                {' '}
                and
                {' '}
                <Link to="/privacy-policy"><span>Privacy Policy.</span></Link>
              </p>
              <center className="auth__switchMode">
                {isSignUp ? (
                  <p>
                    Already have an Account?
                    {' '}
                    <span onClick={() => setIsSignUp(false)}>Sign in here</span>
                  </p>
                ) : (
                  <p>
                    Don&apos;t have an Account?
                    {' '}
                    <span onClick={() => setIsSignUp(true)}>Sign up here</span>
                  </p>
                )}
              </center>
            </form>
          )}

          {resetPassword && (
            <form className="auth__main" onSubmit={handleResetPassword}>
              <IconButton className="auth__otpGoBack" onClick={goBackToMainAuth} style={{ position: "absolute" }}>
                <ArrowBackIcon />
              </IconButton>
              <h3 className='text-center'>Change or Reset your password <hr /> </h3>
              <div className="auth__pwd">
                <div>
                  <span>Password</span>
                  <Input name="password" placeholder="Enter your password" value={formData.password}
                    handleChange={(e) => {
                      if (e.target.value.length > 25) {
                        return false;
                      } else {
                        handleChange(e)
                      }
                    }} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
                </div>
                <div>
                  <span>Confirm Password</span>
                  <Input name="confirmPassword" placeholder="Confirm password" value={formData.confirmPassword}
                    handleChange={(e) => {
                      if (e.target.value.length > 25) {
                        return false;
                      } else {
                        handleChange(e)
                      }
                    }}
                    type={showConfirmPassword ? 'text' : 'password'} handleShowPassword={handleShowConfirmPassword} />
                </div>
              </div>
              <Button disabled={loading}
                className="auth__email"
                type="submit"
              >Reset Password</Button>
              <br />
              <br />
              <p>
                By signing up, you agree to our
                {' '}
                <Link to="/terms-and-conditions"><span>Terms of Use</span></Link>
                {' '}
                and
                {' '}
                <Link to="/privacy-policy"><span>Privacy Policy.</span></Link>
              </p>
              <center className="auth__switchMode">
                <p>
                  Don&apos;t have an Account?
                  {' '}
                  <span onClick={goBackToMainAuth}>Sign up here</span>
                </p>
              </center>
            </form>
          )}

          {otpVerification && (
            <form className="auth__otp" onSubmit={handleOTPSubmit}>
              <IconButton className="auth__otpGoBack" onClick={goBackToMainAuth}>
                <ArrowBackIcon />
              </IconButton>
              <span>Enter OTP Sent to your Mobile Number</span>
              <Input name="otp"
                placeholder="Enter OTP"
                handleChange={handleChange}
                type={showOtp ? 'text' : 'password'}
                handleShowPassword={handleShowOtp}
                sx={{ marginBottom: '15px', background: '#FFFFFF', borderRadius: '10px' }} />
              <Button
                type="button" className="whtebtn pull-right" onClick={() => resendOTP()}
                sx={{ padding: '6px !important', width: 'unset !important', fontSize: '15px !important', margin: '0px 0px 20px 140px', fontWeight: "600" }}
              >
                Resend OTP
              </Button>
              <button type="submit" disabled={formData.otp.length !== 5} className="auth__email">VERIFY</button>

            </form>
          )}

          {forgotPasswordPopup && (
            <form className="auth__otp" onSubmit={handleForgotPaswordSubmit}>
              <IconButton className="auth__otpGoBack" onClick={goBackToMainAuth} style={{ position: "absolute" }}>
                <ArrowBackIcon />
              </IconButton>
              <h3 className='text-center'>Password assistance <hr /> </h3>

              <div className={isSignUp ? "auth__name" : ''}>
                <div>
                  <span>Enter Your Mobile Number</span>
                  <Input name="mobile_no" placeholder="Enter Your Mobile Number" value={formData.mobile_no}
                    handleChange={(e) => {
                      if (e.target.value.length > 45) {
                        return false;
                      } else {
                        handleChange(e)
                      }
                    }}
                    type="number" />
                </div>
              </div>
              <button type="submit" className="auth__email">Submit</button>
              <br />
              <p>
                By signing up, you agree to our
                {' '}
                <Link to="/terms-and-conditions"><span>Terms of Use</span></Link>
                {' '}
                and
                {' '}
                <Link to="/privacy-policy"><span>Privacy Policy.</span></Link>
              </p>
              <center className="auth__switchMode">
                <p>
                  Don&apos;t have an Account?
                  {' '}
                  <span onClick={goBackToMainAuth}>Sign up here</span>
                </p>
              </center>
            </form>
          )}


        </div>
      </Dialog>


    </>
  );
};

export default AuthProvider;