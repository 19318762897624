import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import {
  Card,
  Divider,
  CardContent,
  CardHeader,
  Box,
  Grid,
  Button,
  styled,
  useTheme,
  TextField, Alert
} from "@mui/material";
import Moment from 'moment';
import { Formik } from 'formik';
import swal from 'sweetalert';
const Item = styled("div")(({ theme }) => (
  {
    marginBottom: "5px",
    fontSize: '16px',
    fontWeight: '400',
    color: '#484747'
  }
));
const ItemContent = styled("div")(({ theme }) => (
  {
    marginBottom: "15px",
    fontSize: '16px',
    fontWeight: '600',
  }
));

const Container = styled("div")(({ theme }) => (
  {
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }
));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  // padding: '32px',
  position: 'relative',
  // background: 'rgba(0, 0, 0, 0.01)',
}));

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .required('Comment is required!'),
});


const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '5px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  cursor: 'pointer'
}));

const ViewTicket = () => {
  const { palette } = useTheme();
  const bgInfo = palette.info.main;
  const bgError = palette.error.main;
  const bgSuccess = palette.success.main;
  const bgWarning = palette.warning.main;

  const { ticket_id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [ticket, setTicket] = useState(false);
  const [comments, setComments] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
    fetchTicketComments();
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-support-ticket', {
          ticket_id: ticket_id
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setTicket(response.data.data);
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.error(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Fetching data from server.
   */
  const fetchTicketComments = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-ticket-comments', {
          ticket_id: ticket_id
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setComments(response.data.data);
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.error(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  const initialValues = {
    ticket_id: ticket_id,
    comment: '',
  };
  const handleFormSubmit = (values, errors) => {
    try {
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/add-ticket-comment', values)
          .then(response => {
            if (response.data.response_code === 200) {
              swal({
                text: response.data.message,
                icon: "success",
              });
              values.comment = '';
              fetchTicketComments();
            } else {
              swal({
                title: "Warning!",
                text: response.data.message,
                icon: "warning",
              });
              errors.setErrors(response.data.errors);
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <>
      <div className="pagetitle">
        <h1>Ticket Details</h1>
      </div>
      <Card title="Ticket Details">
        <ContentBox>
          <CardHeader
            title="Ticket Details"
            titleTypographyProps={{
              variant: "h4",
              fontSize: '18px',
              fontWeight: '600',
              // textTransform: 'uppercase',
              color: theme.palette.primary.main,

            }}
            action={
              <>
                <Button title="Back" component={Link}
                  to={`/dashboard/supports`}
                  color="inherit" variant="outlined" size="small" sx={{ m: .5 }}>
                  Back
                </Button>
              </>
            }
          />
          <Divider />
          <CardContent>
            <Box width="100%" overflow="auto">
              {ticket && (
                <>
                  <Grid container>
                    <Grid item sm={6} xs={12} lg={2}>
                      <Item>Order Id</Item>
                      <ItemContent>{ticket.hash_order_id}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={5}>
                      <Item>Product</Item>
                      <ItemContent>{ticket.topic_title}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={5}>
                      <Item>Ticket Title</Item>
                      <ItemContent>{ticket.ticket_title}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={2}>
                      <Item>Status</Item>
                      <ItemContent>
                        {(() => {
                          switch (ticket.status) {
                            case 'closed':
                              return <Small bgcolor={bgSuccess} title="Closed">Closed</Small>
                            case 'pending':
                              return <Small bgcolor={bgWarning} variant="contained" size="small">Pending</Small>
                            case 'process':
                              return <Small bgcolor={bgInfo} variant="contained" size="small">Process</Small>
                            default:
                              return null
                          }
                        })()}
                      </ItemContent>
                    </Grid>

                    <Grid item sm={6} xs={12} lg={5}>
                      <Item>Ticket Date</Item>
                      <ItemContent>{Moment(ticket.created_at).format('DD/MM/YYYY')}</ItemContent>
                    </Grid>

                    <Grid item sm={12} xs={12} lg={12}>
                      <Item>Description</Item>
                      <ItemContent>{ticket.description}</ItemContent>
                    </Grid>

                  </Grid>
                  <br />
                  <br />
                  <Divider />
                  <br />
                  <br />
                  <h4 style={{ color: theme.palette.primary.main, fontSize: '18px', }}>Ticket Comments</h4>
                  <br />
                  <Divider />
                  <br />
                  {/* comments */}
                  <Grid container>
                    {comments && (
                      comments.map((row, index) => (
                        <Grid className={`comment ${((row.user_id === ticket.viewed_user_id) ? 'right' : '')}`} key={`comment${index}`}>
                          <Grid item sm={6} xs={12} lg={2}>
                            <div className="comment-author-ava">
                              {row.profile_image && (
                                <img src={row.profile_image} alt="Avatar" />
                              )}
                            </div>
                          </Grid>
                          <Grid item sm={12} xs={12} lg={9}>
                            <div className="comment-body">
                              <p className="comment-text">
                                {row.comment}
                              </p>
                              <div className="comment-footer">
                                <span className="comment-meta">
                                  {row.name}
                                </span>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      ))
                    )}
                    {/* <Grid className="comment">
                      <Grid item sm={6} xs={12} lg={2}>
                        <div class="comment-author-ava">
                          <img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="Avatar" />
                        </div>
                      </Grid>
                      <Grid item sm={12} xs={12} lg={9}>
                        <div class="comment-body">
                          <p class="comment-text">
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
                            beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                            sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                          </p>
                          <div class="comment-footer">
                            <span class="comment-meta">
                              Jacob Hammond, Staff</span>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className="comment right">
                      <Grid item sm={6} xs={12} lg={2}>
                        <div class="comment-author-ava">
                          <img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="Avatar" />
                        </div>
                      </Grid>
                      <Grid item sm={12} xs={12} lg={9} className="comment-body">
                        <p className="comment-text">
                          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                          totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
                          beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                          sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                        </p>
                        <div className="comment-footer">
                          <span className="comment-meta">
                            Jacob Hammond, Staff</span>
                        </div>
                      </Grid>
                    </Grid> */}
                  </Grid>
                  {/* <div class="comment">
                    <div class="comment-author-ava">
                      <img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="Avatar" />
                    </div>
                    <div class="comment-body">
                      <p class="comment-text">
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                        totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
                        beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                        sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                      </p>
                      <div class="comment-footer">
                        <span class="comment-meta">
                          Jacob Hammond, Staff</span>
                      </div>
                    </div>
                  </div>
                  <div class="comment right">
                    <div class="comment-author-ava">
                      <img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="Avatar" />
                    </div>
                    <div class="comment-body">
                      <p class="comment-text">
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                        totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
                        beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                        sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                      </p>
                      <div class="comment-footer">
                        <span class="comment-meta">
                          Jacob Hammond, Staff</span>
                      </div>
                    </div>
                  </div> */}
                  {(ticket.status === 'closed') ? (
                    <Alert severity="success">Ticket has been cloded!</Alert>
                  ) : (
                    <Formik
                      onSubmit={
                        (values, errors) => {
                          handleFormSubmit(values, errors);
                        }
                      }
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                    >
                      {
                        ({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} lg={4}></Grid>
                              <Grid item sm={6} xs={12}>
                                <label htmlFor="comment" className='form-label'>Comment</label>
                                <TextField
                                  fullWidth
                                  multiline={true}
                                  minRows={2}
                                  size="small"
                                  type="text"
                                  name="comment"
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  value={values.comment}
                                  onChange={handleChange}
                                  placeholder="Type comment here (at least 3 chars)"
                                  helperText={touched.comment && errors.comment}
                                  error={Boolean(errors.comment && touched.comment)}
                                  sx={{ mb: 3 }}
                                />
                              </Grid>
                              <Grid item sm={4} xs={12} lg={2}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  type='submit'
                                  sx={{ mt: 5 }}
                                >
                                  Submit
                                </Button>
                              </Grid>
                            </Grid>

                          </form>
                        )}
                    </Formik>
                  )}
                </>
              )}
            </Box >
          </CardContent >
        </ContentBox >
      </Card >
    </>

  );
};

export default ViewTicket;
