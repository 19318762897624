import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import RelatedLearningMaterial from './RelatedLearningMaterial';
import { useCart } from '../../../contexts/CartContext';
import { useAuth } from '../../../contexts/AuthContext';
import SEO from './../../layouts/SEO';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { saveAs } from 'file-saver';
import axios from 'axios';
import swal from 'sweetalert';
import PreviewIcon from '@mui/icons-material/Preview';
import PreviewModalDialog from '../PreviewModalDialog';

import {
    Grid, Avatar, CircularProgress, Box, Button, Rating
} from '@mui/material';

function PaperDetail() {
    const { isAuthenticated, checkUserAuth } = useAuth();
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [paper, setPaper] = useState(false);
    const [loading, setLoading] = useState(false);
    const [slug, setSlug] = useState(params.slug);
    const [subject, setSubject] = useState(params.subject);
    const { addToCart, isItemInCart, addToWishlist, removeFormWishlist } = useCart();
    const [previewModalOpen, setPreviewModalOpen] = useState(false);

    useEffect(() => {
        if (slug !== params.slug) {
            setSlug(params.slug);
        } else {
            fetchData();
        }
    }, [slug, location, navigate]);

    /**
     * Fetching data from server.
     */
    const fetchData = async () => {
        setLoading(true);
        try {
            var api_url = '/api/frontend/get-paper-detail';
            if (isAuthenticated) {
                var api_url = '/api/frontend/get-auth-paper-detail';
            }
            axios.post(api_url, { 'slug': slug })
                .then(response => {
                    if (response.data.response_code === 200) {
                        setPaper(response.data.data);
                    } else if (response.data.response_code === 401) {

                    } else {

                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
        } catch (e) {
            setLoading(false);
        }
    }

    /**
     * Add Product in to wishlist
     */
    const productAddToWishlist = (paper) => {
        if (checkUserAuth()) {
            addToWishlist(paper.id);
            setPaper({ ...paper, in_wishlist: 1 });
        } else {
            swal({
                text: 'Please logged out from current login and Signin or Signup as student or user for Add To Wishlist!',
                icon: 'warning',
                buttons: false,
                timer: 2000
            });
            return false;
        }
    }

    /**
     * Remove Product from to wishlist
     */
    const productRemoveFormWishlist = (paper) => {
        removeFormWishlist(paper.id);
        setPaper({ ...paper, in_wishlist: 0 });
    }

    /**
     * Download zip file of order.
     */
    const downloadProduct = async (product_order_row_id) => {
        if (product_order_row_id) {
            setLoading(true);
            try {
                await axios.get('/sanctum/csrf-cookie').then(response => {
                    axios.post('/api/dashboard/download-zip', {
                        row_id: product_order_row_id
                    }
                        , { responseType: 'blob' }
                    )
                        .then((...responses) => {
                            if (response.data.response_code === "401" || response.data.response_code === 401) {
                                // console.log(response.data.response_code);
                            } else {
                                responses.map((res) => (
                                    saveAs(res.data, 'Irevize_Purchase_' + product_order_row_id + '.zip')
                                ))
                            }
                        });
                });
                setLoading(false);
            } catch (e) {
                setLoading(false);
            }
        }
    }
    const permalink = (row) => {
        var subject = row.subject_name;
        var subject_slug = subject.replace(' ', '-').toLowerCase();
        if (row.type === 'practice') {
            var url = `https://www.irevize.com/samplepaper/paper-detail/${subject_slug}/${row.slug}`;
        } else {
            var url = `https://www.irevize.com/notes/paper-detail/${subject_slug}/${row.slug}`;
        }
        return url;
    }

    const get_subject_name = (row) => {
        return row.subject_name;
    }
    const get_exam_name = (row) => {
        var exam_name = '';
        if (row.board_id) {
            exam_name = row.class_name;
        } else if (row.university_id) {
            exam_name = row.university_name;
        } else if (row.entrance_exam_id) {
            exam_name = row.entrance_exam_name;
        } else if (row.competitive_exam_id) {
            exam_name = row.competitive_exam_name;
        }
        return exam_name;
    }
    // Product Preview
    const visiblityPreviewModal = (rowData) => {
        setPreviewModalOpen(true);
    };
    return (
        <>
            {paper && (
                <SEO
                    title={paper.topic_title}
                    description={`Download ${(paper.type === 'learning') ? 'Learning material' : 'Practice Test'} for ${paper.topic_title}`}
                    keywords={`${paper.class_name}, Download ${(paper.type === 'learning') ? 'Learning material' : 'Practice Test'} for ${paper.topic_title}, Download ${(paper.type === 'learning') ? 'Learning material' : 'Practice Test'} for ${paper.class_name}`}
                    canonical={permalink(paper)}
                />
            )}
            <div className="breadcrumb_section  page-title-mini">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <ol className="breadcrumb ">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item">
                                    <Link to={`/search?q=&selected_subjects=${paper.subject_id}`}>
                                        {paper.subject_name}
                                    </Link>
                                </li>
                                {/* <li className="breadcrumb-item"><Link to="#">Web Development</Link></li> */}
                                <li className="breadcrumb-item active">{paper.topic_title}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main_content">
                <div className="section">
                    <div className="container">
                        {paper && (

                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="row align-items-center prod-detail">
                                        <div className="col-12">
                                            <h1 className="btn-shine">{paper.topic_title}</h1>
                                            {/* <h4>{paper.sub_topic_title}</h4> */}
                                            <ul className="rating-prolist">
                                                <li>
                                                    <span>{paper.total_review}</span>
                                                    <Rating className="rating" value={paper.avg_rating}
                                                        name="size-medium" size="medium" readOnly />
                                                </li>
                                                <li>{paper.downloads} - Downloads</li>
                                                {(paper.type === 'practice') ? (
                                                    <li>{paper.paper_level} Level</li>
                                                ) : ''}
                                            </ul>
                                            <p>{paper.description}</p>

                                            {paper.what_you_will_learn_keywords ? (
                                                <>
                                                    <h3 className="btn-shine mt-30">What you will learn</h3>
                                                    <ul className="check-prolist">
                                                        {paper.what_you_will_learn_keywords.split(',').map((keyword) =>
                                                            <li>{keyword}</li>
                                                        )}
                                                    </ul>
                                                </>
                                            ) : ''}

                                            {paper.about_me ? (
                                                <div className="mt-30 prodbgbox">
                                                    <h3 className="btn-shine mt-30 text-center1">Instructors</h3>
                                                    <br />
                                                    <Grid container>
                                                        <Grid item sm={6} xs={12} lg={3}>
                                                            <div className='auther-profile1'>
                                                                {paper.profile_image ?
                                                                    <img src={paper.profile_image} title={paper.author_name} alt={paper.author_name} style={{ width: "180px", borderRadius: "50%", border: "1px solid #CCC" }} />
                                                                    :
                                                                    <Avatar src="/broken-image.jpg" title={paper.author_name} sx={{ width: 180, height: 180 }} />
                                                                }
                                                            </div>
                                                        </Grid>
                                                        <Grid item sm={6} xs={12} lg={8}>
                                                            <div className='instruct-detail'>
                                                                <p> <strong>Professional summary :</strong></p>
                                                                <p>Name : <strong>{paper.author_name}</strong></p>
                                                                <p>Organization Type : <strong>{paper.organization_type_name}</strong></p>
                                                                <p>Education/Qualification : <strong>{paper.qualification}</strong></p>
                                                                <p>Experience as a Teacher : <strong>{paper.experience_as_teacher} Years</strong></p>
                                                                {/* <p>Students : <strong>School</strong></p> */}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <br />
                                                    <p>{paper.about_me}</p>

                                                    {paper.key_strengths ? (
                                                        <>
                                                            <h4>Key Strengths</h4>
                                                            <ul className="prodbgbox mt-30">
                                                                {paper.key_strengths.split(',').map((keyword) =>
                                                                    <li>{keyword}</li>
                                                                )}
                                                            </ul>
                                                        </>
                                                    ) : ''}

                                                </div>
                                            ) : ''}
                                            <div className="mt-30 prodbgbox">
                                                <h3 className="btn-shine mt-10 text-center1">Details</h3>
                                                <br />
                                                <p>
                                                    Looking to ace {get_subject_name(paper)} exams for {get_exam_name(paper)}? Look no further! Our comprehensive study materials offer the best sample papers tailored to {get_subject_name(paper)} topics.
                                                    With a focus on key subjects, our practice papers provide invaluable insights into exam patterns and question formats,
                                                    ensuring you're well-prepared on exam day.
                                                </p>
                                                <p>
                                                    Designed by expert educators, our sample papers cover all essential {get_subject_name(paper)} topics for {get_exam_name(paper)} with detailed explanations and practice questions.
                                                    Whether it's mastering {get_subject_name(paper)} concepts or delving deep into specific {get_subject_name(paper)} topics,
                                                    our study materials are your ultimate study companion.
                                                </p>
                                                <p>
                                                    Why settle for mediocre when you can access the best practice papers? Our sample papers are meticulously crafted to simulate real exam conditions,
                                                    giving you the confidence to tackle any {get_subject_name(paper)} question that comes your way.
                                                    Plus, with our user-friendly format and clear instructions, studying {get_subject_name(paper)} becomes a breeze.
                                                </p>
                                                <p>
                                                    Don't leave your {get_subject_name(paper)} exam success to chance. Invest in our best {get_subject_name(paper)} sample papers today and unlock your potential.
                                                    Get ready to ace your {get_subject_name(paper)} exams for {get_exam_name(paper)} with ease and confidence!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="sidebar prodimgbar">
                                        <div className="widget">
                                            <img src={(paper.thumb_nail_url ? paper.thumb_nail_url : ((paper.thumb_nail_image) ?? paper.thumb_nail_image))} alt={paper.topic_title} width={'100%'} />
                                            {/* <iframe src={paper.paper_file_url} width={"100%"} height={"500px"} /> */}
                                            <div className="product_info">
                                                <div className='author-info-wrap'>
                                                    <h6 className='product-subject'>
                                                        <div className='auther-profile'>
                                                            {paper.profile_image ?
                                                                <img src={paper.profile_image} alt={paper.author_name} />
                                                                :
                                                                <Avatar src="/broken-image.jpg" />
                                                            }
                                                        </div>
                                                        {paper.subject_name.split(' ')[0]}{(paper.subject_name.split(' ')[1]) ? '...' : ''}
                                                        <small>{paper.author_name}</small>
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="prodcontbox">
                                                {(paper.order_payment_status && paper.order_payment_status === 'success') ? (
                                                    <>
                                                        <Button fullWidth onClick={() => downloadProduct(paper.product_order_row_id)} variant="contained" size='large' className="btn btn-fill-out rounded-0 checkout display-block">
                                                            Download <DownloadForOfflineIcon />
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <h3>
                                                            <div>
                                                                <small className="price1" style={{ textDecoration: "line-through" }}>
                                                                    {process.env.REACT_APP_CURRENCY} {paper.gst_price}
                                                                </small>
                                                                <small>{paper.discount_percentage ? ` ( -${paper.discount_percentage}%)` : ' ( -0%)'}</small>
                                                                <small>{` (Inclusive GST)`}</small>
                                                                <small>
                                                                    {(paper.pdf_preview) ?
                                                                        <PreviewIcon
                                                                            className='pdf-preview-icon'
                                                                            onClick={() => visiblityPreviewModal(paper)}
                                                                        />
                                                                        :
                                                                        ''
                                                                    }
                                                                </small>

                                                            </div>
                                                            {process.env.REACT_APP_CURRENCY} {paper.gst_selling_price}

                                                            {paper.in_wishlist ? (
                                                                <Button color='secondary' aria-label="Name" onClick={() => productRemoveFormWishlist(paper)} size='large' style={{ float: "right" }}>
                                                                    <FavoriteIcon></FavoriteIcon>
                                                                </Button>
                                                            ) : (
                                                                <Button color='secondary' aria-label="Name" onClick={() => productAddToWishlist(paper)} size='large' style={{ float: "right" }}>
                                                                    <FavoriteBorderIcon></FavoriteBorderIcon>
                                                                </Button>
                                                            )}
                                                        </h3>
                                                        {/* <Link to="#" className="btn btn-fill-out rounded-0 checkout display-block">Add to Cart</Link> */}
                                                        {isItemInCart(paper.id) ? (
                                                            <Button fullWidth variant="contained" size='large' className="btn btn-fill-out1 checkout display-block">
                                                                <span>Added to cart</span>
                                                            </Button>
                                                        ) : (
                                                            <Button fullWidth onClick={() => addToCart(paper.id)} variant="contained" size='large' className="btn btn-fill-out rounded-0 checkout display-block">
                                                                <span>Add To Cart</span>
                                                            </Button>
                                                        )}
                                                        <Link to="#" onClick={() => addToCart(paper.id, true)} className="btn btn-fill-line rounded-0 view-cart display-block mt-10 ml-0">Buy Now</Link>
                                                    </>
                                                )}

                                                <h6>{(paper.type === 'learning') ? 'Learning material' : 'Practice Test'} Includes</h6>
                                                <ul>
                                                    <li>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/board.png`} alt="Board Icon" />
                                                        <strong>Board/University/Entrance Exam</strong>
                                                        <span>
                                                            {paper.board_id
                                                                ? `${paper.board_name} Board`
                                                                : <>
                                                                    {paper.university_id
                                                                        ? paper.university_name
                                                                        : <>
                                                                            {paper.entrance_exam_id
                                                                                ? paper.entrance_exam_name
                                                                                : <>
                                                                                    {paper.competitive_exam_id
                                                                                        ? paper.competitive_exam_name
                                                                                        : <>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/level-m.png`} alt="Class Icon" />
                                                        <strong>Class</strong>
                                                        <span>{paper.class_name}</span>
                                                    </li>
                                                    {(paper.type === 'practice') ? (
                                                        <>
                                                            <li>
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/numer-question.png`} alt="Number Icon" />
                                                                <strong>Number of Questions</strong>
                                                                <span>{paper.number_of_questions}</span>
                                                            </li>
                                                            <li>
                                                                <img src={`${process.env.PUBLIC_URL}/assets/images/level-m.png`} alt="Level Icon" />
                                                                <strong>Difficulty Level</strong>
                                                                <span>{paper.paper_level}</span>
                                                            </li>
                                                        </>
                                                    ) : ''}
                                                </ul>

                                            </div>


                                        </div>

                                    </div>
                                    <PreviewModalDialog previewModalOpen={previewModalOpen} paper={paper} setPreviewModalOpen={setPreviewModalOpen} />
                                    {/* <div className="lgtctr"><Link to="#">Find out more</Link> about Latest courses</div> */}
                                </div>
                            </div>
                        )}
                        <RelatedLearningMaterial></RelatedLearningMaterial>
                    </div>
                </div>
            </div >
        </>
    );
}
export default PaperDetail;
