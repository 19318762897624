import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import FrontendLayout from './views/layouts/FrontendLayout';
import DashboardLayout from './views/layouts/DashboardLayout';
import AuthProvider from './contexts/AuthContext';

import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_PATH;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const profile = JSON.parse(localStorage.getItem('profile'));

  if (profile !== undefined && profile !== null) {
    const token = profile.token;
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  return config;
});

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
 
  useEffect(() => {
    

    // prevents the default right-click menu from appearing
    
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById('root');
    rootElement.addEventListener('contextmenu', handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };

    // End Right click desable
    
  }, []);
  return (

    <Router basename={process.env.REACT_APP_BASE_PATH}>
      <ScrollToTop>
        <AuthProvider >
          <Routes>

            <Route exact path="/*" element={<FrontendLayout />} >
              {/* All Frontend Route in layout file */}
            </Route>

            <Route exact path="/dashboard/*" element={<DashboardLayout />}>
              {/* All Dashboard Route in layout file */}
            </Route>

          </Routes>
        </AuthProvider>
      </ScrollToTop>
    </Router >
  );
}

export default App;
