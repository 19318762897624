import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './store/initStore';
// import './index.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = configureStore();


root.render(
  
<Provider store={store}>
  {/* Local gmail login */}
  {/* <GoogleOAuthProvider clientId="265725551965-nf3sngmh4j4p5b5g9qa3rjh5bncl7c03.apps.googleusercontent.com"> */}
  <GoogleOAuthProvider clientId="732316334621-k26hvu1ajtec0jm2ibedujpr0h444lft.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>
</Provider>,
);
