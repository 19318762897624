import { useState, useEffect } from "react";
import axios from 'axios';
import swal from 'sweetalert';
import {
  CardContent,
  Box, Grid, Dialog,
  DialogTitle, DialogContent, DialogActions,
  IconButton,
  Button,
  styled,
  useTheme,
  Rating,
  TextField,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import * as Yup from 'yup';
import { Formik } from 'formik';

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
}));

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));
const Item = styled("div")(({ theme }) => (
  {
    marginBottom: "5px",
    fontSize: '16px',
    fontWeight: '400',
    color: '#484747'
  }
));
const ItemContent = styled("div")(({ theme }) => (
  {
    marginBottom: "15px",
    fontSize: '16px',
    fontWeight: '600',
  }
));
 
const validationSchema = Yup.object().shape({

});

function RatingReviewModel({ openRatingModal, setOpenRatingModal, product_id, setRatingProductId }) {
  const labels = {
    0.5: 'Useless',
    1: 'Useless',
    1.5: 'Poor',
    2: 'Poor',
    2.5: 'Ok',
    3: 'Ok',
    3.5: 'Good',
    4: 'Good',
    4.5: 'Excellent',
    5: 'Excellent',
  };
  const { palette } = useTheme();
  const bgError = palette.error.main;
  const bgPrimary = palette.primary.main;
  const bgSecondary = palette.secondary.main;
  const bgSuccess = palette.success.main;
  const bgWarning = palette.warning.main;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [formData, setFormData] = useState({
    rating: 0,
    review: ''
  });
  const [open, setOpen] = useState(false);
  const [ratingModal, setRatingModal] = useState(false);
  const [paper, setPaper] = useState([]);
  const [value, setValue] = useState(2);
  const [hover, setHover] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [ratingLoaded, setRatingLoaded] = useState(false);
  useEffect(() => {
    if (product_id && openRatingModal) {
      fetchData();
    }
  }, [openRatingModal]);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-product-rating-review', {
          product_id: product_id
        }).then((response) => {
          var data = response.data;
          if (data !== undefined) {
            if (data.response_code === 200) {
              if (data.data.rating) {
                console.log(data.data.rating);
                setFormData(data.data);
                setValue(data.data.rating);
              }
              setRatingLoaded(true);
            } else if (data.response_code === '401') {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            } else {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            }
          }
          setLoading(false);
        });
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  const handleClose = () => {
    setRatingProductId(false);
    setOpenRatingModal(false);
  };

  const getLabelText = (value) => {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  };
  const handleFormSubmit = (values, errors) => {
    try {
      if (product_id) {
        axios.get('/sanctum/csrf-cookie').then(response => {
          axios.post('/api/dashboard/add-product-rating-review', {
            product_id: product_id,
            rating: values.rating,
            review: values.review
          })
            .then(response => {
              if (response.data.response_code === 200) {
                swal({
                  text: response.data.message,
                  icon: "success",
                  buttons: false,
                  timer: 2000,
                });
                setRatingProductId(false);
                setOpenRatingModal(false);
              } else {
                swal({
                  text: response.data.message,
                  icon: "warning",
                });
                errors.setErrors(response.data.errors);
              }
              setLoading(false);
            })
            .catch(function (error) {
              console.error(error);
            });
        });
      }

    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openRatingModal}
        onClose={handleClose}>
        {ratingLoaded && (
          <Formik
            onSubmit={(values, errors) => {
              handleFormSubmit(values, errors);
            }
            }
            initialValues={formData}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, inputName }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Rate and  Review this product
                  </BootstrapDialogTitle>
                  <DialogContent dividers>
                    <CardContent>
                      <Box width="100%" overflow="auto">
                        <Grid container>
                          <Grid item sm={12} xs={12} lg={12}>
                            <Item>
                              <label htmlFor="description" className='form-label'>Rate this Product</label>
                            </Item>
                            <ItemContent>
                              <Rating
                                className="rating"
                                name="rating"
                                value={values.rating}
                                precision={0.5}
                                getLabelText={getLabelText}
                                // onChange={handleChange}
                                onChange={(event, newValue) => {
                                  // handleChange(e)
                                  setFieldValue('rating', parseFloat(newValue))
                                  setValue(newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                  setHover(newHover);
                                }}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                              />
                              {value !== null && (
                                <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                              )}
                              {paper.topic_title}
                            </ItemContent>
                          </Grid>

                          <Grid item sm={12} xs={12}>
                            <Item>
                              <label htmlFor="review" className='form-label'>Write Review</label>
                            </Item>
                            <ItemContent>
                              <TextField
                                fullWidth
                                multiline={true}
                                minRows={3}
                                size="small"
                                type="text"
                                name="review"
                                variant="outlined"
                                onBlur={handleBlur}
                                value={values.review}
                                onChange={handleChange}
                                helperText={touched.review && errors.review}
                                error={Boolean(errors.review && touched.review)}
                                sx={{ mb: 3 }}
                              />
                            </ItemContent>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      type="sublit"
                      size="small">
                      Submit
                    </Button>
                  </DialogActions>

                </div>
              </form>
            )}
          </Formik>
        )}
      </Dialog>
    </>

  );
};

export default RatingReviewModel;
