import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {
  Button, Rating, RadioGroup, FormControlLabel, Radio, Checkbox,
  FormControl, FormGroup
} from '@mui/material';
import { Formik } from 'formik';

import { useSearchFilter } from '../../../contexts/SearchFilterContext';

function LeftFilter() {
  const { filterOptions, setFilterOptions, fetchFilteredProduct } = useSearchFilter();
  const [loading, setLoading] = useState(false);
  const [organizationType, setOrganizationType] = useState(false);
  const [boards, setBoards] = useState(false);
  const [universities, setUniversities] = useState(false);
  const [entranceExams, setEntranceExams] = useState(false);
  const [competitiveExams, setCompetitiveExams] = useState(false);
  const [subjects, setSubjects] = useState(false);
  const [userCategories, setUserCategories] = useState(filterOptions.selected_subjects ? filterOptions.selected_subjects : []);
   
  useEffect(() => {
    fetchOrganizationData();
    fetchBoardData();
    fetchUniversityData();
    fetchEntranceExamData();
    fetchCompetitiveExamData();
    fetchSubjectData();
  }, []);

  useEffect(() => {
    fetchFilteredProduct();
    /**
     * Load Subject list based on above selections.
     */
    fetchSubjectData();
  }, [filterOptions]);

  const fetchOrganizationData = () => {
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/frontend/get-organization-types').then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            setOrganizationType(data.data);
          } else if (data.response_code === '401') {
            swal({
              // title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          } else {
            swal({
              // title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  }
  const fetchBoardData = () => {
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/frontend/get-boards').then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            setBoards(data.data);
          }
        }
      });
    });
  }

  const fetchUniversityData = () => {
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/frontend/get-filter-universities').then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            setUniversities(data.data);
          }
        }
      });
    });
  }
  const fetchEntranceExamData = () => {
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/frontend/get-filter-entrance-exams').then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            setEntranceExams(data.data);
          }
        }
      });
    });
  }
  const fetchCompetitiveExamData = () => {
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/frontend/get-filter-competitive-exams').then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            setCompetitiveExams(data.data);
          }
        }
      });
    });
  }

  const fetchSubjectData = () => {
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/frontend/get-subjects', {
        selected_organization: filterOptions.selected_organization,
        selected_board: filterOptions.selected_board,
        selected_university: filterOptions.selected_university,
        selected_entrance_exam: filterOptions.selected_entrance_exam,
        selected_competitive_exam: filterOptions.selected_competitive_exam
      }).then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            setSubjects(data.data);
          } else if (data.response_code === '401') {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  }

  const handleSubjectCheck = (item) => {
    return userCategories.includes(item) ? true : false;
  }

  return (
    <>
      <Formik
        onSubmit={(values, errors) => {
          // handleFormSubmit(values, errors);
        }
        }
        enableReinitialize
        initialValues={filterOptions}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="sidebar">
              <div className="widget">
                <h5 className="widget_title">Organization
                  {values.selected_organization && (
                    <Button onClick={e => {
                      setFieldValue('selected_organization', '');
                      setFilterOptions({ ...filterOptions, ['selected_organization']: '' });

                    }} className='text-right right' sx={{ float: "right" }}>Clear
                    </Button>
                  )}
                </h5>
                <ul className="list_brand">
                  {organizationType && (
                    <RadioGroup
                      row
                      name="selected_organization"
                      value={values.selected_organization}
                      onChange={e => {
                        if (e.target.value === '1' || e.target.value === 1) {
                          setFieldValue('selected_board', '');
                        }
                        setFieldValue('selected_organization', e.target.value);
                        setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
                      }
                      }
                      size="small"
                    >
                      {
                        organizationType.map((row, index) => (
                          <li key={'medi-cat-flt-' + index} style={{ width: "100%" }}>
                            <div className="custome-radio customerad">
                              <FormControlLabel value={row.id} control={<Radio size="small" />} label={row.name} sx={{ height: "25px" }} />
                            </div>
                          </li>
                        ))
                      }
                    </RadioGroup>
                  )}
                </ul>
              </div>

              {(filterOptions.selected_organization === '1' || filterOptions.selected_organization === 1) ? (
                <div className="widget">
                  <h5 className="widget_title">Board
                    {values.selected_board && (
                      <Button onClick={e => {
                        setFieldValue('selected_board', '');
                        setFilterOptions({ ...filterOptions, ['selected_board']: '' });
                      }} className='text-right right' sx={{ float: "right" }}>
                        Clear
                      </Button>
                    )}
                  </h5>
                  <ul className="list_brand lstscroll">
                    {boards && (
                      <RadioGroup
                        row
                        name="selected_board"
                        value={values.selected_board}
                        onChange={e => {
                          setFieldValue('selected_board', e.target.value);
                          setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
                        }
                        }
                        size="small"
                      >
                        {
                          boards.map((row, index) => (
                            <li key={'board-flt-' + index} style={{ width: "100%" }}>
                              <div className="custome-radio customerad">
                                <FormControlLabel value={row.id} control={<Radio size="small" />} label={row.name} sx={{ height: "25px" }} />
                              </div>
                            </li>
                          ))
                        }
                      </RadioGroup>
                    )}
                  </ul>
                </div>
              ) : ''}

              {(filterOptions.selected_organization === '2' || filterOptions.selected_organization === 2) ? (
                <div className="widget">
                  <h5 className="widget_title">Universities
                    {values.selected_university && (
                      <Button onClick={e => {
                        setFieldValue('selected_university', '');
                        setFilterOptions({ ...filterOptions, ['selected_university']: '' });
                      }} className='text-right right' sx={{ float: "right" }}>
                        Clear
                      </Button>
                    )}
                  </h5>
                  <ul className="list_brand lstscroll">
                    {universities && (
                      <RadioGroup
                        row
                        name="selected_university"
                        value={values.selected_university}
                        onChange={e => {
                          setFieldValue('selected_university', e.target.value);
                          setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
                        }
                        }
                        size="small"
                      >
                        {
                          universities.map((row, index) => (
                            <li key={'board-flt-' + index} style={{ width: "100%" }}>
                              <div className="custome-radio customerad">
                                <FormControlLabel value={row.id} control={<Radio size="small" />} label={row.name} sx={{ height: "25px" }} />
                              </div>
                            </li>
                          ))
                        }
                      </RadioGroup>
                    )}
                  </ul>
                </div>
              ) : ''}

              {(filterOptions.selected_organization === '3' || filterOptions.selected_organization === 3) ? (
                <div className="widget">
                  <h5 className="widget_title">Entrance Exams
                    {values.selected_entrance_exam && (
                      <Button onClick={e => {
                        setFieldValue('selected_entrance_exam', '');
                        setFilterOptions({ ...filterOptions, ['selected_entrance_exam']: '' });
                      }} className='text-right right' sx={{ float: "right" }}>
                        Clear
                      </Button>
                    )}
                  </h5>
                  <ul className="list_brand lstscroll">
                    {entranceExams && (
                      <RadioGroup
                        row
                        name="selected_entrance_exam"
                        value={values.selected_entrance_exam}
                        onChange={e => {
                          setFieldValue('selected_entrance_exam', e.target.value);
                          setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
                        }
                        }
                        size="small"
                      >
                        {
                          entranceExams.map((row, index) => (
                            <li key={'board-flt-' + index} style={{ width: "100%" }}>
                              <div className="custome-radio customerad">
                                <FormControlLabel value={row.id} control={<Radio size="small" />} label={row.exam_name.replace(/(.{25})..+/, "$1…")} title={row.exam_name} sx={{ height: "25px" }} />
                              </div>
                            </li>
                          ))
                        }
                      </RadioGroup>
                    )}
                  </ul>
                </div>
              ) : ''}
              {(filterOptions.selected_organization === '4' || filterOptions.selected_organization === 4) ? (
                <div className="widget">
                  <h5 className="widget_title">Competitive Exams
                    {values.selected_competitive_exam && (
                      <Button onClick={e => {
                        setFieldValue('selected_competitive_exam', '');
                        setFilterOptions({ ...filterOptions, ['selected_competitive_exam']: '' });
                      }} className='text-right right' sx={{ float: "right" }}>
                        Clear
                      </Button>
                    )}
                  </h5>
                  <ul className="list_brand lstscroll">
                    {competitiveExams && (
                      <RadioGroup
                        row
                        name="selected_competitive_exam"
                        value={values.selected_competitive_exam}
                        onChange={e => {
                          setFieldValue('selected_competitive_exam', e.target.value);
                          setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
                        }
                        }
                        size="small"
                      >
                        {
                          competitiveExams.map((row, index) => (
                            <li key={'board-flt-' + index} style={{ width: "100%" }}>
                              <div className="custome-radio customerad">
                                <FormControlLabel value={row.id} control={<Radio size="small" />} label={row.exam_name.replace(/(.{25})..+/, "$1…")} title={row.exam_name} sx={{ height: "25px" }} />
                              </div>
                            </li>
                          ))
                        }
                      </RadioGroup>
                    )}
                  </ul>
                </div>
              ) : ''}

              {subjects.length ? (
                <div className="widget">
                  <h5 className="widget_title">Subjects
                    {values.selected_subjects.length ? (
                      <Button onClick={e => {
                        setUserCategories([]);
                        setFilterOptions({ ...filterOptions, ['selected_subjects']: [] });
                      }} className='text-right right' sx={{ float: "right" }}>Clear
                      </Button>
                    ) : ''}
                  </h5>
                  <ul className="list_brand lstscroll">
                    <FormControl sx={{ mb: 3 }} component="fieldset" variant="standard">
                      <FormGroup row={true}>
                        {subjects ? subjects
                          .map((row, index) => (
                            <li key={'subject-flt-' + index} style={{ width: "100%" }}>
                              <div className="custome-radio customerad">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={handleSubjectCheck(row.id)}
                                      name={`selected_subjects[${row.id}]`}
                                      value={row.id}
                                      onChange={e => {
                                        if (e.target.checked) {
                                          userCategories.push(row.id);
                                          setFilterOptions({ ...filterOptions, ['selected_subjects']: userCategories });
                                        } else {
                                          const updateUserCat = filterOptions.selected_subjects.filter((rowLal) => rowLal !== (row.id));
                                          setUserCategories(updateUserCat);
                                          setFilterOptions({ ...filterOptions, ['selected_subjects']: updateUserCat });
                                        }
                                      }}
                                      size="small"
                                      sx={{ height: "25px" }}
                                    />
                                  }
                                  label={row.name.replace(/(.{30})..+/, "$1…")} title={row.name}
                                />
                              </div>
                            </li>
                          )) : <></>}
                      </FormGroup>
                    </FormControl>
                  </ul>
                </div>
              ) : ''}

              <div className="widget">
                <h5 className="widget_title">Ratings
                  {values.selected_rating && (
                    <Button onClick={e => {
                      setFieldValue('selected_rating', '');
                      setFilterOptions({ ...filterOptions, ['selected_rating']: '' });
                    }} className='text-right right' sx={{ float: "right" }}>Clear
                    </Button>
                  )}
                </h5>
                <ul className="list_brand">
                  <RadioGroup
                    row
                    name="selected_rating"
                    value={values.selected_rating}
                    onChange={e => {
                      setFieldValue('selected_rating', e.target.value);
                      setFilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
                    }
                    }
                    size="small"
                  >
                    <li key={`rating-flt-5`} style={{ width: "100%" }}>
                      <div className="custome-radio customerad">
                        <FormControlLabel value={5} control={<Radio size="small" />}
                          label={<Rating className="rating" value={5} name="size-small" size="small" readOnly />}
                          sx={{ height: "25px" }} />
                      </div>
                    </li>
                    <li key={`rating-flt-4`} style={{ width: "100%" }}>
                      <div className="custome-radio customerad">
                        <FormControlLabel value={4} control={<Radio size="small" />}
                          label={<Rating className="rating" value={4} name="size-small" size="small" readOnly />}
                          sx={{ height: "25px" }} />
                      </div>
                    </li>
                    <li key={`rating-flt-3`} style={{ width: "100%" }}>
                      <div className="custome-radio customerad">
                        <FormControlLabel value={3} control={<Radio size="small" />}
                          label={<Rating className="rating" value={3} name="size-small" size="small" readOnly />}
                          sx={{ height: "25px" }} />
                      </div>
                    </li>
                    <li key={`rating-flt-2`} style={{ width: "100%" }}>
                      <div className="custome-radio customerad">
                        <FormControlLabel value={2} control={<Radio size="small" />}
                          label={<Rating className="rating" value={2} name="size-small" size="small" readOnly />}
                          sx={{ height: "25px" }} />
                      </div>
                    </li>
                    <li key={`rating-flt-1`} style={{ width: "100%" }}>
                      <div className="custome-radio customerad">
                        <FormControlLabel value={1} control={<Radio size="small" />}
                          label={<Rating className="rating" value={1} name="size-small" size="small" readOnly />}
                          sx={{ height: "25px" }} />
                      </div>
                    </li>
                  </RadioGroup>
                </ul>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
export default LeftFilter;
