import React, { useState, useEffect } from 'react';

import axios from 'axios';
import {
  Button,
  Grid, Select, MenuItem, TextField, FormControl,
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const validationSchema = Yup.object().shape({
  organization_type_id: Yup.number()
    .required('Organization Type is required!'),
  board_id: Yup.string()
    .when("organization_type_id", {
      is: 1,
      then: (schema) => schema.required('Select board!'),
      otherwise: (schema) => schema.nullable()
    }),
  class_id: Yup.string()
    .when("organization_type_id", {
      is: 1,
      then: (schema) => schema.required('Select class!'),
      otherwise: (schema) => schema.nullable()
    }),
  university_id: Yup.string()
    .when("organization_type_id", {
      is: 2,
      then: (schema) => schema.required('Select University!'),
      otherwise: (schema) => schema.nullable()
    }),
  college_course_id: Yup.string()
    .when("organization_type_id", {
      is: 2,
      then: (schema) => schema.required('Select Course!'),
      otherwise: (schema) => schema.nullable()
    }),
  entrance_exam_id: Yup.string()
    .when("organization_type_id", {
      is: 3,
      then: (schema) => schema.required('Select Entrance Exam!'),
      otherwise: (schema) => schema.nullable()
    }),
  competitive_exam_id: Yup.string()
    .when("organization_type_id", {
      is: 4,
      then: (schema) => schema.required('Select Competitive Exam!'),
      otherwise: (schema) => schema.nullable()
    }),

  subject_id: Yup.string()
    .required('Subject is required!'),
  topic_title: Yup.string()
    .min(2, 'Topic/Title must be at least 2 characters.')
    .max(255, 'Topic/Title has a maximum limit of 255 characters.')
    .required('Topic/Title is required!'),
  description: Yup.string()
    .min(50, 'Description must be at least 50 characters.')
    .required('Description is required!'),
  number_of_questions: Yup.number()
    .when("type", {
      is: 'practice',
      then: (schema) => schema.required('Number of questions is required!'),
      otherwise: (schema) => schema.nullable()
    }),
  difficulty_level_id: Yup.number()
    .when("type", {
      is: 'practice',
      then: (schema) => schema.required('Difficulty level is required!'),
      otherwise: (schema) => schema.nullable()
    }),
});
const EducationalInformation = (props) => {
  const [organizationTypes, setOrganizationTypes] = useState(false);
  const [boards, setBoards] = useState(false);
  const [schoolClasses, setSchoolClasses] = useState(false);
  const [subjects, setSubjects] = useState(false);
  const [difficultyLevels, setDifficultyLevels] = useState(false);
  const [universities, setUniversities] = useState(false);
  const [years, setYears] = useState(false);
  const [college_courses, setCollegeCourses] = useState(false);
  const [entrance_exams, setEntranceExams] = useState(false);
  const [competitive_exams, setCompetitiveExams] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
    if (props.formData.organization_type_id) {
      fetchSubjectList(props.formData.organization_type_id,
        props.formData.board_id,
        props.formData.class_id,
        props.formData.entrance_exam_id,
        props.formData.competitive_exam_id,
        props.formData.university_id,
        props.formData.college_course_id);
    }

  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-organization-types')
          .then(response => {
            if (response.data.response_code === 200) {
              setOrganizationTypes(response.data.data);
            } else if (response.data.response_code === 401) {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.error(error);
          });

        axios.post('/api/dashboard/get-boards')
          .then(response => {
            if (response.data.response_code === 200) {
              setBoards(response.data.data);
            } else if (response.data.response_code === 401) {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.error(error);
          });

        axios.post('/api/dashboard/get-school-classes')
          .then(response => {
            if (response.data.response_code === 200) {
              setSchoolClasses(response.data.data);
            } else if (response.data.response_code === 401) {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.error(error);
          });

        axios.post('/api/dashboard/get-difficulty-levels')
          .then(response => {
            if (response.data.response_code === 200) {
              setDifficultyLevels(response.data.data);
            } else if (response.data.response_code === 401) {
            } else {
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
        axios.post('/api/dashboard/get-universities')
          .then(response => {
            if (response.data.response_code === 200) {
              setUniversities(response.data.data);
            } else if (response.data.response_code === 401) {
            } else {
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
        axios.post('/api/dashboard/get-collage-courses')
          .then(response => {
            if (response.data.response_code === 200) {
              setCollegeCourses(response.data.data);
            } else if (response.data.response_code === 401) {
            } else {
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });

        axios.post('/api/dashboard/get-entrance-exams')
          .then(response => {
            if (response.data.response_code === 200) {
              setEntranceExams(response.data.data);
            } else if (response.data.response_code === 401) {
            } else {
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
        axios.post('/api/dashboard/get-competitive-exams')
          .then(response => {
            if (response.data.response_code === 200) {
              setCompetitiveExams(response.data.data);
            } else if (response.data.response_code === 401) {
            } else {
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
        axios.post('/api/dashboard/get-years')
          .then(response => {
            if (response.data.response_code === 200) {
              setYears(response.data.data);
            } else if (response.data.response_code === 401) {
            } else {
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Fetching Mapped subject list from server.
   */

  const fetchSubjectList = async (organization_type_id, board_id, class_id, entrance_exam_id, competitive_exam_id, university_id, college_course_id) => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-mapped-subject-list', {
          organization_type_id: organization_type_id,
          board_id: board_id,
          class_id: class_id,
          entrance_exam_id: entrance_exam_id,
          competitive_exam_id: competitive_exam_id,
          university_id: university_id,
          college_course_id: college_course_id
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setSubjects(response.data.data);
            } else if (response.data.response_code === 401) {
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.error(error);
          });
      }
      )
    } catch (e) {
      setLoading(false);
    }
  }

  const handleFormSubmit = (values, errors) => {
    props.next(values);
  }

  const handleSaveAsDraftSubmit = (values) => {
    props.saveAsDraftSubmit(values);
  }

  return (
    <>
      <Formik
        onSubmit={(values, errors) => {
          handleFormSubmit(values, errors);
        }
        }
        initialValues={props.formData}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Grid container style={{ padding: '20px' }} spacing={3}>
              <Grid item sm={6} xs={12}>
                <label htmlFor="organization_type_id" className='form-label'>School/College/University/Institute</label>
                <FormControl fullWidth
                  sx={{ mb: 3 }}
                  size="small"
                >
                  <Select
                    displayEmpty
                    labelId="organization_type_id-label"
                    id="organization_type_id"
                    name="organization_type_id"
                    value={values.organization_type_id}
                    // onChange={handleChange}
                    onChange={(e) => {
                      handleChange(e)
                      var organization_type_id = (e.target.value) ? parseInt(e.target.value) : 0;
                      var board_id = (values.board_id) ? parseInt(values.board_id) : 0;
                      var class_id = (values.class_id) ? parseInt(values.class_id) : 0;
                      var entrance_exam_id = (values.entrance_exam_id) ? parseInt(values.entrance_exam_id) : 0;
                      var competitive_exam_id = (values.competitive_exam_id) ? parseInt(values.competitive_exam_id) : 0;
                      var university_id = (values.university_id) ? parseInt(values.university_id) : 0;
                      var college_course_id = (values.college_course_id) ? parseInt(values.college_course_id) : 0;
                      fetchSubjectList(organization_type_id, board_id, class_id, entrance_exam_id, competitive_exam_id, university_id, college_course_id);
                    }}
                    variant="outlined"
                    onBlur={handleBlur}
                    error={Boolean(errors.organization_type_id && touched.organization_type_id)}
                  >
                    <MenuItem value='' key="sg">Select</MenuItem>
                    {organizationTypes && organizationTypes.map((row) =>
                      <MenuItem value={row.id} key={`sg_` + row.id}>{row.name}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {values.organization_type_id && (values.organization_type_id === 1) && (
                <>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="board_id" className='form-label'>Select Board</label>
                    <FormControl fullWidth
                      sx={{ mb: 3 }}
                      size="small"
                    >
                      <Select
                        displayEmpty
                        labelId="board_id"
                        id="board_id"
                        name="board_id"
                        value={values.board_id}
                        // onChange={handleChange}
                        onChange={(e) => {
                          handleChange(e)
                          var board_id = (e.target.value) ? parseInt(e.target.value) : 0;
                          var organization_type_id = (values.organization_type_id) ? parseInt(values.organization_type_id) : 0;
                          var class_id = (values.class_id) ? parseInt(values.class_id) : 0;
                          var entrance_exam_id = (values.entrance_exam_id) ? parseInt(values.entrance_exam_id) : 0;
                          var competitive_exam_id = (values.competitive_exam_id) ? parseInt(values.competitive_exam_id) : 0;
                          var university_id = (values.university_id) ? parseInt(values.university_id) : 0;
                          var college_course_id = (values.college_course_id) ? parseInt(values.college_course_id) : 0;
                          fetchSubjectList(organization_type_id, board_id, class_id, entrance_exam_id, competitive_exam_id, university_id, college_course_id);
                        }}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(errors.board_id && touched.board_id)}
                      >
                        <MenuItem value='' key="emp_bor">Select</MenuItem>
                        {boards && boards.map((row) =>
                          <MenuItem value={row.id} key={`bor_` + row.id}>{row.name}</MenuItem>
                        )}
                      </Select>
                      {(errors.board_id)
                        && (touched.board_id)
                        && <p
                          className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                        >
                          {`${errors.board_id}`}
                        </p>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="class_id" className='form-label'>Select Class</label>
                    <FormControl fullWidth
                      sx={{ mb: 3 }}
                      size="small"
                    >
                      <Select
                        displayEmpty
                        labelId="board_id"
                        id="class_id"
                        name="class_id"
                        value={values.class_id}
                        // onChange={handleChange}
                        onChange={(e) => {
                          handleChange(e)
                          var class_id = (e.target.value) ? parseInt(e.target.value) : 0;
                          var organization_type_id = (values.organization_type_id) ? parseInt(values.organization_type_id) : 0;
                          var board_id = (values.board_id) ? parseInt(values.board_id) : 0;
                          var entrance_exam_id = (values.entrance_exam_id) ? parseInt(values.entrance_exam_id) : 0;
                          var competitive_exam_id = (values.competitive_exam_id) ? parseInt(values.competitive_exam_id) : 0;
                          var university_id = (values.university_id) ? parseInt(values.university_id) : 0;
                          var college_course_id = (values.college_course_id) ? parseInt(values.college_course_id) : 0;
                          fetchSubjectList(organization_type_id, board_id, class_id, entrance_exam_id, competitive_exam_id, university_id, college_course_id);
                        }}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(errors.class_id && touched.class_id)}
                      >
                        <MenuItem value='' key="emp_class">Select</MenuItem>
                        {schoolClasses && schoolClasses.map((row) =>
                          <MenuItem value={row.id} key={`class_` + row.id}>{row.class_name}</MenuItem>
                        )}
                      </Select>
                      {(errors.class_id)
                        && (touched.class_id)
                        && <p
                          className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                        >
                          {`${errors.class_id}`}
                        </p>
                      }
                    </FormControl>
                  </Grid>
                </>
              )}
              {values.organization_type_id && (values.organization_type_id === 2) && (
                <>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="university_id" className='form-label'>Select University</label>
                    <FormControl fullWidth
                      sx={{ mb: 3 }}
                      size="small"
                    >
                      <Select
                        displayEmpty
                        labelId="university_id"
                        id="university_id"
                        name="university_id"
                        value={values.university_id}
                        // onChange={handleChange}
                        onChange={(e) => {
                          handleChange(e)
                          var university_id = (e.target.value) ? parseInt(e.target.value) : 0;
                          var organization_type_id = (values.organization_type_id) ? parseInt(values.organization_type_id) : 0;
                          var board_id = (values.board_id) ? parseInt(values.board_id) : 0;
                          var class_id = (values.class_id) ? parseInt(values.class_id) : 0;
                          var entrance_exam_id = (values.entrance_exam_id) ? parseInt(values.entrance_exam_id) : 0;
                          var competitive_exam_id = (values.competitive_exam_id) ? parseInt(values.competitive_exam_id) : 0;
                          var college_course_id = (values.college_course_id) ? parseInt(values.college_course_id) : 0;
                          fetchSubjectList(organization_type_id, board_id, class_id, entrance_exam_id, competitive_exam_id, university_id, college_course_id);
                        }}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(errors.university_id && touched.university_id)}
                      >
                        <MenuItem value='' key="emp_uni">Select</MenuItem>
                        {universities && universities.map((row) =>
                          <MenuItem value={row.id} key={`uni_` + row.id}>{row.name}</MenuItem>
                        )}
                      </Select>
                      {(errors.university_id)
                        && (touched.university_id)
                        && <p
                          className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                        >
                          {`${errors.university_id}`}
                        </p>
                      }
                    </FormControl>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <label htmlFor="college_course_id" className='form-label'>Select Course</label>
                    <FormControl fullWidth
                      sx={{ mb: 3 }}
                      size="small"
                    >
                      <Select
                        displayEmpty
                        labelId="college_course_id"
                        id="college_course_id"
                        name="college_course_id"
                        value={values.college_course_id}
                        onChange={(e) => {
                          handleChange(e)
                          var college_course_id = (e.target.value) ? parseInt(e.target.value) : 0;
                          var organization_type_id = (values.organization_type_id) ? parseInt(values.organization_type_id) : 0;
                          var board_id = (values.board_id) ? parseInt(values.board_id) : 0;
                          var class_id = (values.class_id) ? parseInt(values.class_id) : 0;
                          var entrance_exam_id = (values.entrance_exam_id) ? parseInt(values.entrance_exam_id) : 0;
                          var competitive_exam_id = (values.competitive_exam_id) ? parseInt(values.competitive_exam_id) : 0;
                          var university_id = (values.university_id) ? parseInt(values.university_id) : 0;
                          fetchSubjectList(organization_type_id, board_id, class_id, entrance_exam_id, competitive_exam_id, university_id, college_course_id);
                        }}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(errors.college_course_id && touched.college_course_id)}
                      >
                        <MenuItem value='' key="emp_cor">Select</MenuItem>
                        {college_courses && college_courses.map((row) =>
                          <MenuItem value={row.id} key={`cor_` + row.id}>{row.course_name}</MenuItem>
                        )}
                      </Select>
                      {(errors.college_course_id)
                        && (touched.college_course_id)
                        && <p
                          className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                        >
                          {`${errors.college_course_id}`}
                        </p>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} xs={12} lg={6}>
                    <label htmlFor="year" className='form-label'>Year</label>
                    <FormControl fullWidth
                      sx={{ mb: 3 }}
                      size="small"
                    >
                      <Select
                        displayEmpty
                        labelId="year"
                        id="year"
                        name="year"
                        value={values.year}
                        onChange={handleChange}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(errors.year && touched.year)}
                      >
                        <MenuItem value='' key="emp_yer">Select</MenuItem>
                        {years && years.map((row) =>
                          <MenuItem value={row.name} key={`yer_` + row.name}>{row.name}</MenuItem>
                        )}
                      </Select>
                      {(errors.year)
                        && (touched.year)
                        && <p
                          className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                        >
                          {`${errors.year}`}
                        </p>
                      }
                    </FormControl>
                  </Grid>
                </>
              )}
              {values.organization_type_id && (values.organization_type_id === 3) && (
                <>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="entrance_exam_id" className='form-label'>Select Entrance Exam</label>
                    <FormControl fullWidth
                      sx={{ mb: 3 }}
                      size="small"
                    >
                      <Select
                        displayEmpty
                        labelId="entrance_exam_id"
                        id="entrance_exam_id"
                        name="entrance_exam_id"
                        value={values.entrance_exam_id}
                        // onChange={handleChange}
                        onChange={(e) => {
                          handleChange(e)
                          var entrance_exam_id = (e.target.value) ? parseInt(e.target.value) : 0;
                          var organization_type_id = (values.organization_type_id) ? parseInt(values.organization_type_id) : 0;
                          var board_id = (values.board_id) ? parseInt(values.board_id) : 0;
                          var class_id = (values.class_id) ? parseInt(values.class_id) : 0;
                          var competitive_exam_id = (values.competitive_exam_id) ? parseInt(values.competitive_exam_id) : 0;
                          var university_id = (values.university_id) ? parseInt(values.university_id) : 0;
                          var college_course_id = (values.college_course_id) ? parseInt(values.college_course_id) : 0;
                          fetchSubjectList(organization_type_id, board_id, class_id, entrance_exam_id, competitive_exam_id, university_id, college_course_id);
                        }}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(errors.entrance_exam_id && touched.entrance_exam_id)}
                      >
                        <MenuItem value='' key="emp_ent">Select</MenuItem>
                        {entrance_exams && entrance_exams.map((row) =>
                          <MenuItem value={row.id} key={`ent_` + row.id}>{row.exam_name}</MenuItem>
                        )}
                      </Select>
                      {(errors.entrance_exam_id)
                        && (touched.entrance_exam_id)
                        && <p
                          className='Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                        >
                          {`${errors.entrance_exam_id}`}
                        </p>
                      }
                    </FormControl>
                  </Grid>
                </>
              )}
              {values.organization_type_id && (values.organization_type_id === 4) && (
                <>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="competitive_exam_id" className='form-label'>Select Competitive Exam</label>
                    <FormControl fullWidth
                      sx={{ mb: 3 }}
                      size="small"
                    >
                      <Select
                        displayEmpty
                        labelId="competitive_exam_id"
                        id="competitive_exam_id"
                        name="competitive_exam_id"
                        value={values.competitive_exam_id}
                        // onChange={handleChange}
                        onChange={(e) => {
                          handleChange(e)
                          var competitive_exam_id = (e.target.value) ? parseInt(e.target.value) : 0;
                          var organization_type_id = (values.organization_type_id) ? parseInt(values.organization_type_id) : 0;
                          var board_id = (values.board_id) ? parseInt(values.board_id) : 0;
                          var class_id = (values.class_id) ? parseInt(values.class_id) : 0;
                          var entrance_exam_id = (values.entrance_exam_id) ? parseInt(values.entrance_exam_id) : 0;
                          var university_id = (values.university_id) ? parseInt(values.university_id) : 0;
                          var college_course_id = (values.college_course_id) ? parseInt(values.college_course_id) : 0;
                          fetchSubjectList(organization_type_id, board_id, class_id, entrance_exam_id, competitive_exam_id, university_id, college_course_id);
                        }}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(errors.competitive_exam_id && touched.competitive_exam_id)}
                      >
                        <MenuItem value='' key="emp_cmp">Select</MenuItem>
                        {competitive_exams && competitive_exams.map((row) =>
                          <MenuItem value={row.id} key={`cmp_` + row.id}>{row.exam_name}</MenuItem>
                        )}
                      </Select>
                      {(errors.competitive_exam_id)
                        && (touched.competitive_exam_id)
                        && <p
                          className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                        >
                          {`${errors.competitive_exam_id}`}
                        </p>
                      }
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item sm={6} xs={12}>
                <label htmlFor="subject_id" className='form-label'>Select Subject</label>
                <FormControl fullWidth
                  sx={{ mb: 3 }}
                  size="small"
                >
                  <Select
                    displayEmpty
                    labelId="subject_id"
                    id="subject_id"
                    name="subject_id"
                    value={values.subject_id}
                    onChange={handleChange}
                    variant="outlined"
                    onBlur={handleBlur}
                    error={Boolean(errors.subject_id && touched.subject_id)}
                  >
                    <MenuItem value='' key="emp_sub">Select</MenuItem>
                    {subjects && subjects.map((row) =>
                      <MenuItem value={row.id} key={`select_sub_${row.id}`}>{row.subject_name}</MenuItem>
                    )}
                  </Select>
                  {(errors.subject_id)
                    && (touched.subject_id)
                    && <p
                      className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                    >
                      {`${errors.subject_id}`}
                    </p>
                  }
                </FormControl>
              </Grid>

              <Grid item sm={6} xs={12} lg={12}>
                <label htmlFor="first_name" className='form-label'>Topic/Title</label>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  name="topic_title"
                  variant="outlined"
                  onBlur={handleBlur}
                  value={values.topic_title}
                  onChange={handleChange}
                  helperText={touched.topic_title && errors.topic_title}
                  error={Boolean(errors.topic_title && touched.topic_title)}
                  sx={{ mb: 3 }}
                />
              </Grid>

              <Grid item sm={6} xs={12} lg={12}>
                <label htmlFor="description" className='form-label'>Description</label>
                <TextField
                  fullWidth
                  multiline={true}
                  minRows={3}
                  size="small"
                  type="text"
                  name="description"
                  variant="outlined"
                  onBlur={handleBlur}
                  value={values.description}
                  onChange={handleChange}
                  helperText={touched.description && errors.description}
                  error={Boolean(errors.description && touched.description)}
                  sx={{ mb: 3 }}
                />
              </Grid>
              <Grid item sm={6} xs={12} lg={12}>
                <label htmlFor="what_you_will_learn_keywords" className='form-label'>What you will learn keywords</label>
                <TextField
                  fullWidth
                  multiline={true}
                  minRows={3}
                  size="small"
                  type="text"
                  name="what_you_will_learn_keywords"
                  variant="outlined"
                  onBlur={handleBlur}
                  placeholder='Like Mathematics, Complex Problem Solving, Critical Thinking, Reading Comprehension'
                  value={values.what_you_will_learn_keywords}
                  onChange={handleChange}
                  helperText={touched.what_you_will_learn_keywords && errors.what_you_will_learn_keywords}
                  error={Boolean(errors.what_you_will_learn_keywords && touched.what_you_will_learn_keywords)}
                  sx={{ mb: 3 }}
                />
              </Grid>
              {values.type && (values.type === 'practice') && (
                <>
                  <Grid item sm={6} xs={12} lg={4}>
                    <label htmlFor="number_of_questions" className='form-label'>No. of Questions</label>
                    <TextField
                      fullWidth
                      size="small"
                      type="number"
                      name="number_of_questions"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.number_of_questions}
                      onChange={handleChange}
                      helperText={touched.number_of_questions && errors.number_of_questions}
                      error={Boolean(errors.number_of_questions && touched.number_of_questions)}
                      sx={{ mb: 3 }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} lg={4}>
                    <label htmlFor="difficulty_level_id" className='form-label'>Difficulty Level</label>
                    <FormControl fullWidth
                      sx={{ mb: 3 }}
                      size="small"
                    >
                      <Select
                        displayEmpty
                        labelId="difficulty-level-label"
                        id="difficulty_level_id"
                        name="difficulty_level_id"
                        value={values.difficulty_level_id}
                        onChange={handleChange}
                        variant="outlined"
                        onBlur={handleBlur}
                        error={Boolean(errors.difficulty_level_id && touched.difficulty_level_id)}
                      >
                        <MenuItem value="" key="sg">Select</MenuItem>
                        {difficultyLevels && difficultyLevels.map((row) =>
                          <MenuItem value={row.id} key={row.id}>{row.level_name}</MenuItem>
                        )}
                      </Select>
                      {(errors.difficulty_level_id)
                        && (touched.difficulty_level_id)
                        && <p
                          className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                        >
                          {`${errors.difficulty_level_id}`}
                        </p>
                      }
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container style={{ padding: '10px 20px 0px' }} spacing={4}>
              <Grid item sm={4} xs={12} lg={6}>
              </Grid>
              <Grid item sm={4} xs={12} lg={3}>
                <Button
                  fullWidth
                  disabled={loading}
                  variant="contained"
                  type='button'
                  sx={{ mb: 2, mt: 1 }}
                  onClick={() => handleSaveAsDraftSubmit(values)}
                >
                  Save as Draft
                </Button>
              </Grid>
              <Grid item sm={4} xs={12} lg={3}>
                <Button
                  fullWidth
                  disabled={loading}
                  variant="contained"
                  type='submit'
                  sx={{ mb: 2, mt: 1 }}
                >
                  Next <NavigateNextIcon />
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>

  );
}

export default EducationalInformation;
