import React, { useEffect, useState, useRef } from 'react';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';

import { useDispatch } from 'react-redux';

function SideNav({ openSideNav, setOpenSideNav }) {
  const ref = useRef()
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const currentPath = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (window.innerWidth <= 768 && openSideNav) {
      setOpenSideNav(false);
    } else {
      setOpenSideNav(true);
    }
  }, []);


  useEffect(() => {
    const checkIfClickedOutside = e => {
      // && !ref.current.contains(e.target)
      if (openSideNav && ref.current) {
        if (window.innerWidth <= 768) {
          setOpenSideNav(false)
        }
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    // return () => {
    //   // Cleanup the event listener
    //   document.removeEventListener("mousedown", checkIfClickedOutside)
    // }
  }, [openSideNav])

  return (

    <>
      <aside id="sidebar" className="sidebar" style={{ left: `${openSideNav ? '0' : '-300px'}` }}>
        <ul ref={ref} className="sidebar-nav" id="sidebar-nav">
          {(user.auth_type === 2 || user.auth_type === "2") ? (
            <>
              {/* Teacher Menu */}
              <li className="nav-item dashboard">
                <Link to="/dashboard" className={(currentPath == "/dashboard") ? "nav-link" : "nav-link collapsed"} >
                  <i className="bi bi-speedometer2"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="nav-item practice-pests">
                <Link to="/dashboard/practice/practice-tests" className={(currentPath.includes("/practice/")) ? "nav-link" : "nav-link collapsed"} >
                  <i className="bi bi-grid"></i>
                  <span>Practice Tests</span>
                </Link>
              </li>
              <li className="nav-item learning-material">
                <Link to="/dashboard/learning/learning-materials" className={(currentPath.includes("/learning/")) ? "nav-link" : "nav-link collapsed"} >
                  <i className="bi bi-clipboard-plus"></i>
                  <span>Learning Materials</span>
                </Link>
              </li>


              <li className="nav-item order-history">
                <Link to="/dashboard/order/order-history" className={(currentPath.includes("/order/")) ? "nav-link" : "nav-link collapsed"} >
                  <i className="bi bi-arrow-clockwise"></i>
                  <span>Order History </span>
                </Link>
              </li>

              <li className="nav-item current-sale">
                <Link to="/dashboard/earning/current-sale" className={(currentPath.includes("/current-sale")) ? "nav-link" : "nav-link collapsed"} >
                  <i className="bi bi-basket-fill"></i>
                  <span>Current Sale</span>
                </Link>
              </li>
              <li className="nav-item withdrawable-sale">
                <Link to="/dashboard/earning/withdrawable-sale" className={(currentPath.includes("/withdrawable-sale")) ? "nav-link" : "nav-link collapsed"} >
                  <i className="bi bi-cash-stack"></i>
                  <span>Withdrawable Sale</span>
                </Link>
              </li>
              <li className="nav-item withdrawable-requested">
                <Link to="/dashboard/earning/withdrawable-requested" className={(currentPath.includes("/withdrawable-requested")) ? "nav-link" : "nav-link collapsed"} >
                  <i className="bi bi-cash-coin"></i>
                  <span>Withdrawable Requested</span>
                </Link>
              </li>
              <li className="nav-item total-sale">
                <Link to="/dashboard/earning/total-sale" className={(currentPath.includes("/total-sale")) ? "nav-link" : "nav-link collapsed"} >
                  <i className="bi bi-bag-check-fill"></i>
                  <span>Total Sale (Released)</span>
                </Link>
              </li>
              <li className="nav-item profile-settings">
                <Link to="/dashboard/profile/profile-settings" className={(currentPath.includes("/profile/")) ? "nav-link" : "nav-link collapsed"} >
                  <i className="bi bi-gear"></i>
                  <span>Profile Settings </span>
                </Link>
              </li>
              {/* <Link className={(currentPath.includes("/support")) ? "nav-link support" : "nav-link collapsed support"} to="/dashboard/supports">
                <i className="bi bi-question-diamond"></i>
                <span>Support</span>
              </Link> */}
              <Link className={(currentPath.includes("/refunds-requested")) ? "nav-link support" : "nav-link collapsed support"} to="/dashboard/refunds-requested">
                <i className="bi bi-question-diamond"></i>
                <span>Refunds Requested</span>
              </Link>
              {/* <li className="nav-item">
                <Link to="/dashboard/help/videos" className={(currentPath.includes("/help/")) ? "nav-link" : "nav-link collapsed"} >
                  <i className="bi bi-play-circle"></i>
                  <span>Help Videos</span>
                </Link>
              </li> */}
            </>
          ) : (
            <>
              <li className="nav-item">
                <Link to="/dashboard" className={(currentPath == "/dashboard") ? "nav-link" : "nav-link collapsed"} >
                  <i className="bi bi-speedometer2"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="nav-item">
                <li className="nav-item">
                  <Link to="/dashboard/order/order-history" className={(currentPath.includes("/order/")) ? "nav-link" : "nav-link collapsed"} >
                    <i className="bi bi-arrow-clockwise"></i>
                    <span>Order History </span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/dashboard/wishlists" className={(currentPath.includes("/wishlists")) ? "nav-link" : "nav-link collapsed"} >
                    <i className="bi bi-heart"></i>
                    <span>My Wishlist</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/dashboard/profile/profile-settings" className={(currentPath.includes("/profile/")) ? "nav-link" : "nav-link collapsed"} >
                    <i className="bi bi-gear"></i>
                    <span>Profile Settings </span>
                  </Link>
                </li>
                <Link className={(currentPath.includes("/refunds")) ? "nav-link" : "nav-link collapsed"} to="/dashboard/refunds">
                  <i className="bi bi-question-diamond"></i>
                  <span>Refunds</span>
                </Link>
                {/* <Link className="nav-link collapsed" to="/" onClick={logout}>
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Logout</span>
                </Link> */}
              </li>
            </>
          )}
        </ul>
      </aside >
    </>
  );
}

export default SideNav;
