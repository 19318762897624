import React, { useEffect, useState } from 'react';
import SEO from './../../layouts/SEO';
import { useSearchFilter } from '../../../contexts/SearchFilterContext';

function SearchPageSEO() {
  const { filterOptions, setFilterOptions, fetchFilteredProduct } = useSearchFilter();
  return (
    <>

      {(() => {
        switch (filterOptions.selected_entrance_exam) {
          // case ((filterOptions.selected_organization === 3 && filterOptions.selected_entrance_exam === 20) || (filterOptions.selected_organization === '3' && filterOptions.selected_entrance_exam === '20')):
          case (1 || '1'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for GMAT"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for GMAT in Refinable Cost."
              keywords="Download LEARNING MATERIAL for GMAT, Download PRACTICE TESTS for GMAT"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (2 || '2'):
            return <SEO
              title="LEARNING MATERIAL and PRACTICE TESTS for CMAT"
              description="We are one of the best Teachers LEARNING MATERIAL and PRACTICE TESTS for CMAT
              Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CMAT, Download PRACTICE TESTS for CMAT"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (3 || '3'):
            return <SEO
              title="Download MATERIAL and PRACTICE TESTS for SITEEE"
              description="Find one of the best Complete Study Material With Experience and professional Teachers and PRACTICE TESTS for SITEEE
              in Refinable Cost."
              keywords="Download LEARNING MATERIAL for XAT, Download PRACTICE TESTS for XAT"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (4 || '4'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for MAT"
              description="If you are looking best Cheapest Study Material Experts Teachers and PRACTICE TESTS for MAT in Refinable Cost."
              keywords="Download LEARNING MATERIAL for MAT, Download PRACTICE TESTS for MAT"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (5 || '5'):
            return <SEO
              title="Complete Study MATERIAL and PRACTICE TESTS for ATMA"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for ATMA"
              keywords="Download LEARNING MATERIAL for ATMA, Download PRACTICE TESTS for ATMA"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (6 || '6'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for NAMT"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for NAMT in Refinable Cost."
              keywords="Download LEARNING MATERIAL for NAMT, Download PRACTICE TESTS for NAMT"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (7 || '7'):
            return <SEO
              title="Easily Download Study  MATERIAL and PRACTICE TESTS for SNAP"
              description="We Offers Affordable and Best Complete Study Material Experts Teachers and PRACTICE TESTS for SNAP in Best Price."
              keywords="Download LEARNING MATERIAL for SNAP, Download PRACTICE TESTS for SNAP"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (8 || '8'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for IIFT"
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for IIFT in Refinable Cost."
              keywords="Download LEARNING MATERIAL for IIFT, Download PRACTICE TESTS for IIFT"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (9 || '9'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for IRMA"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for IRMA in Refinable Cost."
              keywords="Download LEARNING MATERIAL for IRMA, Download PRACTICE TESTS for IRMA"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (10 || '10'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for MICAT"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for MICAT in Refinable Cost."
              keywords="Download LEARNING MATERIAL for MICAT, Download PRACTICE TESTS for MICAT"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (11 || '11'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for JEE Foundation"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for TISSNET in Cheapest Price."
              keywords="Download LEARNING MATERIAL for TISSNET, Download PRACTICE TESTS for TISSNET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (12 || '12'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for JEE Main"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for JEE Main in Refinable Cost."
              keywords="Download LEARNING MATERIAL for JEE Main, Download PRACTICE TESTS for JEE Main"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (13 || '13'):
            return <SEO
              title="Get Learning Compete MATERIAL and PRACTICE TESTS for BITSAT"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for BITSAT Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for BITSAT, Download PRACTICE TESTS for BITSAT"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (14 || '14'):
            return <SEO
              title="Get Learning Compete MATERIAL and PRACTICE TESTS for MET"
              description="We have Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for MET in Refinable Cost."
              keywords="Download LEARNING MATERIAL for MET, Download PRACTICE TESTS for MET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (15 || '15'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for VITEEE"
              description="We are on of the best Study Material Experts Teachers and PRACTICE TESTS Platform for VITEEE in Best Price."
              keywords="Download LEARNING MATERIAL for VITEEE, Download PRACTICE TESTS for VITEEE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (16 || '16'):
            return <SEO
              title="Find Best MATERIAL and PRACTICE TESTS for SRMJEEE"
              description="Download Study Material Experts Teachers and PRACTICE TESTS for SRMJEEE from India’s No. 1 Website in lowest price."
              keywords="Download LEARNING MATERIAL for SRMJEEE, Download PRACTICE TESTS for SRMJEEE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (17 || '17'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for COMEDK UGET"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for COMEDK UGET
              Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for COMEDK UGET, Download PRACTICE TESTS for COMEDK UGET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (18 || '18'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for KIITEE"
              description="We are provides Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for KIITEE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for KIITEE, Download PRACTICE TESTS for KIITEE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (19 || '19'):
            return <SEO
              title="Best Study MATERIAL and PRACTICE TESTS for SITEEE Class"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for SITEEE Class in Very Low Cost."
              keywords="Download LEARNING MATERIAL for SITEEE Class, Download PRACTICE TESTS for SITEEE Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (20 || '20'):
            return <SEO
              title="Get Complete Study MATERIAL and PRACTICE TESTS Physics, Chemistry and Maths for AMUEEE"
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for AMUEEE Entrance Exam in Refinable Cost."
              keywords="Download LEARNING MATERIAL for AMUEEE, Download PRACTICE TESTS for AMUEEE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (21 || '21'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for IGCSE"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for IGCSE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for IGCSE, Download PRACTICE TESTS for IGCSE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (22 || '22'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for COMEDK UGET"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for GATE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for GATE, Download PRACTICE TESTS for GATE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (23 || '23'):
            return <SEO
              title="Study MATERIAL and PRACTICE TESTS for NEET in Lowest Cost"
              description="We are providing Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for NEET in Refinable Cost."
              keywords="Download LEARNING MATERIAL for NEET Foundation, Download PRACTICE TESTS for NEET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (24 || '24'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for JEE Foundation"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for JEE Foundation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for JEE Foundation, Download PRACTICE TESTS for JEE Foundation"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
        }

      })()}

      {(() => {
        switch (filterOptions.selected_competitive_exam) {
          // case ((filterOptions.selected_organization === 3 && filterOptions.selected_entrance_exam === 20) || (filterOptions.selected_organization === '3' && filterOptions.selected_entrance_exam === '20')):
          case (1 || '1'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CSE (Civil Srvices Examinitaion)"
              description="We are provides Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for CSE (Civil Srvices Examinitaion) in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CSE (Civil Srvices Examinitaion), Download PRACTICE TESTS for CSE (Civil Srvices Examinitaion)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (2 || '2'):
            return <SEO
              title="LEARNING MATERIAL and PRACTICE TESTS for CMAT"
              description="We are one of the best Teachers LEARNING MATERIAL and PRACTICE TESTS for CMAT
              Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CMAT, Download PRACTICE TESTS for CMAT"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (3 || '3'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for ISSE"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for ISSE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for ISSE, Download PRACTICE TESTS for ISSE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (4 || '4'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CDSE"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for CDSE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CDSE , Download PRACTICE TESTS for CDSE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (5 || '5'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for NDA"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for NDA in Refinable Cost."
              keywords="Download LEARNING MATERIAL for NDA, Download PRACTICE TESTS for NDA"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (6 || '6'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CMSE (Combined Medical Services)"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for CMSE (Combined Medical Services) in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CMSE (Combined Medical Services), Download PRACTICE TESTS for CMSE (Combined Medical Services)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (7 || '7'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for ISE/ESE"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for ISE/ESE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for ISE/ESE, Download PRACTICE TESTS for ISE/ESE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (8 || '8'):
            return <SEO
              title="Study MATERIAL and PRACTICE TESTS for SSC in Lowest Cost"
              description="We are providing Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for SSC in Refinable Cost."
              keywords="Download LEARNING MATERIAL for SSC, Download PRACTICE TESTS for SSC"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (9 || '9'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for IRMA"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for IRMA in Refinable Cost."
              keywords="Download LEARNING MATERIAL for IRMA, Download PRACTICE TESTS for IRMA"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (10 || '10'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for NABARD"
              description="We are provides Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for NABARD in Refinable Cost."
              keywords="Download LEARNING MATERIAL for NABARD, Download PRACTICE TESTS for NABARD"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (11 || '11'):
            return <SEO
              title="LEARNING MATERIAL and PRACTICE TESTS for DOT"
              description="We are one of the best Teachers LEARNING MATERIAL and PRACTICE TESTS for DOT Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for DOT, Download PRACTICE TESTS for DOT"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (12 || '12'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for GATE"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for GATE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for GATE, Download PRACTICE TESTS for GATE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (13 || '13'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CEED"
              description="We are provides Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for CEED in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CEED, Download PRACTICE TESTS for CEED"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (14 || '14'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for GAT (NIFT)"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for GAT (NIFT) in Refinable Cost."
              keywords="Download LEARNING MATERIAL for GAT (NIFT), Download PRACTICE TESTS for GAT (NIFT)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (15 || '15'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for XAT"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for XAT
              in Refinable Cost."
              keywords="Download LEARNING MATERIAL for XAT, Download PRACTICE TESTS for XAT"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (16 || '16'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for IELTS IESC"
              description="We are on of the best Study Material Experts Teachers and PRACTICE TESTS Platform for IELTS IESC in Best Price."
              keywords="Download LEARNING MATERIAL for IELTS IESC, Download PRACTICE TESTS for IELTS IESC"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (17 || '17'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CDSE "
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for NA (Naval Academy Examinition)"
              keywords="Download LEARNING MATERIAL for CDSE , Download PRACTICE TESTS for NA (Naval Academy Examinition)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (18 || '18'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for JEE Foundation"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for RBI Garde B in Cheapest Price."
              keywords="Download LEARNING MATERIAL for RBI Garde B, Download PRACTICE TESTS for RBI Garde B"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (19 || '19'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for Banking - Regulatory Exam"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for Banking - Regulatory Exam in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Banking - Regulatory Exam, Download PRACTICE TESTS for Banking - Regulatory Exam"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (20 || '20'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for ESIC UDC"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for ESIC UDC in Refinable Cost."
              keywords="Download LEARNING MATERIAL for ESIC UDC, Download PRACTICE TESTS for ESIC UDC"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (21 || '21'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for JEE Foundation"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for IDBI ESECUTIVE in Cheapest Price."
              keywords="Download LEARNING MATERIAL for IDBI ESECUTIVE, Download PRACTICE TESTS for IDBI ESECUTIVE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (22 || '22'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for UPSC EPFO"
              description="We have Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for UPSC EPFO
              in Refinable Cost."
              keywords="Download LEARNING MATERIAL for UPSC EPFO, Download PRACTICE TESTS for UPSC EPFO"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (23 || '23'):
            return <SEO
              title="Study MATERIAL and PRACTICE TESTS for IBPS CLERK in Lowest Cost"
              description="We are providing Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for IBPS CLERK in Refinable Cost."
              keywords="Download LEARNING MATERIAL for IBPS CLERK, Download PRACTICE TESTS for IBPS CLERK"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (24 || '24'):
            return <SEO
              title="Best Study MATERIAL and PRACTICE TESTS for IBPS PO Class"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for IBPS PO Class in Very Low Cost."
              keywords="Download LEARNING MATERIAL for IBPS PO Class, Download PRACTICE TESTS for IBPS PO Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (25 || '25'):
            return <SEO
              title="Find Best MATERIAL and PRACTICE TESTS for IBPS SO"
              description="Download Study Material Experts Teachers and PRACTICE TESTS for IBPS SO from India’s No. 1 Website in lowest price."
              keywords="Download LEARNING MATERIAL for IBPS SO, Download PRACTICE TESTS for IBPS SO"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (26 || '26'):
            return <SEO
              title="Easily Download Study  MATERIAL and PRACTICE TESTS for IBPS RRB"
              description="We Offers Affordable and Best Complete Study Material Experts Teachers and PRACTICE TESTS for IBPS RRB in Best Price."
              keywords="Download LEARNING MATERIAL for IBPS RRB, Download PRACTICE TESTS for IBPS RRB"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (27 || '27'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for SBI PO"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for SBI PO in Refinable Cost."
              keywords="Download LEARNING MATERIAL for SBI PO, Download PRACTICE TESTS for SBI PO"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (28 || '28'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for SBI Clerk"
              description="We have Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for SBI Clerk in Refinable Cost."
              keywords="Download LEARNING MATERIAL for SBI Clerk, Download PRACTICE TESTS for SBI Clerk"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (29 || '29'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for NABARD Grade A"
              description="If you are looking best Cheapest Study Material Experts Teachers and PRACTICE TESTS for NABARD Grade A in Refinable Cost."
              keywords="Download LEARNING MATERIAL for NABARD Grade A, Download PRACTICE TESTS for NABARD Grade A"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (30 || '30'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for FCI AGM"
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for FCI AGM in Refinable Cost."
              keywords="Download LEARNING MATERIAL for FCI AGM, Download PRACTICE TESTS for FCI AGM"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (31 || '31'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for NIACL AO"
              description="We are provides Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for NIACL AO in Refinable Cost."
              keywords="Download LEARNING MATERIAL for NIACL AO, Download PRACTICE TESTS for NIACL AO"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (32 || '32'):
            return <SEO
              title="Get Complete Study MATERIAL and PRACTICE TESTS Physics, Chemistry and Maths for OSCB"
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for OSCB Entrance Exam in Refinable Cost."
              keywords="Download LEARNING MATERIAL for OSCB, Download PRACTICE TESTS for OSCB "
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (34 || '34'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for SEBI GRADE A"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for SEBI GRADE A in Refinable Cost."
              keywords="Download LEARNING MATERIAL for SEBI GRADE A, Download PRACTICE TESTS for SEBI GRADE A"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (35 || '35'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for RBI Office Attendat"
              description="We are on of the best Study Material Experts Teachers and PRACTICE TESTS Platform for RBI Office Attendat in Best Price."
              keywords="Download LEARNING MATERIAL for RBI Office Attendat, Download PRACTICE TESTS for RBI Office Attendat"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (36 || '36'):
            return <SEO
              title="Best Study MATERIAL and PRACTICE TESTS for SSC CGL"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for SSC CGL in Very Low Cost."
              keywords="Download LEARNING MATERIAL for SSC CGL Class, Download PRACTICE TESTS for SSC CGL"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (37 || '37'):
            return <SEO
              title="Easily Download Study  MATERIAL and PRACTICE TESTS for SSC CHSL"
              description="We Offers Affordable and Best Complete Study Material Experts Teachers and PRACTICE TESTS for SSC CHSL in Best Price."
              keywords="Download LEARNING MATERIAL for SSC CHSL, Download PRACTICE TESTS for SSC CHSL"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (38 || '38'):
            return <SEO
              title="Get Learning Compete MATERIAL and PRACTICE TESTS for SSC GD"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for SSC GD in Refinable Cost."
              keywords="Download LEARNING MATERIAL for SSC GD, Download PRACTICE TESTS for SSC GD"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (39 || '39'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for IB ACIO"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for IB ACIO in Refinable Cost."
              keywords="Download LEARNING MATERIAL for IB ACIO, Download PRACTICE TESTS for IB ACIO"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (40 || '40'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for DSSB LDC"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for DSSB LDC Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for DSSB LDC, Download PRACTICE TESTS for DSSB LDC"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (41 || '41'):
            return <SEO
              title="Find Best MATERIAL and PRACTICE TESTS for Railway ITI Apprentice"
              description="Download Study Material Experts Teachers and PRACTICE TESTS for Railway ITI Apprentice from India’s No. 1 Website in lowest price."
              keywords="Download LEARNING MATERIAL for Railway ITI Apprentice, Download PRACTICE TESTS for Railway ITI Apprentice"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (42 || '42'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for SSC MTS"
              description="If you are looking best Cheapest Study Material Experts Teachers and PRACTICE TESTS for SSC MTS in Refinable Cost."
              keywords="Download LEARNING MATERIAL for SSC MTS, Download PRACTICE TESTS for SSC MTS"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (43 || '43'):
            return <SEO
              title="Complete Study MATERIAL and PRACTICE TESTS fo SSC CPO"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for SSC CPO in Refinable Cost."
              keywords="Download LEARNING MATERIAL for SSC CPO, Download PRACTICE TESTS for SSC CPO"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (44 || '44'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for SSC Steno"
              description="We have Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for SSC Steno in Refinable Cost."
              keywords="Download LEARNING MATERIAL for SSC Steno, Download PRACTICE TESTS for SSC Steno"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (45 || '45'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for RRB NTPC"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for RRB NTPC in Refinable Cost."
              keywords="Download LEARNING MATERIAL for RRB NTPC, Download PRACTICE TESTS for RRB NTPC"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (46 || '46'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for RRB Group D"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for RRB Group D in Refinable Cost."
              keywords="Download LEARNING MATERIAL for RRB Group D, Download PRACTICE TESTS for RRB Group D"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (47 || '47'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for COMEDK UGET"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for RRB ALP in Refinable Cost."
              keywords="Download LEARNING MATERIAL for RRB ALP, Download PRACTICE TESTS for RRB ALP"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (49 || '49'):
            return <SEO
              title="Complete Study MATERIAL and PRACTICE TESTS for DFCCIL"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for DFCCIL Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for DFCCIL, Download PRACTICE TESTS for DFCCIL"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (50 || '50'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for TET"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for TET in Refinable Cost."
              keywords="Download LEARNING MATERIAL for TET, Download PRACTICE TESTS for TET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (51 || '51'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CTET"
              description="We have Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for CTET in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CTET, Download PRACTICE TESTS for CTET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (52 || '52'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for UP (TGT, PGT)"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for UP (TGT, PGT) in Refinable Cost."
              keywords="Download LEARNING MATERIAL for UP (TGT, PGT), Download PRACTICE TESTS for UP (TGT, PGT)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (53 || '53'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for SPER TET"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for SPER TET in Refinable Cost."
              keywords="Download LEARNING MATERIAL for SPER TET, Download PRACTICE TESTS for SPER TET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (55 || '55'):
            return <SEO
              title="Complete Study MATERIAL and PRACTICE TESTS fo KVS"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for KVS in Refinable Cost."
              keywords="Download LEARNING MATERIAL for KVS, Download PRACTICE TESTS for KVS"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (56 || '56'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for NVS"
              description="We have Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for NVS in Refinable Cost."
              keywords="Download LEARNING MATERIAL for NVS, Download PRACTICE TESTS for NVS"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (57 || '57'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for UP TET"
              description="We have Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for UP TET in Refinable Cost."
              keywords="Download LEARNING MATERIAL for UP TET, Download PRACTICE TESTS for UP TET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (59 || '59'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for Bihar TET"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for Bihar TET in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Bihar TET, Download PRACTICE TESTS for Bihar TET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (60 || '60'):
            return <SEO
              title="Download MATERIAL and PRACTICE TESTS for REET"
              description="Find one of the best Complete Study Material With Experience and professional Teachers and PRACTICE TESTS for REET
              in Refinable Cost."
              keywords="Download LEARNING MATERIAL for REET, Download PRACTICE TESTS for REET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (61 || '61'):
            return <SEO
              title="Get Study HP TETERIAL and PRACTICE TESTS for HP TET"
              description="If you are looking best Cheapest Study HP TETerial Experts Teachers and PRACTICE TESTS for HP TET in Refinable Cost."
              keywords="Download LEARNING HP TETERIAL for HP TET, Download PRACTICE TESTS for HP TET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (62 || '62'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for UP LT Grade"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for UP LT Grade  in Refinable Cost."
              keywords="Download LEARNING MATERIAL for UP LT Grade , Download PRACTICE TESTS for UP LT Grade"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (63 || '63'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CG TET"
              description="If you are looking best Cheapest Study Material Experts Teachers and PRACTICE TESTS for CG TET in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CG TET, Download PRACTICE TESTS for CG TET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (64 || '64'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for Army Public School TET"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for Army Public School TET in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Army Public School TET, Download PRACTICE TESTS for Army Public School TET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (65 || '65'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for UP B.ED"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for UP B.ED in Refinable Cost."
              keywords="Download LEARNING MATERIAL for UP B.ED, Download PRACTICE TESTS for UP B.ED"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (66 || '66'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for HTET"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for HTET in Refinable Cost."
              keywords="Download LEARNING MATERIAL for HTET, Download PRACTICE TESTS for HTET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (67 || '67'):
            return <SEO
              title="LEARNING MATERIAL and PRACTICE TESTS for Punjab TET"
              description="We are one of the best Teachers LEARNING MATERIAL and PRACTICE TESTS for Punjab TET
              Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Punjab TET, Download PRACTICE TESTS for Punjab TET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (68 || '68'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for SSC JE"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for SSC JE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for SSC JE, Download PRACTICE TESTS for SSC JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (69 || '69'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for NIELIT Scintist B"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for NIELIT Scintist B in Refinable Cost."
              keywords="Download LEARNING MATERIAL for NIELIT Scintist B, Download PRACTICE TESTS for NIELIT Scintist B"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (70 || '70'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for Civil Exam"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for Civil Exam in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Civil Exam, Download PRACTICE TESTS for Civil Exam"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (71 || '71'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for HPCL"
              description="We have Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for HPCL in Refinable Cost."
              keywords="Download LEARNING MATERIAL for HPCL, Download PRACTICE TESTS for HPCL"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (72 || '72'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for RRE JE"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for RRE JE
              in Refinable Cost."
              keywords="Download LEARNING MATERIAL for RRE JE, Download PRACTICE TESTS for RRE JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (73 || '73'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for RRB JE MECHANICAL"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for RRB JE MECHANICAL in Refinable Cost."
              keywords="Download LEARNING MATERIAL for RRB JE MECHANICAL, Download PRACTICE TESTS for RRB JE MECHANICAL"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (74 || '74'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for RRB JE Civil"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for RRB JE Civil
              in Refinable Cost."
              keywords="Download LEARNING MATERIAL for RRB JE Civil, Download PRACTICE TESTS for RRB JE Civil"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (75 || '75'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for RRB JE ELECTRICAL"
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for RRB JE ELECTRICAL in Refinable Cost."
              keywords="Download LEARNING MATERIAL for RRB JE ELECTRICAL, Download PRACTICE TESTS for RRB JE ELECTRICAL"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (76 || '76'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for SSC JE ME"
              description="We are provides Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for SSC JE ME"
              keywords="Download LEARNING MATERIAL for SSC JE ME, Download PRACTICE TESTS for SSC JE ME"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (77 || '77'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for SSC JE CE"
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for SSC JE CE."
              keywords="Download LEARNING MATERIAL for SSC JE CE, Download PRACTICE TESTS forSSC JE CE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (78 || '78'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for SSC JE EE"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for SSC JE EE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for SSC JE EE, Download PRACTICE TESTS for SSC JE EE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (79 || '79'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CSPHCL JE"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for CSPHCL JE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CSPHCL JE, Download PRACTICE TESTS for CSPHCL JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (80 || '80'):
            return <SEO
              title="Complete Study MATERIAL and PRACTICE TESTS for PPSC JE"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for PPSC JE Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for PPSC JE, Download PRACTICE TESTS for PPSC JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (81 || '81'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for GPSC AE"
              description="We are provides Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for GPSC AE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for GPSC AE, Download PRACTICE TESTS for GPSC AE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (82 || '82'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for GETCO JE"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for GETCO JE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for GETCO JE, Download PRACTICE TESTS for GETCO JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (83 || '83'):
            return <SEO
              title="Get Complete Study MATERIAL and PRACTICE TESTS for AAI ATC (Airports Authority of India Airport Traffic Control)"
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for AAI ATC (Airports Authority of India Airport Traffic Control) in Refinable Cost."
              keywords="Download LEARNING MATERIAL for AAI ATC (Airports Authority of India Airport Traffic Control), Download PRACTICE TESTS for AAI ATC (Airports Authority of India Airport Traffic Control)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (84 || '84'):
            return <SEO
              title="Complete Study MATERIAL and PRACTICE TESTS for AAPSC AE (Andhra Pradesh Public Service Commision Assistance Engineer"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for AAPSC AE (Andhra Pradesh Public Service Commision Assistance Engineer in Refinable Cost."
              keywords="Download LEARNING MATERIAL for AAPSC AE (Andhra Pradesh Public Service Commision Assistance Engineer, Download PRACTICE TESTS for AAPSC AE (Andhra Pradesh Public Service Commision Assistance Engineer"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (85 || '85'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for Up Police Operator Recruitment"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for Up Police Operator Recruitment
              in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Up Police Operator Recruitment, Download PRACTICE TESTS for Up Police Operator Recruitment"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (86 || '86'):
            return <SEO
              title="Get Study RVUNL JEERIAL and PRACTICE TESTS for RVUNL JE"
              description="If you are looking best Cheapest Study RVUNL JEerial Experts Teachers and PRACTICE TESTS for RVUNL JE in Refinable Cost."
              keywords="Download LEARNING RVUNL JEERIAL for RVUNL JE, Download PRACTICE TESTS for RVUNL JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (87 || '87'):
            return <SEO
              title="Get Complete Study MATERIAL and PRACTICE TESTS Physics, Chemistry and Maths for UPPSC POLYTECHNIC"
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for UPPSC POLYTECHNIC Entrance Exam in Refinable Cost."
              keywords="Download LEARNING MATERIAL for UPPSC POLYTECHNIC, Download PRACTICE TESTS for UPPSC POLYTECHNIC"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (88 || '88'):
            return <SEO
              title="Complete Study MATERIAL and PRACTICE TESTS for UPRVUNL JE"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for UPRVUNL JE Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for UPRVUNL JE, Download PRACTICE TESTS for UPRVUNL JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (89 || '89'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for UKPSC JE"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for UKPSC JE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for UKPSC JE, Download PRACTICE TESTS for UKPSC JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (90 || '90'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for RSMSSB JE"
              description="We are provides Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for RSMSSB JE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for RSMSSB JE, Download PRACTICE TESTS for RSMSSB JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (91 || '91'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for DSSSB JE"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for DSSSB JE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for DSSSB JE, Download PRACTICE TESTS for DSSSB JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (92 || '92'):
            return <SEO
              title="Get Learning Compete MATERIAL and PRACTICE TESTS for DFCCIL JE"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for DFCCIL JE Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for DFCCIL JE, Download PRACTICE TESTS for DFCCIL JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (93 || '93'):
            return <SEO
              title="Get Learning Compete MATERIAL and PRACTICE TESTS for PSPCL"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for PSPCL Graduation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for PSPCL, Download PRACTICE TESTS for PSPCL"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (94 || '94'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CGPSC AE"
              description="We have Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for CGPSC AE in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CGPSC AE, Download PRACTICE TESTS for CGPSC AE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (95 || '95'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for WBSETCL JE"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for WBSETCL JE
              in Refinable Cost."
              keywords="Download LEARNING MATERIAL for WBSETCL JE, Download PRACTICE TESTS for WBSETCL JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (97 || '97'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for MPPSC AE"
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for MPPSC AE."
              keywords="Download LEARNING MATERIAL for MPPSC AE, Download PRACTICE TESTS forMPPSC AE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (98 || '98'):
            return <SEO
              title="Get Complete Study MATERIAL and PRACTICE TESTS Physics, Chemistry and Maths for DDA JE "
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for DDA JE Entrance Exam in Refinable Cost."
              keywords="Download LEARNING MATERIAL for DDA JE, Download PRACTICE TESTS for DDA JE"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (99 || '99'):
            return <SEO
              title="LEARNING MATERIAL and PRACTICE TESTS for AFCAT (Air Force Common Admission test)"
              description="We are one of the best Teachers LEARNING MATERIAL and PRACTICE TESTS for AFCAT (Air Force Common Admission test) in Refinable Cost."
              keywords="Download LEARNING MATERIAL for AFCAT (Air Force Common Admission test), Download PRACTICE TESTS for AFCAT (Air Force Common Admission test)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (100 || '100'):
            return <SEO
              title="Download MATERIAL and PRACTICE TESTS for Indian Air Force Group X"
              description="Find one of the best Complete Study Material With Experience and professional Teachers and PRACTICE TESTS for Indian Air Force Group X in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Indian Air Force Group X, Download PRACTICE TESTS for Indian Air Force Group X"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;

          case (103 || '103'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for INET"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for INET in Refinable Cost."
              keywords="Download LEARNING MATERIAL for INET, Download PRACTICE TESTS for INET"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (104 || '104'):
            return <SEO
              title="LEARNING MATERIAL and PRACTICE TESTS for Territorial Army"
              description="We are one of the best Teachers LEARNING MATERIAL and PRACTICE TESTS for Territorial Army in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Territorial Army, Download PRACTICE TESTS for Territorial Army)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (105 || '105'):
            return <SEO
              title="Get Learning Compete MATERIAL and PRACTICE TESTS for ACC (Army Cadet College)"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for ACC (Army Cadet College) in Refinable Cost."
              keywords="Download LEARNING MATERIAL for ACC (Army Cadet College), Download PRACTICE TESTS for ACC (Army Cadet College)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (106 || '106'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for Indian Navy SSC Officer"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for Indian Navy SSC Officer in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Indian Navy SSC Officer, Download PRACTICE TESTS for Indian Navy SSC Officer"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (107 || '107'):
            return <SEO
              title="LEARNING MATERIAL and PRACTICE TESTS for Indian Coast Guard AC"
              description="We are one of the best Teachers LEARNING MATERIAL and PRACTICE TESTS for Indian Coast Guard AC in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Indian Coast Guard AC, Download PRACTICE TESTS for Indian Coast Guard AC)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (108 || '108'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CA Foundation"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for CA Foundation in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CA Foundation, Download PRACTICE TESTS for CA Foundation"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (109 || '109'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CA Intermediate"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for CA Intermediate in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CA Intermediate, Download PRACTICE TESTS for CA Intermediate"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (110 || '110'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for CA Final"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for CA Final in Refinable Cost."
              keywords="Download LEARNING MATERIAL for CA Final, Download PRACTICE TESTS for CA Final"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
        }

      })()}

      {(() => {
        switch (filterOptions.selected_class) {
          case (1 || '1'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for Nursery Class"
              description="We are provides Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for Nursery Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Nursery Class, Download PRACTICE TESTS for Nursery Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (2 || '2'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for LKG Class"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for LKG Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for LKG Class, Download PRACTICE TESTS for LKG Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (3 || '3'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for UKG Class"
              description="We have Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for UKG Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for UKG Class, Download PRACTICE TESTS for UKG Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (4 || '4'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 1st Class"
              description="We are provides Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for 1st Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 1st Class, Download PRACTICE TESTS for 1st Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (5 || '5'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 2nd Class"
              description="If you are looking best Cheapest Study Material Experts Teachers and PRACTICE TESTS for 2nd Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 2nd Class, Download PRACTICE TESTS for 2nd Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (6 || '6'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 3rd Class"
              description="We have Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for 3rd Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 3rd Class, Download PRACTICE TESTS for 3rd Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (7 || '7'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 4th Class"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for 4th Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 4th Class, Download PRACTICE TESTS for 4th Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (8 || '8'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 5th Class"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for 5th Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 5th Class, Download PRACTICE TESTS for 5th Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (9 || '9'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 6th Class"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for 6th Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 6th Class, Download PRACTICE TESTS for 6th Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (10 || '10'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 7th Class"
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for 7th Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 7th Class, Download PRACTICE TESTS for 7th Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (11 || '11'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 8th Class"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for 8th Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 8th Class, Download PRACTICE TESTS for 8th Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (12 || '12'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 9th Class"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for 9th Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 9th Class, Download PRACTICE TESTS for 9th Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (13 || '13'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 10th Class"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for 10th Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 10th Class, Download PRACTICE TESTS for 10th Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (14 || '14'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 11th Class"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for 11th Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 11th Class, Download PRACTICE TESTS for 11th Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (15 || '15'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for 12th Class"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for 12th Class in Refinable Cost."
              keywords="Download LEARNING MATERIAL for 12th Class, Download PRACTICE TESTS for 12th Class"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;


          case (16 || '16'):
            return <SEO
              title="Find Best MATERIAL and PRACTICE TESTS for BCom"
              description="Download Study Material Experts Teachers and PRACTICE TESTS for BCom from India’s No. 1 Website in lowest price."
              keywords="Download LEARNING MATERIAL for BCom, Download PRACTICE TESTS for BCom"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (17 || '17'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for BCom(Hons)"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for  BCom(Hons) in Refinable Cost."
              keywords="Download LEARNING MATERIAL for  BCom(Hons), Download PRACTICE TESTS for  BCom(Hons)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (18 || '18'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for BA"
              description="We are provides Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for BA in Refinable Cost."
              keywords="Download LEARNING MATERIAL for BA, Download PRACTICE TESTS for BA"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (19 || '19'):
            return <SEO
              title="Best Study MATERIAL and PRACTICE TESTS for BA(Hons)"
              description="Get one of the best Complete Study Material Experts Teachers and PRACTICE TESTS for BA(Hons) in Very Low Cost."
              keywords="Download LEARNING MATERIAL for BA(Hons), Download PRACTICE TESTS for BA(Hons)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (20 || '20'):
            return <SEO
              title="Get Complete Study MATERIAL and PRACTICE TESTS for Bsc"
              description="If you are lookng Experts Teachers LEARNING MATERIAL and PRACTICE TESTS for Bsc in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Bsc, Download PRACTICE TESTS for Bsc"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (21 || '21'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for Msc"
              description="We are provides No. 1 Study Material Experts Teachers and PRACTICE TESTS for Msc in Refinable Cost."
              keywords="Download LEARNING MATERIAL for Msc, Download PRACTICE TESTS for Msc"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (22 || '22'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for MA"
              description="Get One of best Learning Compete MATERIAL and PRACTICE TESTS for MA in Refinable Cost."
              keywords="Download LEARNING MATERIAL for MA, Download PRACTICE TESTS for MA"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (23 || '23'):
            return <SEO
              title="Study MATERIAL and PRACTICE TESTS for MBA in Lowest Cost"
              description="We are providing Affordable Complete Study Material Experts Teachers and PRACTICE TESTS for MBA in Refinable Cost."
              keywords="Download LEARNING MATERIAL for MBA, Download PRACTICE TESTS for MBA"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (24 || '24'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for MCom"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for MCom in Refinable Cost."
              keywords="Download LEARNING MATERIAL for MCom, Download PRACTICE TESTS for MCom"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
          case (25 || '25'):
            return <SEO
              title="Get Study MATERIAL and PRACTICE TESTS for MCom(Hons)"
              description="We are Provide Experts Teachers Complete LEARNING MATERIAL and PRACTICE TESTS for MCom(Hons) in Refinable Cost."
              keywords="Download LEARNING MATERIAL for MCom(Hons), Download PRACTICE TESTS for MCom(Hons)"
              canonical="https://www.irevize.com/search"
            >
            </SEO>;
        }

      })()}
    </>
  );
}
export default SearchPageSEO;
