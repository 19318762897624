import React from 'react';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProfileDetailsRight from './../../../components/Dashboard/ProfilePage/ProfileDetailsRight/ProfileDetailsRight';
function ProfileSettings() {

  return (
    <>
      <div className="pagetitle">
        <h1>Profile Settings</h1>
      </div>
      <section className="section dashboard">
        <ProfileDetailsRight />
      </section>
    </>

  );
}

export default ProfileSettings;
