import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {
  CircularProgress, Box, Button, Grid, Card, CardHeader, Divider, CardContent,
  styled, Table, TableBody, TableRow, TableCell, TextField
} from '@mui/material';
import { useCart } from '../../../contexts/CartContext';
const StyledTable = styled(Table)(() => ({
  minWidth: 200,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  // '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  '& th': { background: "#003152", color: "#FFF" },
}));

function Checkout() {
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const { cart, cartCheckOut } = useCart();

  useEffect(() => {
    fetchData();
  }, []);
  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/user/profile')
          .then(response => {
            if (response.data.status_code === 200) {
              setUser(response.data.data);
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }
  return (
    <>
      <div className="breadcrumb_section  page-title-mini">
        <div className="container">
          <div className="row">

            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                <li className="breadcrumb-item active">Checkout</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content checkout">
        <div className="section">
          <div className="container">
            <h1 className="btn-shine">Checkout</h1>
            <Grid container item rowSpacing={2} columnSpacing={4}>
              <Grid item lg={8} sm={12} xs={12}>
                <Card>
                  <CardContent>
                    <h3 className="btn-shine">Billing Address</h3>
                    {user && (
                      <>
                        <Grid container item rowSpacing={2} columnSpacing={4}>
                          <Grid item lg={6} sm={12} xs={12}>
                            <label>Email ID</label>
                            <TextField fullWidth name='email' variant="outlined" value={user.email} size='small' placeholder='Email ID' />
                            {/* <label>Address Line</label>
                            <TextField fullWidth name='address' variant="outlined" value={user.email} size='small' placeholder='Address Line' /> */}
                          </Grid>
                          <Grid item lg={6} sm={12} xs={12}>
                            <label>Mobile Number</label>
                            <TextField fullWidth name='email' variant="outlined" value={user.mobile_no} size='small' placeholder='Your Mobile Number' />
                            {/* <label>City</label>
                            <TextField fullWidth name='email' variant="outlined" value={user.email} size='small' placeholder='Your City' /> */}
                          </Grid>
                        </Grid>
                      </>
                    )}

                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={4} sm={12} xs={12}>
                <Card style={{ background: "#F4F4F4" }}>
                  <CardHeader
                    title="Summary"
                    titleTypographyProps={{
                      variant: "h2",
                      fontSize: '20px',
                      fontWeight: '600',
                    }}
                  />
                  <Divider />
                  <CardContent style={{ padding: "0px" }}>
                    {cart && (
                      <>
                        <StyledTable>
                          <TableBody>
                            {cart && (
                              <>
                                <TableRow>
                                  <TableCell align="left" sx={{ width: "120px" }}>
                                    Original Price:
                                  </TableCell>
                                  <TableCell align="right">
                                    {process.env.REACT_APP_CURRENCY} {cart.cart_total_price}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="left" sx={{ width: "120px" }}>
                                    <span className="price">Total: </span>
                                  </TableCell>
                                  <TableCell align="right">
                                    <span className="price">{process.env.REACT_APP_CURRENCY} {cart.cart_total_selling_price}</span>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colSpan={2}>
                                    <br />
                                    <Link to={'#'} onClick={() => cartCheckOut()} className="btn btn-fill-out rounded-0 checkout display-block">
                                      Complate Checkout
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </TableBody >
                        </StyledTable>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div >
        </div >
      </div >
    </>
  );
}
export default Checkout;
