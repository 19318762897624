import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './../../../contexts/AuthContext';
function JoiningMemberCard() {
  const { setOpenAuth, setIsSignUp, checkAuth, checkUserAuth, auth_user_type, setUserType, logoutAuth } = useAuth();
  const handleOpen = (authState) => {
    if (!auth_user_type) {
      setIsSignUp(true);
      setOpenAuth(true)
      setUserType(2);
    }
  }
  return (
    <>
      <div className="section row">
        <div className="col-md-6">
          <div className="sale_banner bluebnr">
            <Link className="hover_effect1" to="#">
              <img src={`${process.env.PUBLIC_URL}/assets/images/online-course.jpg`} alt="shop_banner_img4" />
            </Link>
            <div className="sale_banner_text">
              <span>New Collection</span>
              <h3>Latest Maths table practice sets</h3>
              <p>For kids aged 5 to 12 years</p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="sale_banner greenbnr">
            <div className="sale_banner_text" style={{ width: "100%" }}>
              <span>Signup as Teacher</span>
              <h3>Signup for free as a teacher and start earning</h3>
              <p>Increase your earnings 10X as a teacher</p>
              {(!auth_user_type) ? (
                <Link to={'/join-now'} className="btnbtn">Join Now</Link>
              ) : ((auth_user_type == '3') ? (
                <>
                  <Link to={'/dashboard'} className="btnbtn">Dashboard</Link>
                  &nbsp;<span style={{ color: '#FFF' }}>{`  You are logged in as Student. Logout first then signup as teacher.`}</span>
                </>
              ) : (
                <Link to={'/dashboard'} className="btnbtn">Dashboard</Link>
              ))}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default JoiningMemberCard;
