import React from 'react';
import { Link } from 'react-router-dom';

function HelpVideos() {

  return (
    <>
      <div className="pagetitle">
        <h1>Help Videos</h1>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="card recent-sales overflow-auto">
                <div className="col-md-12">
                  <h3 className="card-smallhead">Help Videos <a href="#" className="btn btn-green pull-right">More Videos</a></h3>
                </div>
                <div className="row hpvideosctn">
                  <div className="col-md-4">
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/aun8fica7tw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <p>How to compose a newsletter</p>
                  </div>
                  <div className="col-md-4">
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/aun8fica7tw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <p>How to compose a newsletter</p>
                  </div>
                  <div className="col-md-4">
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/aun8fica7tw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <p>How to compose a newsletter</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HelpVideos;
