import React from 'react';
import { Link } from 'react-router-dom';
import SEO from './../../layouts/SEO';
import DownloadApp from './../Homepage/DownloadApp';

function RefundPolicy() {
  return (
    <>
      <SEO
        title="Refund Policy – irevize"
        description="We want you to be completely satisfied with your purchase from www.irevize.com. If you are not satisfied with your purchase, we offer a flexible and transparent refund policy."
        keywords="Refund Policy,Refund, Policy"
        canonical="http://irevize.com/refund-policy"
      >
      </SEO>
      <div className="breadcrumb_section  page-title-mini">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="/" title='Home'>Home</Link></li>
                <li className="breadcrumb-item active" title='Refund Policy'>Refund Policy</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row align-items-center prod-detail" style={{ textAlign: 'justify' }}>
                  <div className="col-12">
                    <h1 className="btn-shine text-center">Refund Policy</h1>
                    <p>
                      We want you to be completely satisfied with your purchase from www.irevize.com. If you are not satisfied with your purchase, we offer a flexible and transparent refund policy.
                    </p>
                    <h2 className="btn-shine mt-30">Timeframe for Returns</h2>
                    <p>
                      We accept returns within 7 days of the purchase date for digital products, If 7 days have gone by since your purchase, unfortunately, we can’t offer you a refund or exchange.
                    </p>

                    <h2 className="btn-shine mt-30">Condition of Products</h2>
                    <p>
                      To be eligible for a return, the product or service must hold incorrect data/information which is not relevant to the topic of your purchase. As you apply for a refund our team validates your concerns with the author of the product and processes your refund as the author accepts the concerns raised by you in the product.
                    </p>
                    <h2 className="btn-shine mt-30">Refund</h2>
                    <p>
                      We offer refunds for products that meet the above criteria. If you choose a refund, we will process your refund within 3-5 business days
                    </p>
                    <h2 className="btn-shine mt-30">Contact Us</h2>
                    <p>
                      If you have any questions about our refund policy, please contact us at <Link to={'mailto:support@irevize.com'}> support@irevize.com</Link> and we will be happy to assist you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <DownloadApp /> */}
      </div>
    </>
  );
}
export default RefundPolicy;
