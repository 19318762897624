import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import swal from 'sweetalert';
import axios from 'axios';

import './ProfileInfo.scss';
import {
  Box, Grid, Dialog,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  Avatar,
  IconButton,
  MenuItem,
  Select,
  useTheme, Button
} from "@mui/material";
import ProfileImage from '../EditProfile/ProfileImage';
import EditProfile from '../EditProfile/EditProfile';
import EditLoginInfo from '../EditProfile/EditLoginInfo'
import EditBankingInfo from '../EditBankingInfo/EditBankingInfo';
const Item = styled("div")(({ theme }) => (
  {
    marginBottom: "5px",
    fontSize: '16px',
    fontWeight: '400',
    color: '#484747'
  }
));
const ItemContent = styled("div")(({ theme }) => (
  {
    marginBottom: "15px",
    fontSize: '16px',
    fontWeight: '600',
  }
));
function ProfileInfo() {
  const [userProfile, setUserProfile] = useState({});
  const [userProfileInfo, setUserProfileInfo] = useState({
    user_id: '',
    user_type: '',
    name: '',
    mobile_no: '',
    email: '',
    about_me:'',
    key_strengths:'',
    qualification: '',
    organization_type: '',
    experience_as_teacher: '',
    account_holder_name: '',
    account_number: '',
    bank_name: '',
    ifsc_code: '',
    gst_number: '',
  });

  const [open, setOpen] = useState(false);
  const [updateState, setUpdateState] = useState(true);
  const [openBankingInfo, setOpenBankingInfo] = useState(false);
  const [bankingInfoUpdate, setBankingInfoUpdate] = useState(true);
  const [loginInfoUpdate, setLoginInfoUpdate] = useState(true);
  const [openLoginInfo, setOpenLoginInfo] = useState(false);
  const [openProfileImagePopup, setOpenProfileImagePopup] = useState(false);
  const [updateProfileIamge, setUpdateProfileIamge] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleBankingInfoOpen = () => setOpenBankingInfo(true);
  const handleChangePassword = () => setOpenLoginInfo(true);
  const handleProfileImageClick = () => setOpenProfileImagePopup(true);

  useEffect(() => {
    if (updateProfileIamge === true) {
      fetchData();
      setUpdateProfileIamge(false);
    }
    if (updateState == true) {
      fetchData();
      fetchUserInfo();
      setUpdateState(false);
    }
    if (bankingInfoUpdate == true) {
      fetchUserInfo();
      setBankingInfoUpdate(false);
    }

  }, [updateProfileIamge, updateState, bankingInfoUpdate]);

  const fetchData = () => {
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/user/profile').then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.status_code === 200) {
            setUserProfile(data.data);
          } else if (data.status_code === '401') {

            var messageText = '';
            if (data.errors.email) {
              messageText = data.errors.email[0];
            }
            if (data.errors.password) {
              messageText = messageText + '\n' + data.errors.password[0];
            }
            swal({
              title: data.message,
              text: messageText,
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  }
  const fetchUserInfo = () => {
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/user/profile-info').then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.status_code === 200) {
            if (data.data) {
              setUserProfileInfo(data.data);
            }
          } else if (data.status_code === '401') {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  }

  return (
    <>
      <div className="profileInfo" style={{ marginBottom: '20px' }}>
        <div className="profileInfo__content">
          <div className="profileInfo__contenthead">
            <div className="profileInfo__profile">
              <h4>Personal Info</h4>
              <h6>Basic info, for a faster search</h6>
            </div>
            <Button
              variant="contained"
              type="button"
              size="small" onClick={handleOpen}
              color="secondary"
              sx={{ textTransform: "capitalize" }}
            >
              <EditIcon /> Edit
            </Button>
          </div>
          <div className="profileInfo__seperator" />
          <div className='row profileInfo__name'>
            <div className='col-sm-6 col-md-4 profileInfo__fields__content'>
              <h6>Profile Image</h6>
            </div>
            <div className='col-sm-6 col-md-5 profileInfo__fields__content'>

              {userProfile.profile_image ? (
                <>
                  <Avatar className="header__profile@" src={userProfile.profile_image} alt={userProfile.username} sx={{ float: "left" }}>
                    {userProfile.name[0]}
                  </Avatar>

                </>
              ) : (
                <>
                </>
              )}
              <Button
                variant="contained"
                onClick={handleProfileImageClick}
                size="small"
                sx={{ ml: 2, mt: .5, textTransform: "capitalize" }}
              >
                Update Profile Image
              </Button>
              <ProfileImage userProfile={userProfile} openProfileImagePopup={openProfileImagePopup} setOpenProfileImagePopup={setOpenProfileImagePopup} setUpdateProfileIamge={setUpdateProfileIamge} />
            </div>
          </div>
          <div className="profileInfo__seperator" />
          <div className='row profileInfo__name'>
            <div className='col-sm-6 col-md-4 profileInfo__fields__content'>
              <h6>Name</h6>
            </div>
            <div className='col-sm-6 col-md-5 profileInfo__fields__content'>
              <p>{userProfile.name}</p>
            </div>
          </div>
          <div className="profileInfo__seperator" />
          <div className='row profileInfo__name'>
            <div className='col-sm-6 col-md-4 profileInfo__fields__content'>
              <h6>You are</h6>
            </div>
            <div className='col-sm-6 col-md-5 profileInfo__fields__content'>
              <p>{(userProfile.user_type == 2) ? "Teacher" : "Student"}</p>
            </div>
          </div>
          {/* For Teacher */}
          {(userProfile.user_type == 2) ?
            (
              <>
                <div className="profileInfo__seperator" />
                <div className="row profileInfo__name">
                  <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                    <h6>About me</h6>
                  </div>
                  <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                    <p>{userProfileInfo.about_me}</p>
                  </div>
                </div>
                <div className="profileInfo__seperator" />
                <div className="row profileInfo__name">
                  <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                    <h6>Highlight your key strengths</h6>
                  </div>
                  <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                    <p>{userProfileInfo.key_strengths}</p>
                  </div>
                </div>
                <div className="profileInfo__seperator" />
                <div className="row profileInfo__name">
                  <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                    <h6>Education/Qualification</h6>
                  </div>
                  <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                    <p>{userProfileInfo.qualification}</p>
                  </div>
                </div>
                <div className="profileInfo__seperator" />
                <div className="row profileInfo__name">
                  <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                    <h6>Organization Type</h6>
                  </div>
                  <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                    <p>{userProfileInfo.organization_type_name}</p>
                  </div>
                </div>

                <div className="profileInfo__seperator" />
                <div className="row profileInfo__name">
                  <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                    <h6>Experience as a Teacher</h6>
                  </div>
                  <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                    <p>{userProfileInfo.experience_as_teacher}</p>
                  </div>
                </div>
              </>) : (
              <>
                {/* <div className="profileInfo__seperator" />
                <div className="row profileInfo__name">
                  <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                    <h6>Level</h6>
                  </div>
                  <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                    <p>{userProfile.level}</p>
                  </div>
                </div> */}
              </>
            )}

        </div>
        <EditProfile userProfileInfo={userProfileInfo} open={open} setOpen={setOpen} handleProfileUpdate={setUpdateState} />
      </div >
      {/* For Teacher */}
      {
        (userProfile.user_type == 2) ?
          (
            <>
              <div className="profileInfo" style={{ marginBottom: '20px' }}>
                <div className="profileInfo__content">
                  <div className="profileInfo__contenthead">
                    <div className="profileInfo__profile">
                      <h4>Banking Info</h4>
                      <h6>Basic info</h6>
                    </div>
                    <Button
                      variant="contained"
                      type="button"
                      size="small" onClick={handleBankingInfoOpen}
                      color="secondary"
                      sx={{ textTransform: "capitalize" }}
                    >
                      <EditIcon /> Edit
                    </Button>
                  </div>

                  <div className="profileInfo__seperator" />
                  <div className="row profileInfo__name">
                    <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                      <h6>Account Holder Name</h6>
                    </div>
                    <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                      <p>{userProfileInfo.account_holder_name}</p>
                    </div>
                  </div>
                  <div className="profileInfo__seperator" />
                  <div className="row profileInfo__name">
                    <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                      <h6>Account Number</h6>
                    </div>
                    <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                      <p>{userProfileInfo.account_number}</p>
                    </div>
                  </div>
                  <div className="profileInfo__seperator" />
                  <div className="row profileInfo__name">
                    <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                      <h6>Bank Name</h6>
                    </div>
                    <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                      <p>{userProfileInfo.bank_name}</p>
                    </div>
                  </div>

                  <div className="profileInfo__seperator" />
                  <div className="row profileInfo__name">
                    <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                      <h6>IFSC code</h6>
                    </div>
                    <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                      <p>{userProfileInfo.ifsc_code}</p>
                    </div>
                  </div>
                  <div className="profileInfo__seperator" />
                  <div className="row profileInfo__name">
                    <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                      <h6>GST Number</h6>
                    </div>
                    <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                      <p>{userProfileInfo.gst_number}</p>
                    </div>
                  </div>
                </div>
                <EditBankingInfo userProfileInfo={userProfileInfo} open={openBankingInfo} setOpen={setOpenBankingInfo} setBankingInfoUpdate={setBankingInfoUpdate} />
              </div>
            </>
          ) : <></>
      }
      <div className="profileInfo" style={{ marginBottom: '20px' }}>
        <div className="profileInfo__content">
          <div className="profileInfo__contenthead">
            <div className="profileInfo__profile">
              <h4>Login Details</h4>
              <h6>Manage your email address mobile number and password</h6>
            </div>
          </div>
          <div className="profileInfo__seperator" />
          <div className="row profileInfo__name">
            <div className="col-sm-6 col-md-4 profileInfo__fields__content">
              <h6>Mobile Number</h6>
            </div>
            <div className="col-sm-6 col-md-5 profileInfo__fields__content">
              <p>{userProfileInfo.mobile_no}</p>
            </div>
          </div>
          <div className="profileInfo__seperator" />
          <div className="row profileInfo__name">
            <div className="col-sm-6 col-md-4 profileInfo__fields__content">
              <h6>Email ID</h6>
            </div>
            <div className="col-sm-6 col-md-5 profileInfo__fields__content">
              <p>{userProfileInfo.email}</p>
            </div>
          </div>
          {!userProfileInfo.is_social_user ? (
            <>
              <div className="profileInfo__seperator" />
              <div className="row profileInfo__name">
                <div className="col-sm-6 col-md-4 profileInfo__fields__content">
                  <h6>Password</h6>
                </div>
                <div className="col-sm-6 col-md-5 profileInfo__fields__content">
                  <p>******</p>
                </div>
                <div className="col-sm-6 col-md-3 profileInfo__fields__content" style={{ textAlign: 'right' }}>
                  <Button sx={{ textTransform: "capitalize" }} color="secondary" onClick={handleChangePassword}>Change Password?</Button>
                  <EditLoginInfo userProfileInfo={userProfileInfo} open={openLoginInfo} setOpen={setOpenLoginInfo} setLoginInfoUpdate={setLoginInfoUpdate} />
                </div>
              </div>
            </>
          ) : ''}
        </div>
      </div>
    </>
  );
}

export default ProfileInfo;
