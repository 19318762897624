import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import {
  Card,
  Divider,
  CardContent,
  CardHeader,
  Box, Grid, Dialog,
  DialogTitle, DialogContent, DialogActions,
  IconButton,
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress,
  useTheme, Alert,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
// import { LoadingButton } from '@mui/lab';

import Moment from 'moment';

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
}));

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  margin: "0px !important",
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));
const Item = styled("div")(({ theme }) => (
  {
    marginBottom: "5px",
    fontSize: '16px',
    fontWeight: '400',
    color: '#484747'
  }
));
const ItemContent = styled("div")(({ theme }) => (
  {
    marginBottom: "15px",
    fontSize: '16px',
    fontWeight: '600',
  }
));

const StyledTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  // '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  '& th': { background: "#003152", color: "#FFF" },
}));

const Container = styled("div")(({ theme }) => (
  {
    margin: "20px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }
));
const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  // background: 'rgba(0, 0, 0, 0.01)',
}));

const RefundsRequested = () => {

  const { palette } = useTheme();
  const bgInfo = palette.info.main;
  const bgError = palette.error.main;
  const bgSuccess = palette.success.main;
  const bgWarning = palette.warning.main;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [tableData, setTableData] = useState(false);
  const [open, setOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [paper, setPaper] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-refunds-requested').then((res) => {
          var data = res.data;
          if (data !== undefined) {
            if (data.response_code === 200) {
              setTableData(data.data);
            } else if (data.response_code === '401') {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            } else {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            }
          }
        });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Delete row data from server.
   */
  const __rowDelete = (e, row_id, name) => {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover \"" + name + "\" Refund!",
      icon: "warning",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        try {
          axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('/api/dashboard/remove-refund-request', {
              'row_id': row_id
            })
              .then(response => {
                if (response.data.response_code === 200) {
                  fetchData();
                  swal({
                    text: response.data.message,
                    icon: 'success'
                  })
                } else if (response.data.response_code === 412) {
                  swal({
                    text: response.data.message,
                    icon: "error",
                  });
                } else {
                  swal({
                    title: "Errors!",
                    text: response.data.message,
                    icon: "error",
                  });
                }
                setLoading(false);
              })
              .catch(function (error) {
                setLoading(false);
                console.error(error);
              });
          });
        } catch (e) {
          setLoading(false);
        }
      }
    });
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  const visiblityModal = (rowData) => {
    setPaper(rowData)
    setOpen(true);
  };
  const visiblityDetailModal = (rowData) => {
    setDetailModalOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="pagetitle">
        <h1>Refunds Requested</h1>
      </div>
      <section className="section dashboard">
        <Card>
          <CardHeader
            // title="Your all papers related to learning material"
            titleTypographyProps={{
              variant: "h4",
              fontSize: '16px',
              fontWeight: '500',
              // textTransform: 'uppercase',
            }}
          />
          <Divider />
          <CardContent style={{ padding: "0px" }}>
            <Box width="100%" overflow="auto">
              <StyledTable sx={{ minWidth: 1000 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ width: '50px' }}>Sr.No.</TableCell>
                    <TableCell align="left">Order ID</TableCell>
                    <TableCell align="left">Product</TableCell>
                    <TableCell align="left">Title</TableCell>
                    {/* <TableCell align="left">Description</TableCell> */}
                    <TableCell align="left">Posted Date</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center" sx={{ width: '100px' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData ? (tableData.length) ? (
                    <>
                      {
                        tableData
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                            <TableRow key={index} hover>
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell align="left" title={row.hash_order_id}>{row.hash_order_id}</TableCell>
                              <TableCell align="left" title={row.topic_title}>{(row.topic_title) ? row.topic_title.replace(/(.{30})..+/, "$1…") : ''}</TableCell>
                              <TableCell align="left" title={row.ticket_title}>{(row.ticket_title) ? row.ticket_title.replace(/(.{30})..+/, "$1…") : ''}</TableCell>
                              {/* <TableCell align="left" title={row.description}>{(row.description) ? row.description.replace(/(.{50})..+/, "$1…") : ''}</TableCell> */}
                              <TableCell align="left">{Moment(row.created_at).format('DD/MM/YYYY')}</TableCell>
                              <TableCell title="View" align="center">
                                {(() => {
                                  switch (row.status) {
                                    case 'closed':
                                      return <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Closed">Closed</Small>
                                    case 'pending':
                                      return <Small bgcolor={bgWarning} variant="contained" size="small" sx={{ m: 1 }}>Pending</Small>
                                    case 'process':
                                      return <Small bgcolor={bgInfo} variant="contained" size="small" sx={{ m: 1 }}>Process</Small>
                                    default:
                                      return null
                                  }
                                })()}
                              </TableCell>
                              <TableCell title="View" align="right">
                                <Link title="Preview"
                                  to={`/dashboard/refunds-requested/view-refund/${row.id}`}
                                  color="warning" variant="outlined" size="small"
                                >
                                  <PreviewIcon color="secondary" sx={{ m: 1 }} />
                                </Link>
                                {/* <Link title="Delete"
                                  to={`#`}
                                  variant="outlined" size="small"
                                  onClick={(e) => __rowDelete(e, row.id, row.ticket_title)}
                                >
                                  <DeleteIcon color="error" />
                                </Link> */}
                              </TableCell>
                            </TableRow>
                          ))
                      }
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell align="center" colSpan={9}>
                          <Alert severity="error" align="center">No Data found!</Alert>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : <TableRow><TableCell align="center" colSpan={9}><CircularProgress /></TableCell></TableRow>}
                </TableBody>
              </StyledTable>
              {(tableData && tableData.length) ?
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={tableData.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
                : <>

                </>
              }

            </Box>
          </CardContent>
        </Card >
      </section >
    </>

  );
};

export default RefundsRequested;
