import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import {
    Card,
    Divider,
    CardContent,
    CardHeader,
    Box, Grid, Dialog,
    DialogTitle, DialogContent, DialogActions,
    IconButton,
    Button,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    CircularProgress,
    Avatar,
    MenuItem,
    useTheme, Alert,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import PreviewIcon from '@mui/icons-material/Preview';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import Moment from 'moment';

import DetailModalDialog from './DetailModalDialog';
import PreviewModalDialog from './PreviewModalDialog';

const Title = styled('span')(() => ({
    fontSize: '1rem',
    fontWeight: '500',
    textTransform: 'capitalize',
}));

const Small = styled('small')(({ bgcolor }) => ({
    width: 50,
    height: 15,
    color: '#fff',
    padding: '2px 8px',
    borderRadius: '4px',
    overflow: 'hidden',
    margin: "0px !important",
    background: bgcolor,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));
const Item = styled("div")(({ theme }) => (
    {
        marginBottom: "5px",
        fontSize: '16px',
        fontWeight: '400',
        color: '#484747'
    }
));
const ItemContent = styled("div")(({ theme }) => (
    {
        marginBottom: "15px",
        fontSize: '16px',
        fontWeight: '600',
    }
));

const StyledTable = styled(Table)(() => ({
    minWidth: 400,
    whiteSpace: 'pre',
    '& small': {
        width: 50,
        height: 15,
        borderRadius: 500,
        boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
    },
    // '& td': { borderBottom: 'none' },
    '& td:first-of-type': { paddingLeft: '16px !important' },
    '& th': { background: "#003152", color: "#FFF" },
}));

const Container = styled("div")(({ theme }) => (
    {
        margin: "20px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }
));
const ContentBox = styled(Box)(() => ({
    height: '100%',
    padding: '32px',
    position: 'relative',
    // background: 'rgba(0, 0, 0, 0.01)',
}));

const PracticeMaterial = () => {

    const { palette } = useTheme();
    const bgError = palette.error.main;
    const bgSuccess = palette.success.main;
    const bgWarning = palette.warning.main;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [tableData, setTableData] = useState(false);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [paper, setPaper] = useState([]);

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchData();
    }, []);

    /**
     * Fetching data from server.
     */
    const fetchData = async () => {
        setLoading(true);
        try {
            await axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post('/api/dashboard/get-practice-material').then((res) => {
                    var data = res.data;
                    if (data !== undefined) {
                        if (data.response_code === 200) {
                            setTableData(data.data);
                        } else if (data.response_code === '401') {
                            swal({
                                title: "Server not responding!",
                                text: 'Please try again!',
                                icon: "warning",
                            });
                        } else {
                            swal({
                                title: "Server not responding!",
                                text: 'Please try again!',
                                icon: "warning",
                            });
                        }
                    }
                });
            });
        } catch (e) {
            setLoading(false);
        }
    }

    const __rowDelete = (e, row_id, name) => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover \"" + name + "\" Topic!",
            icon: "warning",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                try {
                    axios.get('/sanctum/csrf-cookie').then(response => {
                        axios.post('/api/dashboard/remove-study-material', {
                            'row_id': row_id
                        })
                            .then(response => {
                                if (response.data.response_code === 200) {
                                    fetchData();
                                    swal({
                                        title: 'Done!',
                                        text: response.data.message,
                                        icon: 'success'
                                    })
                                } else if (response.data.response_code === 412) {
                                    swal({
                                        title: "Errors!",
                                        text: response.data.message,
                                        icon: "error",
                                    });
                                } else {
                                    swal({
                                        title: "Errors!",
                                        text: response.data.message,
                                        icon: "error",
                                    });
                                }
                                setLoading(false);
                            })
                            .catch(function (error) {
                                setLoading(false);
                                console.error(error);
                            });
                    });
                } catch (e) {
                    setLoading(false);
                }
            } else {
                swal("Cancelled", "Your data is safe :)", "error");
            }
        });
    }

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    interface DialogTitleProps {
        id: string;
        children?: React.ReactNode;
        onClose: () => void;
    }

    function BootstrapDialogTitle(props: DialogTitleProps) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    const visiblityDetailModal = (rowData) => {
        setPaper(rowData)
        setDetailModalOpen(true);
    };
    // Product Preview
    const visiblityPreviewModal = (rowData) => {
        setPaper(rowData)
        setPreviewModalOpen(true);
    };

    return (
        <>
            <div className="pagetitle">
                <h1>Practice Tests</h1>
            </div>
            <section className="section dashboard">
                <Card title="Practice Test">
                    <CardHeader
                        // title="Your all papers related to practice test"
                        titleTypographyProps={{
                            variant: "h4",
                            fontSize: '16px',
                            fontWeight: '500',
                            // textTransform: 'uppercase',
                        }}
                        action={
                            <Button to="/dashboard/practice/add-practice-test" component={Link} variant="contained" startIcon={<AddIcon />}>
                                Add Practice Test
                            </Button>
                        }
                    />
                    <Divider />
                    <CardContent style={{ padding: "0px" }}>
                        <Box width="100%" overflow="auto">
                            <StyledTable sx={{ minWidth: 1000 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ width: '50px' }}>Sr.No.</TableCell>
                                        <TableCell align="left">Type</TableCell>
                                        <TableCell align="left">Topic/Title</TableCell>
                                        <TableCell align="left">Subject</TableCell>
                                        {/* <TableCell align="center">Year</TableCell> */}
                                        <TableCell align="center" title="Nnumber Of Questions">NOQ</TableCell>
                                        <TableCell align="center">Listing Price</TableCell>
                                        <TableCell align="left">Posted Date</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center" sx={{ width: '100px' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData ? (tableData.length) ? (
                                        <>
                                            {
                                                tableData
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row, index) => (
                                                        <TableRow key={index} hover>
                                                            <TableCell align="center">{index + 1}</TableCell>
                                                            <TableCell align="left">{row.organization_type_name}</TableCell>
                                                            <TableCell align="left" title={row.topic_title}>{(row.topic_title) ? row.topic_title.replace(/(.{50})..+/, "$1…") : row.topic_title}</TableCell>
                                                            <TableCell align="left" title={row.subject_name}>{(row.subject_name) ? row.subject_name.replace(/(.{50})..+/, "$1…") : row.subject_name}</TableCell>
                                                            {/* <TableCell align="center">{row.year}</TableCell> */}
                                                            <TableCell align="center">{row.number_of_questions}</TableCell>
                                                            <TableCell align="center">{row.gst_selling_price}</TableCell>
                                                            <TableCell align="left">{Moment(row.created_at).format('DD/MM/YYYY')}</TableCell>
                                                            <TableCell title="View" align="center">
                                                                {(() => {
                                                                    switch (row.status) {
                                                                        case 1:
                                                                            return <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Approved">Approved</Small>
                                                                        case 0:
                                                                            return <Small bgcolor={bgWarning} variant="contained" size="small" sx={{ m: 1 }}>Pending</Small>
                                                                        case 2:
                                                                            return <Small bgcolor={bgError} variant="contained" size="small" sx={{ m: 1 }}>Rejected</Small>
                                                                        case 3:
                                                                            return <Small bgcolor={'#9c1515'} variant="contained" size="small" sx={{ m: 1 }}>Draft</Small>
                                                                        default:
                                                                            return null
                                                                    }
                                                                })()}
                                                            </TableCell>
                                                            <TableCell title="View" align="right">
                                                                <Link title="View Detail"
                                                                    to={`#`}
                                                                    color="warning" variant="outlined" size="small"
                                                                    onClick={() => visiblityDetailModal(row)}
                                                                >
                                                                    <RemoveRedEyeIcon color="secondary" sx={{ m: 1 }} />
                                                                </Link>
                                                                <Link title="Preview"
                                                                    to={`#`}
                                                                    color="warning" variant="outlined" size="small"
                                                                    onClick={() => visiblityPreviewModal(row)}
                                                                >
                                                                    <PreviewIcon color="secondary" sx={{ m: 1 }} />
                                                                </Link>
                                                                <Link title="Edit"
                                                                    to={`/dashboard/practice/edit-practice-test/${row.id}`}
                                                                    variant="outlined" size="small" >
                                                                    <EditIcon color="primary" sx={{ m: 1 }} />
                                                                </Link>
                                                                <Link title="Delete"
                                                                    to={`#`}
                                                                    variant="outlined" size="small"
                                                                    onClick={(e) => __rowDelete(e, row.id, row.topic_title)}
                                                                >
                                                                    <DeleteIcon color="error" />
                                                                </Link>

                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <TableRow>
                                                <TableCell align="center" colSpan={9}>
                                                    <Alert severity="error" align="center">No Data found!</Alert>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ) : <TableRow><TableCell align="center" colSpan={9}><CircularProgress /></TableCell></TableRow>}
                                </TableBody>
                            </StyledTable>
                            {(tableData && tableData.length) ?
                                <TablePagination
                                    sx={{ px: 2 }}
                                    page={page}
                                    component="div"
                                    rowsPerPage={rowsPerPage}
                                    count={tableData.length}
                                    onPageChange={handleChangePage}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                />
                                : <>

                                </>
                            }
                            <PreviewModalDialog previewModalOpen={previewModalOpen} paper={paper} setPreviewModalOpen={setPreviewModalOpen} />
                            <DetailModalDialog detailModalOpen={detailModalOpen} paper={paper} setDetailModalOpen={setDetailModalOpen} />
                            <Dialog
                                fullWidth
                                maxWidth="lg"
                            // open={open}
                            // onClose={handleClose}
                            >
                                <div>
                                    <BootstrapDialogTitle id="customized-dialog-title" >
                                        Details
                                    </BootstrapDialogTitle>
                                    <DialogContent dividers style={{ height: '550px', overflowY: 'auto' }}>
                                        {/* <Card> */}
                                        <CardContent>
                                            <Box width="100%" overflow="auto">
                                                {paper && (
                                                    <>
                                                        <Grid container>
                                                            <Grid item sm={12} xs={12} lg={8}>
                                                                <Item>Topic/Title</Item>
                                                                <ItemContent>{paper.topic_title}</ItemContent>
                                                            </Grid>
                                                            <Grid item sm={12} xs={12} lg={4}>
                                                                <Item>Status</Item>
                                                                <ItemContent>{(() => {
                                                                    switch (paper.status) {
                                                                        case 1:
                                                                            return <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Approved">Approved</Small>
                                                                        case 0:
                                                                            return <Small bgcolor={bgWarning} variant="contained" size="small" sx={{ m: 1 }}>Pending</Small>
                                                                        case 2:
                                                                            return <Small bgcolor={bgError} variant="contained" size="small" sx={{ m: 1 }}>Rejected</Small>
                                                                        case 3:
                                                                            return <Small bgcolor={'#9c1515'} variant="contained" size="small" sx={{ m: 1 }}>Draft</Small>
                                                                        default:
                                                                            return null
                                                                    }
                                                                })()}
                                                                </ItemContent>
                                                            </Grid>
                                                            <Grid item sm={12} xs={12} lg={12}>
                                                                <Divider />
                                                                <br />
                                                            </Grid>
                                                            <Grid item sm={12} xs={12} lg={4}>
                                                                <Item>School/College/University/Institute</Item>
                                                                <ItemContent>{paper.organization_type_name}</ItemContent>
                                                            </Grid>

                                                            {paper.organization_type_id && (paper.organization_type_id == 1) && (
                                                                <>
                                                                    <Grid item sm={12} xs={12} lg={4}>
                                                                        <Item>Board</Item>
                                                                        <ItemContent>{paper.board_name}</ItemContent>
                                                                    </Grid>
                                                                    <Grid item sm={12} xs={12} lg={4}>
                                                                        <Item>Class</Item>
                                                                        <ItemContent>{paper.class_name}</ItemContent>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                            {paper.organization_type_id && (paper.organization_type_id !== 1) && (
                                                                <>
                                                                    <Grid item sm={6} xs={12} lg={4}>
                                                                        <Item>Year</Item>
                                                                        <ItemContent>{paper.year}</ItemContent>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                            {paper.organization_type_id && (paper.organization_type_id == 2) && (
                                                                <>
                                                                    <Grid item sm={6} xs={12} lg={4}>
                                                                        <Item>University</Item>
                                                                        <ItemContent>{paper.university_name}</ItemContent>
                                                                    </Grid>
                                                                    <Grid item sm={6} xs={12} lg={4}>
                                                                        <Item>Course</Item>
                                                                        <ItemContent>{paper.course_name}</ItemContent>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                            {paper.organization_type_id && (paper.organization_type_id == 3) && (
                                                                <>
                                                                    <Grid item sm={6} xs={12} lg={4}>
                                                                        <Item>Entrance Exam</Item>
                                                                        <ItemContent>{paper.entrance_exam_name}</ItemContent>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                            {paper.organization_type_id && (paper.organization_type_id == 4) && (
                                                                <>
                                                                    <Grid item sm={6} xs={12} lg={4}>
                                                                        <Item>Competitive Exam</Item>
                                                                        <ItemContent>{paper.competitive_exam_name}</ItemContent>
                                                                    </Grid>
                                                                </>
                                                            )}
                                                            <Grid item sm={12} xs={12} lg={4}>
                                                                <Item>Subject</Item>
                                                                <ItemContent>{paper.subject_name}</ItemContent>
                                                            </Grid>

                                                            <Grid item sm={6} xs={12} lg={4}>
                                                                <Item>No. of Questions</Item>
                                                                <ItemContent>{paper.number_of_questions}</ItemContent>
                                                            </Grid>
                                                            <Grid item sm={6} xs={12} lg={4}>
                                                                <Item>Difficulty Level</Item>
                                                                <ItemContent>{paper.difficulty_level}</ItemContent>
                                                            </Grid>
                                                            <Grid item sm={6} xs={12} lg={4}>
                                                                <Item title="Submitted Date">Submitted Date</Item>
                                                                <ItemContent>{Moment(paper.created_at).format('DD/MM/YYYY')}</ItemContent>
                                                            </Grid>
                                                            <Grid item sm={6} xs={12} lg={4}>
                                                                <Item>Updated Date</Item>
                                                                <ItemContent>{Moment(paper.updated_at).format('DD/MM/YYYY')}</ItemContent>
                                                            </Grid>
                                                        </Grid>
                                                        <br />
                                                        <Divider />
                                                        <br />
                                                        <Grid container>
                                                            <Grid item sm={6} xs={12} lg={2}>
                                                                <Item>Listing Price</Item>
                                                                <ItemContent>{paper.price}</ItemContent>
                                                            </Grid>
                                                            <Grid item sm={6} xs={12} lg={2}>
                                                                <Item>With GST Listing Price</Item>
                                                                <ItemContent>{paper.gst_price}</ItemContent>
                                                            </Grid>
                                                            <Grid item sm={6} xs={12} lg={2}>
                                                                <Item>Discount(%)</Item>
                                                                <ItemContent>{paper.discount_percentage}</ItemContent>
                                                            </Grid>
                                                            <Grid item sm={6} xs={12} lg={2}>
                                                                <Item title="Selling Price">Selling Price</Item>
                                                                <ItemContent>{paper.selling_price}</ItemContent>
                                                            </Grid>
                                                            <Grid item sm={6} xs={12} lg={2}>
                                                                <Item title="Selling Price">With GST Selling Price</Item>
                                                                <ItemContent>{paper.gst_selling_price}</ItemContent>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider />
                                                        <br />
                                                        <Grid container>
                                                            <Grid item sm={6} xs={12} lg={4}>
                                                                <Item>Question Paper</Item>
                                                                <ItemContent style={{ padding: "46px 3px 3px 30px" }}>
                                                                    {(paper.paper_file_url) && (
                                                                        <>
                                                                            <a title='Test Paper' href={paper.paper_file_url} target='_blank'>
                                                                                <SystemUpdateAltIcon />
                                                                            </a>
                                                                        </>
                                                                    )}

                                                                </ItemContent>
                                                            </Grid>
                                                            <Grid item sm={6} xs={12} lg={4}>
                                                                <Item>Answer Paper</Item>
                                                                <ItemContent style={{ padding: "46px 3px 3px 30px" }}>
                                                                    {(paper.answer_file_url) && (
                                                                        <>
                                                                            <a title='Test Paper Answer' href={paper.answer_file_url} target='_blank'>
                                                                                <SystemUpdateAltIcon />
                                                                            </a>
                                                                        </>
                                                                    )}

                                                                </ItemContent>
                                                            </Grid>
                                                            <Grid item sm={6} xs={12} lg={4}>
                                                                <Item>Paper Thumbnail</Item>
                                                                <ItemContent><img src={paper.thumb_nail_url} style={{ width: '100px' }} /></ItemContent>
                                                            </Grid>
                                                            <Grid item sm={12} xs={12} lg={12}>
                                                                <Item>Description</Item>
                                                                <ItemContent>{paper.description}</ItemContent>
                                                            </Grid>
                                                            <br />
                                                            <Grid item sm={12} xs={12} lg={12}>
                                                                <Item>Original Owner </Item>
                                                                <ItemContent>
                                                                    <Alert severity="success" color="success">
                                                                        I am the original owner of this material!
                                                                    </Alert>
                                                                </ItemContent>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Box>
                                        </CardContent>
                                        {/* </Card> */}
                                    </DialogContent>

                                </div>
                            </Dialog>
                        </Box>
                    </CardContent>
                </Card>
            </section >
        </>

    );
};

export default PracticeMaterial;
