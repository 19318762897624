import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@mui/material';
import Input from '../../../Input/Input';
import {
  TextField, Button, Grid,
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
import axios from 'axios';

import './EditProfile.scss';
const validationSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(8, 'Old password is too short - should be 8 chars minimum.')
    .required('Listing price is required!'),
  password: Yup.string()
    .min(8, 'Old password is too short - should be 8 chars minimum.')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(8, 'Old password is too short - should be 8 chars minimum.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});
function EditLoginInfo({ userProfileInfo, open, setOpen, handleLoginUpdate }) {
  const handleClose = () => setOpen(false);
  const [formData, setFormData] = useState({
    old_password: '',
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);
  const handleShowNewPassword = () => setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);

  const handleShowConfirmPassword = () => setShowConfirmPassword((prevConfirmShowPassword) => !prevConfirmShowPassword);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setFormData(userProfileInfo);
  }, [userProfileInfo]);

  const handleFormSubmit = (values, errors) => {
    console.log('errors', errors);
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/dashboard/update-user-password', values).then((response) => {
        var data = response.data;
        if (data !== undefined) {
          if (data.status_code === 200) {
            swal({
              // title: "Done!",
              text: data.message,
              icon: "success",
              buttons: false,
              timer: 2000
            });
            setOpen(false);
          } else if (data.status_code === '401' || data.status_code === 401) {
            errors.setErrors(response.data.errors);
            swal({
              title: "Warning!",
              text: response.data.message,
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <div className="editProfile changepassword">
        <div className="editProfile__head">
          <h3>Change Password</h3>
        </div>
        <Formik
          onSubmit={(values, errors) => {
            handleFormSubmit(values, errors);
          }
          }
          initialValues={formData}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container style={{ padding: '20px' }} spacing={3}>
                <Grid item sm={12} xs={12}>
                  <label htmlFor="old_password" className='form-label'>Old Password</label>
                  <Input name="old_password"
                    size="small"
                    placeholder="Enter old password"
                    value={formData.old_password} handleChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    handleShowPassword={handleShowPassword}
                    sx={{height:"20px"}}
                  />
                  {(errors.old_password)
                    && (touched.old_password)
                    && <div style={{ color: '#ff3d57' }}>{`${errors.old_password}`}</div>}
                  <br />
                  <label htmlFor="new_password" className='form-label'>New Password</label>
                  <Input name="password"
                    size="small"
                    placeholder="Enter new password"
                    value={formData.password} handleChange={handleChange}
                    type={showNewPassword ? 'text' : 'password'}
                    handleShowPassword={handleShowNewPassword}
                    helperText={touched.password && errors.password}
                    error={Boolean(errors.password && touched.password)}
                  />
                  {(errors.password)
                    && (touched.password)
                    && <div style={{ color: '#ff3d57' }}>{`${errors.password}`}</div>}
                  <br />
                  <label htmlFor="confirmPassword" className='form-label'>Confirm Password</label>
                  <Input name="confirmPassword"
                    size="small"
                    placeholder="Enter confirm password"
                    value={formData.confirmPassword} handleChange={handleChange}
                    type={showConfirmPassword ? 'text' : 'password'}
                    handleShowPassword={handleShowConfirmPassword}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                  />
                  {(errors.confirmPassword)
                    && (touched.confirmPassword)
                    && <div style={{ color: '#ff3d57' }}>{`${errors.confirmPassword}`}</div>}
                </Grid>
              </Grid>

              <Grid container style={{ padding: '10px 20px 30px' }}>
                <Grid item sm={8} xs={12} lg={8}>
                </Grid>
                <Grid item sm={4} xs={12} lg={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    type='submit'
                  // sx={{m:1 }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>

  );
}

export default EditLoginInfo;
