import React, { useReducer, createContext, useEffect, useContext, ReactNode, useState } from "react";
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import {
  Backdrop, CircularProgress
} from '@mui/material';

import { useAuth } from './AuthContext';

const CartStateContext = createContext({
  getItemQuantity: 0,
  increaseCartQuantity: '',
  decreaseCartQuantity: '',
  removeFromCart: '',

});

export function useCart() {
  return useContext(CartStateContext);
}

const CartProvider = ({ children }) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isMiniCartOpen, setIsMiniCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cart, setCart] = useState(false);
  const [order, setOrder] = useState(false);
  const [wishlists, setWishlists] = useState(false);

  const openMiniCart = () => setIsMiniCartOpen(true);
  const closeMiniCart = () => setIsMiniCartOpen(false);

  const { isAuthenticated, auth_user_type, setOpenAuth, checkAuth, checkUserAuth } = useAuth();

  useEffect(() => {
    fetchCartData();
  }, [navigate, isAuthenticated]);

  /**
   * Fetch cart data.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const fetchCartData = async () => {
    setLoading(true);
    try {
      var apiUrl = '/api/frontend/get-user-cart';
      if (isAuthenticated && (auth_user_type === 3 || auth_user_type === "3")) {
        apiUrl = '/api/frontend/get-auth-user-cart';
      }
      axios.post(apiUrl)
        .then(response => {
          if (response.data.response_code === 200) {
            setCartItems(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
      apiUrl = '/api/frontend/get-cart-summary';
      if (isAuthenticated && (auth_user_type === 3 || auth_user_type === "3")) {
        apiUrl = '/api/frontend/get-auth-cart-summary';
      }
      axios.post(apiUrl)
        .then(response => {
          if (response.data.response_code === 200) {
            setCart(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Cart Items.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */

  const totalCartItems = cartItems.length || 0;

  /**
   * Cart Quantity.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity, 0
  );


  /**
   * Add Product in to wishlist.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  function addIntoWIshlist(product_id) {
    try {
      setLoading(true);
      axios.post('/api/frontend/add-to-wishlist', {
        product_id: product_id,
      })
        .then(response => {
          if (response.data.response_code === 200) {
            setWishlists(response.data.data);
            swal({
              title: 'Added into wishlist!',
              text: response.data.message,
              icon: "success",
              buttons: false,
              timer: 2000
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (e) {
      setLoading(false);
    }
  }


  /**
   * Add Product in to wishlist.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  function removeFormWishlist(product_id) {
    try {
      setLoading(true);
      axios.post('/api/frontend/remove-from-wishlist', {
        product_id: product_id,
      })
        .then(response => {
          if (response.data.response_code === 200) {
            setWishlists(response.data.data);
            swal({
              title: 'Removed from wishlist!',
              text: response.data.message,
              icon: "success",
              buttons: false,
              timer: 2000
            });
          } else if (response.data.response_code == 504) {
          } else {
            swal({
              text: response.data.message,
              icon: "warning",
              buttons: false,
              timer: 2000
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (e) {
      setLoading(false);
    }
  }
  /**
   * Increase Cart Quantity.
   * @param {*} id  // Product ID
   * @param {*} quantity  // Product quantity
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  function increaseCartQuantity(id, quantity, step = false) {
    try {
      setLoading(true);
      var apiUrl = '/api/frontend/update-user-cart';
      if (isAuthenticated && (auth_user_type === 3 || auth_user_type === "3")) {
        apiUrl = '/api/frontend/update-auth-user-cart';
      }
      axios.post(apiUrl, {
        row_id: id,
        quantity: quantity,
      })
        .then(response => {
          if (response.data.response_code === 200) {
            fetchCartData();
            if (step) {
              navigate('/view-cart');
            } else {
              swal({
                // title: "Added to cart!",
                text: response.data.message,
                icon: "success",
                buttons: false,
                timer: 2000
              });
            }
          } else if (response.data.response_code == 504) {
          } else {
            swal({
              // title: "Warning!",
              text: response.data.message,
              icon: "warning",
              buttons: false,
              timer: 2000
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Decrease Cart Quantity.
   * @param {*} id  // Product ID
   * @param {*} quantity  // Product quantity
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  function decreaseCartQuantity(id, quantity) {
    setCartItems(currentItems => {
      if (currentItems.find(item => item.id === id)?.quantity === 1) {
        return currentItems.filter(item => item.id !== id);
      } else {
        return currentItems.map(item => {
          if (item.id === id) {
            return { ...item, quantity: item.quantity - quantity };
          } else {
            return item;
          }
        })
      }
    })
  }

  /**
   * Remove Product item from cart.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const removeItemFromCart = (id) => {
    try {
      setLoading(false);
      var apiUrl = '/api/frontend/remove-product-from-cart';
      if (isAuthenticated && (auth_user_type === 3 || auth_user_type === '3')) {
        apiUrl = '/api/frontend/remove-auth-product-from-cart';
      }
      axios.post(apiUrl, {
        row_id: id,
      })
        .then(response => {
          if (response.data.response_code === 200) {
            fetchCartData();
            swal({
              // title: "Removed from cart!",
              text: response.data.message,
              icon: "success",
              buttons: false,
              timer: 2000
            });
          } else if (response.data.response_code == 504) {
          } else {
            swal({
              // title: "Warning!",
              text: response.data.message,
              icon: "warning",
              timer: 2000
            });
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Remove Product item from cart.
   * @param {*} id  // Product ID
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const isItemInCart = (id) => {
    try {
      if (cart) {
        return cart.cart_items.some(item => {
          if (item.id === id) {
            return true;
          }
          return false;
        });
      }
    } catch (e) {
      console.log(e);
    }
  }


  /**
   * Get Order detail from server 
   * @param {*} order_id 
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const getOrder = (order_id) => {
    setLoading(true);
    try {
      if (order_id) {
        axios.post('/api/frontend/get-order-details', {
          order_id: order_id
        })
          .then(response => {
            if (response.data.response_code === 200 || response.data.response_code === '200') {
              if (response.data.data.payment_status === 'success') {
                setOrder(response.data.data);
                navigate('/checkout/order-success/' + encodeURIComponent(order_id));
              } else {
                setOrder(response.data.data);
                navigate('/checkout/order-failed/' + encodeURIComponent(order_id));
              }
            } else {
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
            setLoading(false);
          });
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  /**
   * Load Razorpay SDK.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  /**
   * Display Razorpay.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  async function displayRazorpay(result) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    const { amount, order_id, currency } = result;

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: amount.toString(),
      currency: currency,
      name: "",
      description: result.description,
      image: `${process.env.PUBLIC_URL}/assets/images/logo.png`,
      order_id: order_id,
      handler: async function (pay_response) {
        axios.post('/api/frontend/order-payment', {
          order_creation_id: order_id,
          razorpay_payment_id: pay_response.razorpay_payment_id,
          razorpay_order_id: pay_response.razorpay_order_id,
          razorpay_signature: pay_response.razorpay_signature,
          payment_status: 'success'
        })
          .then(response => {
            if (response.data.response_code === 200) {
              if (response.data.data.order_id) {
                getOrder(response.data.data.order_id);
                setCartItems([]);
                swal({
                  text: response.data.message,
                  icon: "success",
                  buttons: false,
                  timer: 2000,
                });
              } else {
              }

            } else {
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
            setLoading(false);
          });
      },
      "modal": {
        "ondismiss": function () {
          axios.post('/api/frontend/order-payment', {
            order_creation_id: order_id,
            razorpay_order_id: order_id,
            payment_status: 'cancel'
          })
            .then(response => {
              if (response.data.response_code === 200) {
                getOrder(response.data.data.order_id);
                setCartItems([]);
                swal({
                  text: response.data.message,
                  icon: "success",
                  buttons: false,
                  timer: 2000,
                });
              } else {
              }
              setLoading(false);
            })
            .catch(function (error) {
              console.error(error);
              setLoading(false);
            });
        }
      },
      prefill: {
        name: result.name,
        email: result.email,
        contact: result.contact_number,
      },
      theme: {
        color: "#420e97",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

    /**
     * Handle failed paymet request
     */
    paymentObject.on('payment.failed', function (pay_response) {
      axios.post('/api/frontend/order-payment', {
        order_creation_id: order_id,
        razorpay_payment_id: pay_response.error.metadata.payment_id,
        razorpay_order_id: pay_response.error.metadata.order_id,
        payment_status: 'failed'
      })
        .then(response => {
          if (response.data.response_code === 200) {
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
    });

  }

  /**
   * Place order of selected products in to a Cart.
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const orderPlaced = () => {
    setLoading(true);
    try {
      axios.post('/api/frontend/create-order')
        .then(response => {
          if (response.data.response_code === 200) {
            if (response.data.data.razor_order && response.data.data.razor_order !== undefined) {
              if (response.data.data.razor_order.order_id && response.data.data.razor_order.amount) {
                //Call Razorpay Payment Getway
                displayRazorpay(response.data.data.razor_order);
              } else {
                getOrder(response.data.data.order_id);
                setCartItems([]);
                swal({
                  text: response.data.message,
                  icon: "success",
                  buttons: false,
                  timer: 2000,
                });
              }
            } else {
              getOrder(response.data.data.order_id);
              setCartItems([]);
              swal({
                text: response.data.message,
                icon: "success",
                buttons: false,
                timer: 2000,
              });
            }
          } else {
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.error(error);
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }
  /**
   * Add To cart button function first check user Signin and also check Signin User type!
   * @param {*} order_id 
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const cartCheckOut = async (product_id) => {
    // First Check auth
    if (checkAuth()) {
      if (checkUserAuth()) {
        await orderPlaced();
      } else {
        swal({
          text: 'Please login as Student to buy any material!',
          icon: 'warning',
          buttons: false,
          timer: 2000
        });
        return false;
      }
    } else {
      setOpenAuth(true);
      return false;
    }
  };

  /**
   * Add To cart button function first check user Signin and also check Signin User type!
   * @param {*} order_id 
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  const addToCart = async (product_id, step = false) => {
    if (isAuthenticated && (auth_user_type === 2 || auth_user_type === "2")) {
      swal({
        text: 'Please login as Student to buy any material!',
        icon: 'warning',
        buttons: false,
        timer: 2000
      });
      return false;
    } else {
      await increaseCartQuantity(product_id, 1, step);
    }

  };



  /**
 * Add To Wishlist button function first check user Signin and also check Signin User type!
 * @param {*} order_id 
 * @author Pavan Ji <dropmail2pavan@gmail.com>
 */
  const addToWishlist = async (product_id) => {
    // First Check auth
    if (checkAuth()) {
      if (checkUserAuth()) {
        await addIntoWIshlist(product_id);
        return true;
      } else {
        swal({
          // title: 'Warning!',
          text: 'Please login as Student to Add To Wishlist!',
          icon: 'warning',
          buttons: false,
          timer: 2000
        });
        return false;
      }
    } else {
      setOpenAuth(true);
      return false;
    }
  };
  /**
   * Rander Cart Provider
   * @param {*} order_id 2103%23HEGAN-202333
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */
  return (
    <>
      <CartStateContext.Provider value={{
        openMiniCart,
        closeMiniCart,
        cart,
        totalCartItems,
        cartQuantity,
        addToCart,
        isItemInCart,
        increaseCartQuantity,
        decreaseCartQuantity,
        removeItemFromCart,
        orderPlaced,
        order,
        setOrder,
        cartCheckOut,
        addToWishlist,
        removeFormWishlist
      }}>
        {children}
      </CartStateContext.Provider>
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CartProvider;