import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DownloadApp from './../Homepage/DownloadApp';
import {
    TextField, Button, Grid, Divider,
} from '@mui/material';
import SEO from './../../layouts/SEO';
import * as Yup from 'yup';
import { Formik } from 'formik';
import swal from 'sweetalert';
import axios from 'axios';
import addPaperImage from './../../../images/add-paper.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const validationSchema = Yup.object().shape({
    full_name: Yup.string()
        .required('Name field is required!'),
    email: Yup.string()
        .required('Email field is required!')
        .email('Enter valid email address!'),
    subject: Yup.string()
        .required('Subject field is required!'),
    message: Yup.string()
        .required("Message field is required!")
});

function ContactUs() {
    const [loading, setLoading] = useState(false);
    const initialValues = {
        full_name: '',
        email: '',
        subject: '',
        message: '',
    }

    const handleFormSubmit = (values, errors) => {
        try {
            axios.get('/sanctum/csrf-cookie').then(response => {
                axios.post('/api/frontend/save-contact-us-data', values)
                    .then(response => {
                        if (response.data.response_code === 200) {
                            swal({
                                title: "Good job!",
                                text: response.data.message,
                                icon: "success",
                                timer: 5000,
                            }).then(() => {
                                window.location.reload(false);
                            });
                        } else {
                            swal({
                                title: "Warning!",
                                text: response.data.message,
                                icon: "warning",
                            });
                            errors.setErrors(response.data.errors);
                        }
                        setLoading(false);
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            });
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }
    return (
        <>
            <SEO
                title="Contact Us – irevize"
                description="If you have any questions about our refund policy, please contact us at support@irevize.com and we will be happy to assist you.!"
                keywords="Download LEARNING MATERIAL, Download PRACTICE TESTS"
                canonical="http://irevize.com/contact-us"
            >
            </SEO>
            <div className="breadcrumb_section  page-title-mini">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <ol className="breadcrumb ">
                                <li className="breadcrumb-item"><Link to="/" title='Home'>Home</Link></li>
                                <li className="breadcrumb-item active" title='Contact Us'>Contact Us</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main_content contact-us">
                <div className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row align-items-center prod-detail">
                                    <div className="col-12">
                                        <h1 className="btn-shine text-center">Contact Us</h1>
                                        <Divider />
                                        <br />
                                        <p>
                                            If you have any questions about our refund policy, please contact us at <Link to={'mailto:support@irevize.com'}> support@irevize.com</Link> and we will be happy to assist you.
                                        </p>
                                        <br />
                                        <br />
                                        <Formik
                                            onSubmit={(values, errors) => {
                                                handleFormSubmit(values, errors);
                                            }
                                            }
                                            initialValues={initialValues}
                                            validationSchema={validationSchema}
                                        >
                                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                                <form
                                                    onSubmit={handleSubmit}>
                                                    <Grid container spacing={3}>
                                                        <Grid item sm={6} xs={12}>
                                                            <div className='contact-page-img'>
                                                                <img src={addPaperImage} alt='Add Paper' />
                                                            </div>
                                                            <Grid container style={{ padding: '30px 20px 0px' }} spacing={4}>
                                                                <Grid item sm={6} xs={12} lg={6}>
                                                                    <label>Contact Number:</label>
                                                                    <br />
                                                                    <PhoneIphoneIcon />
                                                                    <Link to={'tel:+91 9717689100'}> +91 9717689100</Link>
                                                                </Grid>
                                                                <Grid item sm={6} xs={12} lg={6}>
                                                                    <label>Contact Email:</label>
                                                                    <br />
                                                                    <MarkEmailReadIcon />
                                                                    <Link to={'mailto:support@irevize.com'}> support@irevize.com</Link>
                                                                </Grid>
                                                                <Grid item sm={6} xs={12} lg={12}>
                                                                    <Divider />
                                                                    <br />
                                                                    <label>Address:</label>
                                                                    <br />
                                                                    <LocationOnIcon /> A-32b, Top floor, Vishwakarma colony, New Delhi - 110044
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sm={6} xs={12}>
                                                            <div className='contact-form'>
                                                                <label htmlFor="full_name" className='form-label'>
                                                                    Full Name: <span className='req-star'>*</span>
                                                                </label>
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    type="text"
                                                                    name="full_name"
                                                                    variant="outlined"
                                                                    onBlur={handleBlur}
                                                                    value={values.full_name}
                                                                    onChange={handleChange}
                                                                    helperText={touched.full_name && errors.full_name}
                                                                    error={Boolean(errors.full_name && touched.full_name)}
                                                                    sx={{ mb: 2 }}
                                                                />

                                                                <label htmlFor="discount_percentage" className='form-label'>
                                                                    Email Address: <span className='req-star'>*</span>
                                                                </label>
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    type="email"
                                                                    name="email"
                                                                    variant="outlined"
                                                                    onBlur={handleBlur}
                                                                    value={values.email}
                                                                    onChange={handleChange}
                                                                    helperText={touched.email && errors.email}
                                                                    error={Boolean(errors.email && touched.email)}
                                                                    sx={{ mb: 2 }}
                                                                />
                                                                <label htmlFor="subject" className='form-label'>
                                                                    Subject: <span className='req-star'>*</span>
                                                                </label>
                                                                <TextField
                                                                    fullWidth
                                                                    size="small"
                                                                    type="text"
                                                                    name="subject"
                                                                    variant="outlined"
                                                                    onBlur={handleBlur}
                                                                    value={values.subject}
                                                                    onChange={handleChange}
                                                                    helperText={touched.subject && errors.subject}
                                                                    error={Boolean(errors.subject && touched.subject)}
                                                                    sx={{ mb: 2 }}
                                                                />
                                                                <label htmlFor="description" className='form-label'>
                                                                    Message: <span className='req-star'>*</span>
                                                                </label>
                                                                <TextField
                                                                    fullWidth
                                                                    multiline={true}
                                                                    minRows={2}
                                                                    size="small"
                                                                    type="text"
                                                                    name="message"
                                                                    variant="outlined"
                                                                    onBlur={handleBlur}
                                                                    value={values.message}
                                                                    onChange={handleChange}
                                                                    helperText={touched.message && errors.message}
                                                                    error={Boolean(errors.message && touched.message)}
                                                                    sx={{ mb: 2 }}
                                                                />
                                                                <Grid container spacing={4}>
                                                                    <Grid item sm={4} xs={3} lg={4}>
                                                                    </Grid>
                                                                    <Grid item sm={4} xs={6} lg={4}>
                                                                        <Button
                                                                            fullWidth
                                                                            disabled={loading}
                                                                            variant="contained"
                                                                            type='submit'
                                                                            sx={{ mb: 2, mt: 4 }}
                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item sm={4} xs={3} lg={4}>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />

                {/* <DownloadApp /> */}
            </div>
        </>
    );
}
export default ContactUs;
