import React from 'react';
import { Link } from 'react-router-dom';
import SEO from './../../layouts/SEO';
import DownloadApp from './../Homepage/DownloadApp';

function PrivacyPolicy() {
  return (
    <>
      <SEO
        title="Privacy Policy – irevize"
        description="iRevize Services Pvt. Ltd. (“us”, “we”, or “our”) operates www.irevize.com. Our Privacy Policy governs your use of our Service and explains how we collect, protect, and use the information that you provide to us."
        keywords="Privacy Policy,Privacy, Policy"
        canonical="http://irevize.com/privacy-policy"
      >
      </SEO>
      <div className="breadcrumb_section  page-title-mini">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="/" title='Home'>Home</Link></li>
                <li className="breadcrumb-item active" title='Privacy Policy'>Privacy Policy</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content">
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row align-items-center prod-detail" style={{ textAlign: 'justify' }}>
                  <div className="col-12">
                    <h1 className="btn-shine text-center">Privacy Policy</h1>
                    <p>
                      iRevize Services Pvt. Ltd. (“us”, “we”, or “our”) operates www.irevize.com. Our Privacy Policy governs your use of our Service and explains how we collect, protect, and use the information that you provide to us.
                    </p>
                    <h2 className="btn-shine mt-30">Information Collection and Use</h2>
                    <p>
                      We collect information from you when you register on our site, place an order, or fill out a form. The information we collect may include, but is not limited to, your name, email address, mailing address, phone number, and payment information.
                    </p>
                    <p>
                      We use the information we collect from you to process your orders, provide customer service, and improve our Service. We may also use the information to send you promotional materials or to contact you for market research purposes.
                    </p>

                    <h2 className="btn-shine mt-30">Information Sharing and Disclosure</h2>
                    <p>
                      We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent.
                      We may share your personal information with third parties for the following reasons:
                    </p>
                    <div className="mt-30 prodbgbox">
                      <ul className="prodbgbox mt-30">
                        <li>To provide you with the products or services you have requested</li>
                        <li>To fulfill the purpose for which you provided the information</li>
                        <li>To comply with legal requirements, such as a law, regulation, search warrant, subpoena, or court order</li>
                      </ul>
                    </div>

                    <h2 className="btn-shine mt-30">Security</h2>
                    <p>
                      We take the security of your personal information seriously. We use a variety of security measures, including encryption and authentication tools, to maintain the safety of your personal information.
                    </p>
                    <h2 className="btn-shine mt-30">Changes to Our Privacy Policy</h2>
                    <p>
                      We may update our Privacy Policy from time to time. If we make changes to our policy, we will post the updated policy on this page.
                    </p>
                    <h2 className="btn-shine mt-30">Contact Us</h2>
                    <p>
                      If you have any questions or concerns about our Privacy Policy, please contact us at <Link to={'mailto:support@irevize.com'}> support@irevize.com</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <DownloadApp /> */}
      </div>
    </>
  );
}
export default PrivacyPolicy;
