import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Header from '../../components/Dashboard/Header';
import SideNav from '../../components/Dashboard/SideNav';
import ContractAgreement from './../../components/Dashboard/ContractAgreement';

import Dashboard from './../dashboard/Dashboard';
import LearningMaterial from './../dashboard/LearningMaterial/LearningMaterial';
import AddLearningMaterial from './../dashboard/LearningMaterial/AddLearningMaterial';
import EditLearningMaterial from './../dashboard/LearningMaterial/EditLearningMaterial';
import PracticeMaterial from './../dashboard/PracticeMaterial/PracticeMaterial';
import AddPracticeMaterial from './../dashboard/PracticeMaterial/AddPracticeMaterial';
import EditPracticeMaterial from './../dashboard/PracticeMaterial/EditPracticeMaterial';
import OrderHistory from './../dashboard/OrderHistory/OrderHistory';
import OrderDetails from './../dashboard/OrderHistory/OrderDetails';
import OrderDetail from './../dashboard/OrderHistory/OrderDetail';
import CurrentSale from './../dashboard/Earning/CurrentSale';
import WithdrawableSale from './../dashboard/Earning/WithdrawableSale';
import WithdrawableRequested from './../dashboard/Earning/WithdrawableRequested';
import RefundsRequested from './../dashboard/Refunds/RefundsRequested';
import ViewRefundRequested from './../dashboard/Refunds/ViewRefundRequested';




import TotalSale from './../dashboard/Earning/TotalSale';

import Wishlists from './../dashboard/Wishlists';


import ProfileSettings from './../dashboard/ProfileSettings/ProfileSettings';
import HelpVideos from './../dashboard/HelpVideos/HelpVideos';
import StudentDashboard from './../dashboard/StudentDashboard';
import Supports from './../dashboard/Supports/Supports';
import AddTicket from './../dashboard/Supports/AddTicket';
import ViewTicket from './../dashboard/Supports/ViewTicket';
import Refunds from './../dashboard/Refunds/Refunds';
import AddRefund from './../dashboard/Refunds/AddRefund';
import ViewRefund from './../dashboard/Refunds/ViewRefund';


import { useAuth } from './../../contexts/AuthContext';

// import './../dashboard/assets/vendor/bootstrap/css/bootstrap.min.css';
import './../dashboard/assets/vendor/bootstrap-icons/bootstrap-icons.css';
// import './../dashboard/assets/vendor/simple-datatables/style.css';

import './../dashboard/assets/css/style.css';

const DashboardLayout = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSideNav, setOpenSideNav] = useState((window.innerWidth > 380 ? true : false));

  useEffect(() => {
    var currUser = JSON.parse(localStorage.getItem('profile'));
    if (currUser) {
      if ((currUser.auth_type === 2 || currUser.auth_type === "2") &&
        (!currUser.contract_agree)) {
        setOpen(true);
      }
    } else {
      navigate('/');
    }
  }, [location]);

  useEffect(() => {
    //Left menu open 
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setOpenSideNav(true);
      } else {
        setOpenSideNav(false);
      }
    };
    window.addEventListener('resize', handleResize);
  }, [window.innerWidth]);

  return (
    <>
      {isAuthenticated && (
        <div className="dash-layout">
          <Header openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} />
          <SideNav openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} />
          <main id="main" className="main">
            {children}
            {user && ((user.auth_type === 2 || user.auth_type === '2') ? (
              <>
                <Routes>
                  <Route exact path="/" element={<Dashboard />} />
                  <Route exact path="learning/learning-materials" element={<LearningMaterial />} />
                  <Route exact path="learning/add-learning-material" element={<AddLearningMaterial />} />
                  <Route exact path="learning/edit-learning-material/:row_id" element={<EditLearningMaterial />} />
                  <Route exact path="learning/add-learning-material/:step" element={<AddLearningMaterial />} />
                  <Route exact path="practice/add-practice-test" element={<AddPracticeMaterial />} />
                  <Route exact path="practice/edit-practice-test/:row_id" element={<EditPracticeMaterial />} />
                  <Route exact path="practice/practice-tests" element={<PracticeMaterial />} />
                  <Route exact path="order/order-history" element={<OrderHistory />} />
                  <Route exact path="order/order-history/details/:order_id" element={<OrderDetails />} />
                  <Route exact path="order/order-history/detail/:row_id" element={<OrderDetail />} />
                  {/* Earning */}
                  <Route exact path="earning/current-sale" element={<CurrentSale />} />
                  <Route exact path="earning/withdrawable-sale" element={<WithdrawableSale />} />
                  <Route exact path="earning/withdrawable-requested" element={<WithdrawableRequested />} />
                  <Route exact path="earning/total-sale" element={<TotalSale />} />

                  <Route exact path="profile/profile-settings" element={<ProfileSettings />} />
                  <Route exact path="refunds-requested" element={<RefundsRequested />} />
                  <Route exact path="refunds-requested/view-refund/:refund_id" element={<ViewRefundRequested />} />
                  
                  <Route exact path="supports" element={<Supports />} />
                  <Route exact path="support/add-ticket" element={<AddTicket />} />
                  <Route exact path="support/view-ticket/:ticket_id" element={<ViewTicket />} />
                  <Route exact path="help/videos" element={<HelpVideos />} />
                </Routes>
                <ContractAgreement open={open} setOpen={setOpen} />
              </>
            ) : (
              <>
                <Routes>
                  <Route exact path="/" element={<StudentDashboard />} />
                  <Route exact path="order/order-history" element={<OrderHistory />} />
                  <Route exact path="wishlists" element={<Wishlists />} />
                  <Route exact path="order/order-history/details/:order_id" element={<OrderDetails />} />
                  <Route exact path="order/order-history/detail/:row_id" element={<OrderDetail />} />
                  <Route exact path="profile/profile-settings" element={<ProfileSettings />} />
                  <Route exact path="refunds" element={<Refunds />} />
                  <Route exact path="refunds/add-refund" element={<AddRefund />} />
                  <Route exact path="refunds/view-refund/:refund_id" element={<ViewRefund />} />
                </Routes>
              </>
            ))}
          </main>
          <footer id="footer" className="footer">
            <div className="copyright">
              © 2022-23 iRevize. All Rights Reserved
            </div>
          </footer>
        </div>
      )}

    </>
  );
};
export default DashboardLayout;