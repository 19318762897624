import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import {
  Card,
  Divider,
  CardContent,
  CardHeader,
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Alert,
  useTheme,
} from "@mui/material";
import PreviewIcon from '@mui/icons-material/Preview';
import CloseIcon from '@mui/icons-material/Close';

import Moment from 'moment';

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));

const StyledTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  // '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  '& th': { background: "#003152", color: "#FFF" },
}));

const CurrentSale = () => {

  const { palette } = useTheme();
  const bgError = palette.error.main;
  const bgPrimary = palette.primary.main;
  const bgSecondary = palette.secondary.main;
  const bgSuccess = palette.success.main;
  const bgWarning = palette.warning.main;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [tableData, setTableData] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-current-sale').then((response) => {
          var data = response.data;
          if (data !== undefined) {
            if (data.response_code === 200) {
              setTableData(data.data);
            } else if (data.response_code === '401') {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            } else {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            }
          }
        });
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="pagetitle">
        <h1>Current Sale</h1>
      </div>
      <section className="section dashboard">
        <Card>
          <CardHeader
            title="Last 14 days user ordered lists"
            titleTypographyProps={{
              variant: "h4",
              fontSize: '16px',
              fontWeight: '500',
            }}
          />
          <Divider />
          <CardContent style={{ padding: "0px" }}>
            <Box width="100%" overflow="auto">
              <StyledTable sx={{ minWidth: 1000 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ width: '50px' }}>Sr.No.</TableCell>
                    <TableCell align="center">Order ID</TableCell>
                    <TableCell align="left">Topic Title</TableCell>
                    <TableCell align="center" title="Base Price">Base Price</TableCell>
                    <TableCell align="center" title="Base Price">Selling Price</TableCell>
                    <TableCell align="center">Commission @ 70%</TableCell>
                    <TableCell align="center" title="Total Income">Total Income</TableCell>
                    <TableCell align="center" title="Income Status">Income Status</TableCell>
                    <TableCell align="center">Order Date</TableCell>
                    {/* <TableCell align="center" sx={{ width: '100px' }}>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData ? (tableData.length) ? (
                    <>
                      {
                        tableData
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => (
                            <TableRow key={index} hover>
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell align="center">
                                <Link title="Order Details"
                                  to={`/dashboard/order/order-history/detail/${row.id}`}
                                  color="warning" variant="outlined" size="small"
                                >
                                  {row.hash_order_id}
                                </Link>
                              </TableCell>
                              <TableCell align="left" title={row.topic_title}>
                                {row.topic_title.replace(/(.{30})..+/, "$1…")}
                              </TableCell>
                              <TableCell align="center">{process.env.REACT_APP_CURRENCY}. {row.total_selling_price}</TableCell>
                              <TableCell align="center">{process.env.REACT_APP_CURRENCY}. {row.gst_total_selling_price}</TableCell>
                              <TableCell align="center">{process.env.REACT_APP_CURRENCY}. {row.user_income}</TableCell>
                              <TableCell align="center">{process.env.REACT_APP_CURRENCY}. {row.user_gst_income}</TableCell>

                              <TableCell title="View" align="center">
                                {(row.total_selling_price) ? (
                                  (() => {
                                    switch (row.status) {
                                      case 'success':
                                        return <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Success">Success</Small>
                                      case 'pending':
                                        return <Small bgcolor={bgWarning} variant="contained" size="small" sx={{ m: 1 }}>Pending</Small>
                                      case 'failed':
                                        return <Small bgcolor={bgError} variant="contained" size="small" sx={{ m: 1 }}>Failed</Small>
                                      default:
                                        return null
                                    }
                                  })()
                                ) : (
                                  <>
                                    <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Free">Free</Small>
                                  </>
                                )}
                              </TableCell>
                              <TableCell align="center">{Moment(row.created_at).format('DD/MM/YYYY')}</TableCell>
                              {/* <TableCell title="View" align="right">
                                <Link title="Preview"
                                  to={`/dashboard/order/order-history/details/${row.order_id}`}
                                  color="warning" variant="outlined" size="small"
                                >
                                  <PreviewIcon color="secondary" sx={{ m: 1 }} />
                                </Link>
                              </TableCell> */}
                            </TableRow>
                          ))
                      }
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell align="center" colSpan={10}>
                          <Alert severity="error" align="center">No Data found!</Alert>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : <TableRow><TableCell align="center" colSpan={10}>
                    {/* <CircularProgress /> */}
                  </TableCell></TableRow>}
                </TableBody>
              </StyledTable>
              {(tableData && tableData.length) ?
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={tableData.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                />
                : <>

                </>
              }
            </Box>
          </CardContent>
        </Card >
      </section >
    </>

  );
};

export default CurrentSale;
