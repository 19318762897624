import React, { createContext, useEffect, useContext, useState } from "react";
import { useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import {
  Backdrop, CircularProgress
} from '@mui/material';

import { useAuth } from './AuthContext';

const SearchFilterStateContext = createContext({
  selected_type: '',
  q: '',
  selected_organization: '',
  selected_school: '',
  selected_class: '',
  selected_board: '',
  selected_university: '',
  selected_entrance_exam: '',
  selected_competitive_exam: '',
  selected_subjects: [],
  selected_rating: '',
  selected_class: '',
});

export function useSearchFilter() {
  return useContext(SearchFilterStateContext);
}

const SearchFilterProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [filterOptions, setFilterOptions] = useState({
    selected_type: '',
    q: ((searchParams.get("q")) ? searchParams.get("q") : ''),
    selected_organization: ((searchParams.get("selected_organization")) ? parseInt(searchParams.get("selected_organization")) : ''),
    selected_school: ((searchParams.get("selected_school")) ? parseInt(searchParams.get("selected_school")) : ''),
    selected_class: ((searchParams.get("selected_class")) ? parseInt(searchParams.get("selected_class")) : ''),
    selected_board: ((searchParams.get("selected_board")) ? parseInt(searchParams.get("selected_board")) : ''),
    selected_university: ((searchParams.get("selected_university")) ? parseInt(searchParams.get("selected_university")) : ''),
    selected_entrance_exam: ((searchParams.get("selected_entrance_exam")) ? parseInt(searchParams.get("selected_entrance_exam")) : ''),
    selected_competitive_exam: ((searchParams.get("selected_competitive_exam")) ? parseInt(searchParams.get("selected_competitive_exam")) : ''),
    selected_subjects: ((searchParams.get("selected_subjects")) ? [parseInt(searchParams.get("selected_subjects"))] : []),
    selected_rating: ((searchParams.get("selected_rating")) ? parseInt(searchParams.get("selected_rating")) : ''),
  });

  const [filteTableData, setFilteTableData] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('/search/')) {
      setFilteTableData(false);
      /**
       * Get Left Filter lists.
       * @author Pavan Ji <dropmail2pavan@gmail.com>
       */
      fetchFilteredProduct();

    }
  }, [filterOptions]);

  useEffect(() => {
    /**
     * Uee effect for search to change selected filter values.
     * @author Pavan Ji <dropmail2pavan@gmail.com>
     */
    setFilteTableData(false);
    var updateData = {
      selected_type: '',
      q: '',
      selected_organization: '',
      selected_school: '',
      selected_class: '',
      selected_board: '',
      selected_entrance_exam: '',
      selected_competitive_exam: '',
      selected_subjects: [],
      selected_rating: '',
    }

    if (searchParams.get("selected_type")) {
      updateData.selected_type = searchParams.get("selected_type");
    }
    if (searchParams.get("q")) {
      updateData.q = searchParams.get("q");
    }
    if (searchParams.get("selected_school")) {
      updateData.selected_school = parseInt(searchParams.get("selected_school"));
    }
    if (searchParams.get("selected_organization")) {
      updateData.selected_organization = parseInt(searchParams.get("selected_organization"));
    }
    if (searchParams.get("selected_class")) {
      updateData.selected_class = parseInt(searchParams.get("selected_class"));
    }
    if (searchParams.get("selected_board")) {
      updateData.selected_board = parseInt(searchParams.get("selected_board"));
    }
    if (searchParams.get("selected_entrance_exam")) {
      updateData.selected_entrance_exam = parseInt(searchParams.get("selected_entrance_exam"));
    }
    if (searchParams.get("selected_competitive_exam")) {
      updateData.selected_competitive_exam = parseInt(searchParams.get("selected_competitive_exam"));
    }
    if (searchParams.get("selected_subjects")) {
      var selected_subjects_up = [];
      selected_subjects_up.push(parseInt(searchParams.get("selected_subjects")));
      updateData.selected_subjects = selected_subjects_up;
    }
    if (searchParams.get("selected_rating")) {
      updateData.selected_rating = parseInt(searchParams.get("selected_rating"));
    }
    setFilterOptions(updateData);

  }, [location, isAuthenticated]);

  /**
   * Fetching data from server.
   */
  const fetchFilteredProduct = async (pageNumber = 1) => {
    setLoading(true);
    try {
      var api_url = '/api/frontend/get-search-papers';
      if (isAuthenticated) {
        api_url = '/api/frontend/get-auth-search-papers';
      }
      axios.post(api_url, {
        page: pageNumber,
        selected_type: filterOptions.selected_type,
        q: filterOptions.q,
        selected_organization: filterOptions.selected_organization,
        selected_board: filterOptions.selected_board,
        selected_school: filterOptions.selected_school,
        selected_class: filterOptions.selected_class,
        selected_university: filterOptions.selected_university,
        selected_entrance_exam: filterOptions.selected_entrance_exam,
        selected_competitive_exam: filterOptions.selected_competitive_exam,
        selected_subjects: filterOptions.selected_subjects,
        selected_rating: filterOptions.selected_rating,
      })
        .then(response => {
          if (response.data.response_code === 200) {
            window.scrollTo(0, 0);
            setFilteTableData(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * RanderSearch Filter Provider
   * @param {*} order_id 2103%23HEGAN-202333
   * @author Pavan Ji <dropmail2pavan@gmail.com>
   */

  return (
    <>
      <SearchFilterStateContext.Provider value={{
        filterOptions,
        setFilterOptions,
        filteTableData,
        fetchFilteredProduct,
      }}>
        {children}
      </SearchFilterStateContext.Provider>
      <Backdrop
        sx={{ color: '#015998', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={setLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SearchFilterProvider;