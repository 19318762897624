import React from 'react';
import { Link } from 'react-router-dom';
import BannerImage from './../assets/images/practise-makes-everyone.png';
import BannerImage2 from './../assets/images/experts.png';
// import BannerImage3 from './../assets/images/banner3.png';
// import BannerImage3 from './../assets/images/_i_banner.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function HomeBanner() {
  const options = {
    nav: true,
    dots: false,
    // autoplay: true,
    loop: true,
    margin: 0,
    navText: ['<i class="ion-chevron-left"></i>', '<i class="ion-chevron-right"></i>'],
    responsiveClass: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      }
    },
  };
  return (
    <>
      <div className="banner_section home_banner slide_medium shop_banner_slider staggered-animation-wrap">
        <OwlCarousel className='carousel slide owl-carousel owl-theme'
          {...options}>
          <div className="item carousel-inner">
            <img src={BannerImage} alt="Banner Inage" className="d-block" style2={{ margin: '0px auto', width: 'auto' }} />
          </div>
          <div className="item carousel-inner">
            <img src={BannerImage2} alt="Banner Inage" className="d-block" style2={{ margin: '0px auto', width: 'auto' }} />
          </div>
        </OwlCarousel>
      </div >
    </>
  );
}
export default HomeBanner;
