import { AUTH, LOGOUT } from '../../constants/actionTypes';
import swal from 'sweetalert';

const authReducer = (state = { authData: null }, action) => {
    switch (action.type) {
        case AUTH:
            if (action.data.status_code === 200) {
                localStorage.setItem('profile', JSON.stringify({ ...action.data.data }));
                return { ...state, authData: action.data };
            } else {
                return action.data;
            }
        case LOGOUT:
            localStorage.clear();
            swal({
                title: "Done!",
                text: "You are successfully logged out!",
                icon: "success",
                timer: 1000,
            }).then(() => {
                
            });
            return { ...state, authData: null };
        default:
            return state;
    }
};

export default authReducer;
