import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Grid, Checkbox, Box,
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import addPaperImage from './../../../images/add-paper.png';

const validationSchema = Yup.object().shape({
  price: Yup.number()
    .nullable()
    .moreThan(-1, "Price cannot be negative!")
    .max(99999, 'Price up to 5-Digits!')
    // .test('len', 'Price up to 5-Digits', (val) => (val) ? (val.toString().length <= 5) : false)
    .transform((_, val) => (val !== "" ? Number(val) : null)),

  discount_percentage: Yup.number()
    .nullable()
    .moreThan(-1, "Discount percentage cannot be negative!")
    .max(99, 'Discount percentage up to 99%!')
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  // .required('Discount percentage is required!'),
  selling_price: Yup.number()
    .nullable()
    .moreThan(-1, "Selling price cannot be negative")
    .max(999999, 'Selling price up to 5-Digits!')
    // .test('len', 'Selling price up to 6-Digits', (val) => (val) ? (val.toString().length <= 6) : false)
    .transform((_, val) => (val !== "" ? Number(val) : null)),
  // .required('Selling price is required!'),
  material_owner: Yup.boolean()
    .required("Please check original owner of this material!")
    .oneOf([true], "Please check original owner of this material!")
});
// const validationSchema = Yup.object().shape({
//   price: Yup.number()
//     // .typeError("GPA must be a number")
//     .nullable()
//     .moreThan(-1, "Price cannot be negative")
//     .test('len', 'Price up to 5-Digits', (val) => val.toString().length <= 5)
//     .transform((_, val) => (val !== "" ? Number(val) : null)),

//   // Yup.string()
//   //   .positive('Please enter positive number!')
//   //   .required('Listing price is required!'),
//   discount_percentage: Yup.string()
//     // .positive('Please enter positive number!')
//     .required('Discount percentage is required!'),
//   selling_price: Yup.string()
//     // .positive('Please enter positive number!')
//     .required('Selling price is required!'),
//   material_owner: Yup.boolean()
//     .required("Please check original owner of this material!")
//     .oneOf([true], "Please check original owner of this material!")
// });

const UploadQuestionPaper = (props) => {
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (values, errors) => {
    props.next(values, true);
  }

  const handleSaveAsDraftSubmit = (values) => {
    props.saveAsDraftSubmit(values);
  }

  return (
    <>
      <Formik
        onSubmit={(values, errors) => {
          handleFormSubmit(values, errors);
        }
        }
        initialValues={props.formData}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Grid container style={{ padding: '20px' }} spacing={3}>
              <Grid item sm={6} xs={12}>
                <div className='price-detail-left'>
                  <label htmlFor="price" className='form-label'>Listing Price (Exclusive GST)</label>
                  <br />
                  <small>IF Price is 0 then course is free!</small>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    name="price"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.price}
                    // onChange={handleChange}
                    onChange={(e) => {
                      handleChange(e)
                      var price = (e.target.value) ? parseInt(e.target.value) : 0;
                      var discount_percentage = (values.discount_percentage) ? parseInt(values.discount_percentage) : 0;
                      var selling_price = Math.round((price - ((price * discount_percentage) / 100)));
                      setFieldValue("selling_price", selling_price);
                      // var gst = (price * 18 / 100);
                      // console.log(Math.round((gst + price).toFixed(2)));
                      var gst_price = Math.round((price * 18 / 100) + price);
                      setFieldValue("gst_price", gst_price);
                      var gst_selling_price = Math.round((selling_price * 18 / 100) + selling_price);
                      setFieldValue("gst_selling_price", gst_selling_price);

                    }}
                    helperText={touched.price && errors.price}
                    error={Boolean(errors.price && touched.price)}
                    inputProps={{ maxLength: 10 }}
                    sx={{ mb: 3 }}
                  />
                  <br />
                  <label htmlFor="price" className='form-label'>Listing Price (18% Inclusive GST)</label>
                  <TextField
                    fullWidth
                    disabled
                    size="small"
                    type="number"
                    name="gst_price"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.gst_price}
                    onChange={handleChange}
                    helperText={touched.gst_price && errors.gst_price}
                    error={Boolean(errors.gst_price && touched.gst_price)}
                    sx={{ mb: 3 }}
                  />
                  <br />
                  <label htmlFor="discount_percentage" className='form-label'>Discount percentage</label>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    name="discount_percentage"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.discount_percentage}
                    // onChange={handleChange}
                    onChange={(e) => {
                      handleChange(e)
                      var discount_percentage = (e.target.value) ? parseInt(e.target.value) : 0;
                      var price = (values.price) ? parseInt(values.price) : 0;
                      var selling_price = Math.round((price - ((price * discount_percentage) / 100)));
                      setFieldValue("selling_price", selling_price);
                      var gst_price = Math.round((price * 18 / 100) + price);
                      setFieldValue("gst_price", gst_price);
                      var gst_selling_price = Math.round((selling_price * 18 / 100) + selling_price);
                      setFieldValue("gst_selling_price", gst_selling_price);
                    }}
                    helperText={touched.discount_percentage && errors.discount_percentage}
                    error={Boolean(errors.discount_percentage && touched.discount_percentage)}
                    sx={{ mb: 3 }}
                  />
                  <br />
                  <label htmlFor="selling_price" className='form-label'>Selling Price  (Exclusive GST)</label>
                  <TextField
                    fullWidth
                    disabled
                    size="small"
                    type="number"
                    name="selling_price"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.selling_price}
                    onChange={handleChange}
                    helperText={touched.selling_price && errors.selling_price}
                    error={Boolean(errors.selling_price && touched.selling_price)}
                    sx={{ mb: 3, _webkitTextFillColor: '#000' }}
                    style={{ color: '#000' }}
                  />
                  <br />
                  <label htmlFor="selling_price" className='form-label'>Selling Price (18% Inclusive GST)</label>
                  <TextField
                    fullWidth
                    disabled
                    size="small"
                    type="number"
                    name="gst_selling_price"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.gst_selling_price}
                    onChange={handleChange}
                    helperText={touched.gst_selling_price && errors.gst_selling_price}
                    error={Boolean(errors.gst_selling_price && touched.gst_selling_price)}
                    sx={{ mb: 3, _webkitTextFillColor: '#000' }}
                    style={{ color: '#000' }}
                  />
                  <Box gap={1} alignItems="center">
                    <Checkbox
                      size="small"
                      name="material_owner"
                      onChange={handleChange}
                      value={values.material_owner}
                      checked={values.material_owner}
                      sx={{ padding: 0 }}
                    />
                    <span style={{ padding: "0px 10px" }}>
                      I am the original owner of this material.
                    </span>
                  </Box>
                  {(errors.material_owner && (errors.material_owner)
                    && (touched.material_owner)
                    && (
                      <>
                        <p style={{ color: '#ff3d57' }}>{`${errors.material_owner}`}</p>
                      </>
                    ))}
                </div>
              </Grid>

              <Grid item sm={6} xs={12}>
                <img src={addPaperImage} alt='Add Paper' />
              </Grid>
            </Grid>

            <Grid container style={{ padding: '30px 20px 0px' }} spacing={4}>
              <Grid item sm={6} xs={12} lg={2}>
                <Button
                  color="inherit"
                  variant="contained"
                  type='button'
                  onClick={() => props.prev(values)}
                  sx={{ mb: 2, mt: 4 }}
                >
                  <NavigateBeforeIcon />  Back
                </Button>
              </Grid>
              <Grid item sm={6} xs={12} lg={4}>
              </Grid>
              <Grid item sm={4} xs={12} lg={3}>
                <Button
                  fullWidth
                  disabled={loading}
                  variant="contained"
                  type='button'
                  sx={{ mb: 2, mt: 4 }}
                  onClick={() => handleSaveAsDraftSubmit(values)}
                >
                  Save as Draft
                </Button>
              </Grid>
              <Grid item sm={4} xs={12} lg={3}>
                <Button
                  fullWidth
                  disabled={loading}
                  variant="contained"
                  type='submit'
                  sx={{ mb: 2, mt: 4 }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>

  );
}

export default UploadQuestionPaper;
