import React from 'react';
import { Link } from 'react-router-dom';
import LandingBanner from './LandingBanner';
import { useAuth } from './../../../contexts/AuthContext';
import SEO from './../../layouts/SEO';

function TeachOnIrevizePage() {

  const { setOpenAuth, setIsSignUp, auth_user_type, setUserType } = useAuth();
  const handleOpen = (authState) => {
    if (!auth_user_type) {
      setIsSignUp(true);
      setOpenAuth(true)
      setUserType(2);
    }
  }

  return (
    <>
      <SEO
        title="Teach On Irevize – irevize"
        description="Publish the Notes/Learning material/Worksheets on the topics you are an expert in while retaining ownership of your content."
        keywords="Sell Learning Material,Sell Sample Papers,Become well-known"
        canonical="http://irevize.com/teach-on-irevize"
      >
      </SEO>
      <LandingBanner />
      <div className="main_content">

        <div className="section midetreescn">
          <div className="container">
            <h3 className="btn-shine text-center">Lots of reasons to begin</h3>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="midtalk">
                  <img src={process.env.PUBLIC_URL + '/assets/images/teach.png'} alt="Teach Icon"/>
                  <h4>Sell Learning Material</h4>
                  <p>Publish the Notes/Learning material/Worksheets on the topics you are an expert in while
                    retaining ownership of your content.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="midtalk">
                  <img src={process.env.PUBLIC_URL + '/assets/images/learner.png'} alt="Learner Icon"/>
                  <h4>Sell Sample Papers</h4>
                  <p>Sell sample papers in subjects you're an expert in and earn ten times your income.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="midtalk">
                  <img src={process.env.PUBLIC_URL + '/assets/images/rewards.png'} alt="Reward Icon"/>
                  <h4>Become well-known</h4>
                  <p>By sharing your knowledge on iRevize, you not only earn 70% of each sale but also gain
                    popularity across the country.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section text-center countinner">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-2">
                <div className="countbox">
                  <h4>58M</h4>
                  <p>Audience</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="countbox">
                  <h4>70%</h4>
                  <p>Profit</p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="countbox">
                  <h4><i class="bi bi-currency-rupee"></i>0</h4>
                  <p>Signup Cost</p>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">

            <div className="col-md-12">
              <div className="smltabsctn">

                <h3 className="btn-shine text-center">Steps to sell on iRevize.com</h3>

                <ul className="nav nav-tabs nav-fill lowconter" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                      data-bs-target="#home" type="button" role="tab" aria-controls="home"
                      aria-selected="true">Upload Material</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                      type="button" role="tab" aria-controls="profile" aria-selected="false">Set Selling
                      Price</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact"
                      type="button" role="tab" aria-controls="contact" aria-selected="false">Let student
                      buy it</button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                    <div className="row">
                      <div className="col-md-6">
                        <p>Choose the subject in which you are an expert and start creating materials
                          related to that subject/topic.</p>
                        <p>Create your material in PDF or Word format, or take a picture of handwritten
                          material using your phone.</p>
                        <h5>Here’s how we help</h5>
                        <p>We will assist you throughout your onboarding journey. If you need any help,
                          please send an email to our support team at support@irevize.com, and our
                          team will promptly get in touch with you.</p>
                      </div>
                      <div className="col-md-6">
                        <img src="assets/images/plan-curriculum.jpg" alt="Plan Icon"/>
                      </div>
                    </div>

                  </div>
                  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                    <div className="row">
                      <div className="col-md-6">
                        <p>Set the price at which you wish to sell your materials and earn 70% of the
                          selling price.</p>
                        <p>Make sure you offer some discounts on your materials, as products with
                          discounts are sold more compared to products without discounts.</p>
                        <h5>Here’s how we help</h5>
                        <p>We will assist you throughout your journey of uploading your first material,
                          please send an email to our support team at support@irevize.com, and our
                          team will promptly get in touch with you.</p>
                      </div>
                      <div className="col-md-6">
                        <img src="assets/images/record-video.jpg" alt="Record Video Icon"/>
                      </div>
                    </div>

                  </div>
                  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">

                    <div className="row">
                      <div className="col-md-6">
                        <p>Create and upload relevant material in your sample papers/notes. The more
                          relevant your material is, the higher the chances of earning. iRevize takes
                          care of marketing.</p>
                        <p>just upload the material, and we will ensure you start making a significant
                          income from the very first day.</p>
                        <h5>Bonus Tip</h5>
                        <p>Always share information about yourself in the profile section. Sharing
                          information about yourself always helps increase sales. For any support you
                          may need at any step, you can connect with us at support@irevize.com.</p>
                      </div>
                      <div className="col-md-6">
                        <img src="assets/images/launch-course.jpg" alt="Launch Course Icon"/>
                      </div>
                    </div>

                  </div>
                </div>


              </div>
            </div>


          </div>
        </div>
        <div className="section">
          <div className="container text-center">
            <div className="row">
              <div className="col-md-12">
                <div className="toosmlwdth pdtarea">
                  <div className="row" >
                    <div className="col-md-12">
                      <h3 className="btn-shine text-center">We are with you</h3>
                      <p>Our team is always here to support you at any given point.
                        You can always connect with us for help related to onboarding, your first upload,
                        the most selling subject, or your income. We are just one email away at support@irevize.com or
                        a phone call away at 9870107120.</p>
                      <a href="#">Need more details before you start? Learn more.</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section greybg insbox">
          <div className="container">
            <h3 className="btn-shine text-center">Start selling on iRevize.com today</h3>
            <h5>Become a part of the best upcoming edtech company across the nation.</h5>
            <Link onClick={() => handleOpen(true)} to='#' className="btn btn-fill-blue rounded-0 staggered-animation" data-animation="slideInLeft" data-animation-delay="1.5s">
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
export default TeachOnIrevizePage;
