import React, { useEffect, useState } from 'react';
import {
  Dialog, 
  DialogTitle, DialogContent, DialogActions,
  IconButton,
  Button,
  styled,
  CircularProgress, Box
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    minWidth: '50%',
    // width: '400%;'
  },

}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function PreviewModalDialog({ previewModalOpen, paper, setPreviewModalOpen }) {
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (paper.id) {
      setRowData([]);
      fetchData();
    }
  }, [paper]);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      var api_url = '/api/frontend/get-pdf-preview';
      axios.post(api_url, {
        row_id: paper.id
      })
        .then(response => {
          if (response.data.response_code === 200) {
            setRowData(response.data.data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
    }
  }

  const handleClose = () => {
    setPreviewModalOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={previewModalOpen}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Preview
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <div className="row" >
            <Box sx={{ textAlign: 'center', margin: '0px auto', minHeight: '500px' }}>
              {(loading) ? <CircularProgress /> :
                <img src={(rowData.pdf_thumb_nail_url ?? rowData.pdf_thumb_nail_url)} alt={paper.topic_title} width={'100%'} />
              }
            </Box>
          </div>
        </DialogContent >
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog >
    </div >
  );
}