import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import {
    Avatar, IconButton, ListItemIcon, Menu, MenuItem, Select
} from '@mui/material';
import { Formik } from 'formik';
import swal from 'sweetalert';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import LogoutIcon from '@mui/icons-material/Logout';
import FavoriteIcon from '@mui/icons-material/Favorite';

import MiniCart from './MiniCart';
import { useAuth } from './../../../contexts/AuthContext';
import { useSearchFilter } from './../../../contexts/SearchFilterContext';
import logo from '../../../images/logo.png';
import './Header.scss';

function Header() {
    const { filterOptions, setFilterOptions } = useSearchFilter();
    const [topMenues, setTopMenues] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

    const { authSignOut, isAuthenticated, auth_user_type, setOpenAuth, setIsSignUp, setUserType, checkAuth, checkUserAuth, logoutAuth } = useAuth();
    const handleOpen = (authState) => {
        if (authState) {
            setIsSignUp(true);
            if (window.location.pathname === '/join-now' || window.location.pathname === '/irevize/join-now' || 
            window.location.pathname === '/teach-on-irevize' || window.location.pathname === '/irevize/teach-on-irevize') {
                setUserType(2);
            }
        } else {
            setIsSignUp(false);
        }
        setOpenAuth(true);
    }

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        const token = (user) ? user.token : false;
        if (token) {
            // const decodedToken = decode(token);
            // if (decodedToken.exp * 1000 < new Date().getTime()) logout();
        }
        setUser(JSON.parse(localStorage.getItem('profile')));
        if (!topMenues) {
            getTopMenus();
        }
    }, [location, isAuthenticated]);

    const getTopMenus = () => {
        axios.get('/sanctum/csrf-cookie').then(async response => {
            axios.post('/api/frontend/get-top-menues').then((res) => {
                var data = res.data;
                if (data !== undefined) {
                    if (data.response_code === 200) {
                        setTopMenues(data.data);
                    } else if (data.response_code === '401') {
                        console.log('Error', data);
                    } else {
                        console.log('Error', data);
                    }
                }
            });
        });
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        swal({
            title: "Are you sure?",
            text: "You want to sign out.",
            icon: "info",
            buttons: [
                'No, cancel it!',
                'Yes, I am sure!'
            ],
        }).then(function (isConfirm) {
            if (isConfirm) {
                setOpenAuth(false);
                /**
                 * User logout
                 */
                authSignOut()
            }
        });
    };



    const openMenu = () => {
        document.getElementById('sidebar').style.left = '-8px';
    };



    const handleFormSubmit = (values, errors) => {
        setFilterOptions({
            selected_type: values.selected_type,
            q: values.q,
            selected_organization: filterOptions.selected_organization,
            selected_school: filterOptions.selected_school,
            selected_class: filterOptions.selected_class,
            selected_board: filterOptions.selected_board,
            selected_university: filterOptions.selected_university,
            selected_entrance_exam: filterOptions.selected_entrance_exam,
            selected_competitive_exam: filterOptions.selected_competitive_exam,
            selected_subjects: filterOptions.selected_subjects,
            selected_rating: filterOptions.selected_rating,
        });
        if (values.selected_type) {
            navigate(`/search/?selected_type=${values.selected_type}&q=${values.q}`);
            // navigate(`/search/${values.selected_type}?q=${values.q}`);
        } else {
            navigate(`/search?q=${values.q}`);
        }

    };

    return (
        <>
            <header className="header_wrap fixed-top header_with_topbar">
                <div className="top-header">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-2 col-sm-6">
                                <Link to="/"> <img src={logo} alt="logo" height="60px;" /> </Link>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-expanded="false">
                                    <span className="ion-android-menu"></span>
                                </button>
                            </div>
                            <div className="col-md-8">
                                <div className="product_search_form">
                                    <Formik
                                        onSubmit={(values, errors) => {
                                            handleFormSubmit(values, errors);
                                        }
                                        }
                                        enableReinitialize={true}
                                        initialValues={filterOptions}
                                    >
                                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                            <form onSubmit={handleSubmit}>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="custom_selectd">
                                                            <Select
                                                                displayEmpty
                                                                className="flt_type_select"
                                                                labelId="selected_type"
                                                                id="selected_type"
                                                                name="selected_type"
                                                                value={values.selected_type}
                                                                onChange={handleChange}
                                                                variant="standard"
                                                                onBlur={handleBlur}
                                                                error={Boolean(errors.selected_type && touched.selected_type)}
                                                                sx={{
                                                                    "& fieldset": {
                                                                        border: "none",
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value='' key="flt_type0">Select</MenuItem>
                                                                <MenuItem value='learning' key="flt_type1">Learning Material</MenuItem>
                                                                <MenuItem value='practice' key="flt_type2">Practice Tests</MenuItem>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <input
                                                        className="form-control search-field"
                                                        placeholder="Search for adventure..."
                                                        required=""
                                                        type="text"
                                                        name="q"
                                                        onBlur={handleBlur}
                                                        value={values.q}
                                                        onChange={handleChange}
                                                    />
                                                    <button type="submit" className="search_btn" aria-label="Name">
                                                        <i className="linearicons-magnifier"></i>
                                                    </button>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                            <div className="col-md-2 nwonestl1  col-sm-12">
                                <div className="text-center text-md-end">
                                    <ul className="header_list navbar-nav attr-nav top-header-cart">
                                        <li className="dropdown cart_dropdown">
                                            <MiniCart />
                                        </li>
                                        {user ? (
                                            <>
                                                <li>
                                                    <IconButton
                                                        onClick={handleClick}
                                                        size="small"
                                                        sx={{ ml: 2 }}
                                                        aria-controls={open ? 'account-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                    >
                                                        <Avatar className="header__profile" src={user.profile_image} alt={user.username}>{user.username[0]}</Avatar>
                                                    </IconButton>
                                                </li>
                                                <Menu
                                                    className="header__muiMenu"
                                                    anchorEl={anchorEl}
                                                    id="account-menu"
                                                    open={open}
                                                    onClose={handleClose}
                                                    onClick={handleClose}
                                                    PaperProps={{
                                                        elevation: 0,
                                                        sx: {
                                                            overflow: 'visible',
                                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                            mt: 1.5,
                                                            '& .MuiAvatar-root': {
                                                                width: 26,
                                                                height: 26,
                                                                mr: 1,
                                                            },
                                                            '&:before': {
                                                                content: '""',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 14,
                                                                width: 10,
                                                                height: 10,
                                                                bgcolor: 'background.paper',
                                                                transform: 'translateY(-50%) rotate(45deg)',
                                                                zIndex: 0,
                                                            },
                                                        },
                                                    }}
                                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                >
                                                    <MenuItem onClick={() => navigate('/dashboard')}>
                                                        <ListItemIcon>
                                                            <DashboardIcon />
                                                        </ListItemIcon>
                                                        <div className="header__profileMenu">
                                                            <span>Dashboard</span>
                                                            {/* <span>Manage your profile, </span>
                                                            <span>login details and password</span> */}
                                                        </div>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => navigate('/dashboard/order/order-history')}>
                                                        <ListItemIcon>
                                                            <ViewListIcon />
                                                        </ListItemIcon>
                                                        <div className="header__profileMenu">
                                                            <span>My Orders</span>
                                                            {/* <span>See order details, download e-papers,</span>
                                                            <span>Cancel order</span> */}
                                                        </div>
                                                    </MenuItem>
                                                    {(user.auth_type === 3 || user.auth_type === '3') ? (
                                                        <MenuItem onClick={() => navigate('/dashboard/wishlists')}>
                                                            <FavoriteIcon>
                                                                <FavoriteIcon />
                                                            </FavoriteIcon>
                                                            <div className="header__profileMenu">
                                                                <span>&nbsp;&nbsp;My Wishlist</span>
                                                            </div>
                                                        </MenuItem>
                                                    ) : ''}
                                                    <MenuItem onClick={logout}>
                                                        <ListItemIcon>
                                                            <LogoutIcon fontSize="medium" />
                                                        </ListItemIcon>
                                                        <div className="header__profileMenu">
                                                            <span>Sign Out</span>
                                                        </div>
                                                    </MenuItem>
                                                </Menu>
                                            </>

                                        ) : (
                                            <>
                                                <li><Link className="nav-link" to="#" onClick={() => handleOpen(false)}><span>Sign In</span></Link></li>
                                                <li><Link className="nav-link btnstrong" to="#" onClick={() => handleOpen(true)}><span>Sign Up</span></Link></li>
                                            </>
                                        )}
                                        {/* <li><button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-expanded="false">
                                            <span className="ion-android-menu"></span>
                                        </button></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {topMenues && (
                    <div className="bottom_header dark_skin main_menu_uppercase">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg">
                                <div className="collapse navbar-collapse justify-content-start" id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        {
                                            topMenues.map((row, index) => (
                                                <li key={`top-menu-${index}`}>
                                                    {
                                                        row.school_class ? (
                                                            <>
                                                                <Link className={(filterOptions.selected_school === row.id) ? 'dropdown-toggle nav-link active-nav' : 'dropdown-toggle nav-link'} to="" data-bs-toggle="dropdown">{row.name}</Link>
                                                                <div className="dropdown-menu">
                                                                    <ul className="submenu d-lg-flex1">
                                                                        {row.school_class.map((row_chield, index) => (
                                                                            <li key={`top-menu-chield-${index}`}>
                                                                                <Link className="dropdown-item nav-link nav_item" to={row_chield.link_url}>{row_chield.class_name} </Link>
                                                                            </li>
                                                                        ))

                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </>
                                                        ) : (row.entrance_exams ? (
                                                            <>
                                                                <Link className={(filterOptions.selected_organization === 3) ? 'dropdown-toggle nav-link active-nav' : 'dropdown-toggle nav-link'} to="" data-bs-toggle="dropdown">{row.name}</Link>
                                                                <div className="dropdown-menu">
                                                                    <ul className="submenu d-lg-flex1">
                                                                        {row.entrance_exams.map((row_chield, index) => (
                                                                            <li key={`top-menu-chield-${index}`}>
                                                                                <Link className="dropdown-item nav-link nav_item" to={row_chield.link_url}>{row_chield.exam_name} </Link>
                                                                            </li>
                                                                        ))

                                                                        }
                                                                    </ul>
                                                                </div>
                                                                {/* <div className="dropdown-menu">
                                                                    <ul className="mega-menu d-lg-flex">
                                                                        <li className="mega-menu-col col-lg-9">
                                                                            <ul className="d-lg-flex">
                                                                                <li className='mega-menu-col col-lg-4'>
                                                                                    <ul>
                                                                                        {row.entrance_exams.map((row_chield, index) => (
                                                                                            <li className='dropdown-header' key={`top-menu-chield-${index}`}>
                                                                                                <Link className="dropdown-item nav-link nav_item" to="#">{row_chield.exam_name} </Link>
                                                                                            </li>
                                                                                        ))
                                                                                        }
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </div> */}
                                                            </>
                                                        ) : (row.competitive_exams ? (
                                                            <>
                                                                <Link className={(filterOptions.selected_organization === 4) ? 'dropdown-toggle nav-link active-nav' : 'dropdown-toggle nav-link'} to="" data-bs-toggle="dropdown">{row.name}</Link>
                                                                <div className="dropdown-menu">
                                                                    <ul className="submenu d-lg-flex1">
                                                                        {row.competitive_exams.map((row_chield, index) => (
                                                                            <li key={`top-menu-chield-${index}`}>
                                                                                <Link className="dropdown-item nav-link nav_item" to={row_chield.link_url} title={row_chield.exam_name}>{row_chield.exam_name.replace(/(.{30})..+/, "$1…")}</Link>
                                                                            </li>
                                                                        ))

                                                                        }
                                                                    </ul>
                                                                </div>
                                                                {/* <div className="dropdown-menu">
                                                                    <ul className="mega-menu1 d-lg-flex1">
                                                                        {row.competitive_exams.map((row_chield, index) => (
                                                                            <li key={`top-menu-chield-${index}`}>
                                                                                <Link className="dropdown-item nav-link nav_item" to="#" title={row_chield.exam_name}>{row_chield.exam_name.replace(/(.{30})..+/, "$1…")}</Link>
                                                                            </li>
                                                                        ))

                                                                        }
                                                                    </ul>
                                                                </div> */}
                                                            </>
                                                        ) : (
                                                            <Link className="nav-link nav_item" to="/">{row.name}</Link>
                                                        )))
                                                    }
                                                </li>
                                            )
                                            )
                                        }
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div >
                )
                }

            </header >
        </>
    );
}
export default Header;
