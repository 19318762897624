import React, { useEffect, useState, useRef } from 'react';
import {
  Link, NavLink, useLocation, useNavigate,
} from 'react-router-dom';

import { useDispatch } from 'react-redux';
// import { signout } from './../../actions/auth';
import logo from '../../images/logo.png';
import swal from 'sweetalert';
import ContractAgreement from './../../components/Dashboard/ContractAgreement';
import { useAuth } from './../../contexts/AuthContext';


function Header({ openSideNav, setOpenSideNav }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const { authSignOut, isAuthenticated, auth_user_type, setOpenAuth, setIsSignUp, checkAuth, checkUserAuth, logoutAuth } = useAuth();

  const ref = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  /**
   * User Signout after confirmation!
   */
  const logout = async () => {
    swal({
      title: "Are you sure?",
      text: "You want to sign out.",
      icon: "info",
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
    }).then(function (isConfirm) {
      if (isConfirm) {
        /**
         * User logout
         */
        authSignOut();
      }
    });
  };

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isMenuOpen, isAuthenticated])

  return (
    <>
      <header id="header" className="header fixed-top d-flex align-items-center">
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/" className="logo d-flex align-items-center">
            <img src={logo} alt="iRevize Logo" />
          </Link>
          <i className="bi bi-list toggle-sidebar-btn dashboard-mobile-icon" onClick={(e) => {
            if (openSideNav) {
              setOpenSideNav(false);
            } else {
              setOpenSideNav(true);
            }
            return false;
          }}></i>
        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">
              <Link onClick={() => setIsMenuOpen(true)} className="nav-link nav-profile align-items-center pe-0 dropdown-toggle1" to="#" data-bs-toggle="dropdown">
                <span className="d-none d-md-block ps-2" style={{ float: 'Left', clear: 'both', textAlign: 'right', marginRight: '10px' }}>
                  {user.username}
                  <br />
                  <span style={{ textDecoration: 'none', color: '#5F5F5F', fontWeight: '400', fontSize: '13px' }}>
                    {(user.auth_type === 2 || user.auth_type === "2") ? 'As Teacher' : 'As Student'}
                  </span>
                </span>
                <img src={user.profile_image} alt={user.username} className="rounded-circle dropdown-toggle" style={{ width: '40px' }} />
              </Link>

              <ul ref={ref} className={(isMenuOpen) ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile open" : "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile close"}>
                <li>
                  <Link onClick={() => setIsMenuOpen(false)} className="dropdown-item d-flex align-items-center" to="/dashboard/profile/profile-settings">
                    <i className="bi bi-gear"></i>
                    <span>Profile Settings</span>
                  </Link>
                </li>
                {(user.auth_type === 2 || user.auth_type === "2") ? (
                  <>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link onClick={() => setIsMenuOpen(false)} className="dropdown-item d-flex align-items-center" onClick={() => setOpen(true)} >
                        <i className="bi bi-c-circle"></i>
                        <span>Teacher’s Contract</span>
                      </Link>
                      <ContractAgreement open={open} setOpen={setOpen} agreeBtn={false} />
                    </li>

                  </>
                ) : ''}
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <Link onClick={() => setIsMenuOpen(false)} className="dropdown-item d-flex align-items-center" to="/dashboard/help/videos">
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link onClick={() => setIsMenuOpen(false)} className="dropdown-item d-flex align-items-center" onClick={logout}>
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav >
      </header >
    </>
  );
}
export default Header;
