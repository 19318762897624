import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import addPaperImage from './../../../images/add-paper.png';
import swal from 'sweetalert';
import axios from 'axios';
import Box from '@mui/material/Box';
import {
  Card, CardHeader, CardContent,
  Divider, Button,
  Grid, Select, MenuItem, TextField, FormControl,
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';


const validationSchema = Yup.object().shape({
  ticket_title: Yup.string()
    .required('Ticket title is required!'),
  description: Yup.string()
    .required('Ticket description is required!'),
});

function AddTicket() {
  const [orders, setOrders] = useState(false);
  const [orderProducts, setOrderProducts] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialValues = {
    order_id: '',
    product_id: '',
    ticket_title: '',
    description: '',
    status: 'pending',
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, []);
  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-orders-list')
          .then(response => {
            if (response.data.response_code === 200) {
              setOrders(response.data.data);
            } else if (response.data.response_code === 401) {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.error(error);
          });

      });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Fetching data from server.
   */
  const fetchOrderProducts = async (order_id) => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-order-products', {
          order_id: order_id
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setOrderProducts(response.data.data);
            } else if (response.data.response_code === 401) {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.error(error);
          });

      });
    } catch (e) {
      setLoading(false);
    }
  }
  const handleFormSubmit = (values, errors) => {
    try {
      axios.get('/sanctum/csrf-cookie').then(response => {
        // const uploadFormData = new FormData();
        // for (let iKey in values) {
        //   if (iKey === 'learning_material_file' || iKey === 'question_paper_file' || iKey === 'answer_paper_file' || iKey === 'paper_thumbnail_file') {
        //     uploadFormData.append(iKey, fileData[iKey])
        //   } else {
        //     uploadFormData.append(iKey, values[iKey])
        //   }
        // }
        // const config = { headers: { "Content-Type": "multipart/form-data" } };
        axios.post('/api/dashboard/add-support-ticket', values)
          .then(response => {
            if (response.data.response_code === 200) {
              swal({
                // title: "Good job!",
                text: response.data.message,
                icon: "success",
              });
              navigate('/dashboard/supports');
            } else {
              swal({
                title: "Warning!",
                text: response.data.message,
                icon: "warning",
              });
              errors.setErrors(response.data.errors);
            }
            setLoading(false);
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="pagetitle">
        <h1>Adding Support ticket</h1>
        {/* <h6>Basic info, about the test paper</h6> */}
      </div>
      <section className="section dashboard">
        <Card title="Support ticket">
          <CardHeader
            title=" "
            titleTypographyProps={{
              variant: "h4",
              fontSize: '16px',
              fontWeight: '500',
            }}
            action={
              <Button to="/dashboard/supports" component={Link} variant="contained" color="inherit" startIcon={<NavigateBeforeIcon />}>
                Back
              </Button>
            }
          />
          <Divider />
          <CardContent>
            <Box sx={{ width: '100%' }}>
              <Formik
                onSubmit={(values, errors) => {
                  handleFormSubmit(values, errors);
                }
                }
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container style={{ padding: '20px' }} spacing={3}>
                      <Grid item sm={8} xs={12}>
                        <div className='price-detail-left'>
                          <label htmlFor="order_id" className='form-label'>Select Order</label>
                          <FormControl fullWidth
                            sx={{ mb: 3 }}
                            size="small"
                          >
                            <Select
                              displayEmpty
                              labelId="order_id"
                              id="order_id"
                              name="order_id"
                              value={values.order_id}
                              // onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e)
                                var order_id = (e.target.value) ? parseInt(e.target.value) : 0;
                                fetchOrderProducts(order_id);
                              }}

                              variant="outlined"
                              onBlur={handleBlur}
                              error={Boolean(errors.order_id && touched.order_id)}
                            >
                              <MenuItem value='' key="ords">Select</MenuItem>
                              {orders && orders.map((row) =>
                                <MenuItem value={row.id} key={`select_ord_${row.id}`}>{row.order_id}</MenuItem>
                              )}
                            </Select>
                            {(errors.order_id)
                              && (touched.order_id)
                              && <p
                                className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                              >
                                {`${errors.order_id}`}
                              </p>
                            }
                          </FormControl>
                          {orderProducts && (
                            <>
                              <br />
                              <label htmlFor="product_id" className='form-label'>Select Product</label>
                              <FormControl fullWidth
                                sx={{ mb: 3 }}
                                size="small"
                              >
                                <Select
                                  displayEmpty
                                  labelId="product_id"
                                  id="product_id"
                                  name="product_id"
                                  value={values.product_id}
                                  onChange={handleChange}
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  error={Boolean(errors.product_id && touched.product_id)}
                                >
                                  <MenuItem value='' key="prodlist_">Select</MenuItem>
                                  {orderProducts && orderProducts.map((row) =>
                                    <MenuItem value={row.product_id} key={`select_prod_${row.id}`}>{row.topic_title}</MenuItem>
                                  )}
                                </Select>
                                {(errors.product_id)
                                  && (touched.product_id)
                                  && <p
                                    className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                                  >
                                    {`${errors.product_id}`}
                                  </p>
                                }
                              </FormControl>
                            </>
                          )}


                          <br />
                          <label htmlFor="first_name" className='form-label'>Title</label>
                          <TextField
                            fullWidth
                            size="small"
                            type="text"
                            name="ticket_title"
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.ticket_title}
                            onChange={handleChange}
                            helperText={touched.ticket_title && errors.ticket_title}
                            error={Boolean(errors.ticket_title && touched.ticket_title)}
                            sx={{ mb: 3 }}
                          />
                          <br />
                          <label htmlFor="description" className='form-label'>Description</label>
                          <TextField
                            fullWidth
                            multiline={true}
                            minRows={3}
                            size="small"
                            type="text"
                            name="description"
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.description}
                            onChange={handleChange}
                            helperText={touched.description && errors.description}
                            error={Boolean(errors.description && touched.description)}
                            sx={{ mb: 3 }}
                          />
                        </div>
                      </Grid>

                      <Grid item sm={4} xs={12}>
                        <img src={addPaperImage} alt='Add Paper' />
                      </Grid>
                    </Grid>

                    <Grid container style={{ padding: '30px 20px 0px' }} spacing={4}>

                      <Grid item sm={6} xs={12} lg={3}>
                      </Grid>
                      <Grid item sm={4} xs={12} lg={2}>
                        <Button
                          fullWidth
                          variant="contained"
                          type='submit'
                          sx={{ mb: 2, mt: 4 }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>

            </Box>
          </CardContent>
        </Card>
      </section >
    </>
  );
}
export default AddTicket;
