import React from 'react';
import ProfileInfo from '../ProfileInfo/ProfileInfo';

// import LoginDetails from '../LoginDetails/LoginDetails';
// import ProfileVerificationHeader from '../ProfileVerificationHeader/ProfileVerificationHeader';

import './ProfileDetailsRight.scss';

function ProfileDetailsRight() {
  return (
    <div className="ProfileDetailsRight">
      {/* <ProfileVerificationHeader /> */}
      <ProfileInfo />
      {/* <LoginDetails /> */}
    </div>
  );
}

export default ProfileDetailsRight;
