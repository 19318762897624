import React from 'react';
import SEO from './../../layouts/SEO';
import ForgotPassword from '../../../components/Auth/ForgotPassword';
import HomeBanner from './HomeBanner';
import Exams from './Exams';
import UPPolice from './Papers/UPPolice';
import RrbAlp from './Papers/RrbAlp';
import Crpf from './Papers/Crpf';
import BankPo from './Papers/BankPo';
import SscMts from './Papers/SscMts';
import Ctet from './Papers/Ctet';
import TenthClass from './Papers/TenthClass';
import JoiningMemberCard from './JoiningMemberCard';

import Testimonials from './Testimonials';
// import DownloadApp from './DownloadApp';

function Home() {
    return (
        <>
            <SEO
                title="LEARNING MATERIAL and PRACTICE TESTS – irevize"
                description="Download Best LEARNING MATERIAL and PRACTICE TESTS for School level, College Level, Competitive Exam, and Entrance Exam"
                keywords="Download LEARNING MATERIAL, Download PRACTICE TESTS"
                canonical="https://irevize.com/"
            >
            </SEO>
            <ForgotPassword />
            <HomeBanner />
            <div className="main_content">
                <Exams></Exams>
                <UPPolice></UPPolice>
                <RrbAlp></RrbAlp>
                <Crpf></Crpf>
                <BankPo></BankPo>
                <SscMts></SscMts>
                <Ctet></Ctet>
                <TenthClass></TenthClass>

                <div className="container">
                    <JoiningMemberCard></JoiningMemberCard>
                </div>
                <Testimonials></Testimonials>
                {/* <DownloadApp></DownloadApp> */}
            </div>
        </>
    );
}
export default Home;
