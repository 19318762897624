import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button, Grid
} from '@mui/material';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import IconButton from '@mui/material/IconButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { FileUploader } from "react-drag-drop-files";

const validationSchema = Yup.object().shape({
  //   // question_paper_file: Yup.number()
  //   //   .required('Organization Type is required!'),
});

// const paperFileTypes = ["JPG", "PNG", "PDF"];
const paperFileTypes = ["PDF"];
const thumbFileTypes = ["JPG", "PNG", "JPEG"];

// function UploadQuestionPaper(props) {
const UploadQuestionPaper = (props) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = (values, errors) => {
    if (props.formData.type === 'learning') {
      if (props.fileData.learning_material_file) {
        if (props.fileData.learning_material_file.size > 52521850) {
          return errors.setErrors({ 'learning_material_file': 'Max allowed size is 50MB!' });
        }
      } else {
        return errors.setErrors({ 'learning_material_file': 'You must be upload Learning Material!' });
      }
    } else {
      if (props.fileData.question_paper_file) {
        if (props.fileData.question_paper_file.size > 52521850) {
          return errors.setErrors({ 'question_paper_file': 'Max allowed size is 50MB!' });
        }
      } else {
        return errors.setErrors({ 'question_paper_file': 'You must be upload Question Paper!' });
      }
      if (props.fileData.answer_paper_file) {
        if (props.fileData.answer_paper_file.size > 52521850) {
          return errors.setErrors({ 'answer_paper_file': 'Max allowed size is 50MB!' });
        }
      } else {
        return errors.setErrors({ 'answer_paper_file': 'You must be upload Answer Paper!' });
      }
    }
    if (props.fileData.paper_thumbnail_file) {
      if (props.fileData.paper_thumbnail_file.size > 52521850) {
        return errors.setErrors({ 'paper_thumbnail_file': 'Max allowed size is 50MB!' });
      }
    } else {
      // return errors.setErrors({ 'paper_thumbnail_file': 'You must be upload Answer Paper!' });
    }
    props.next(values);
  }

  const handleSaveAsDraftSubmit = (values) => {
    props.saveAsDraftSubmit(values);
  }

  return (
    <>
      <Formik
        onSubmit={(values, errors) => {
          handleFormSubmit(values, errors);
        }
        }
        initialValues={props.fileData}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, inputName }) => (
          <form onSubmit={handleSubmit}>
            <Grid container style={{ padding: '20px' }} spacing={3}>
              {(props.formData.type === 'learning') ? (
                <>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="question_paper_file" className='form-label'>Upload Learning Material</label>
                    <div className='file-grag-drop-wrap'>
                      <FileUploader
                        multiple={false}
                        label={'Drag & drop file here'}
                        handleChange={(file) => {
                          setFieldValue(inputName, file);
                          props.setFileData({ ...props.fileData, ['learning_material_file']: file })
                        }
                        }
                        // onChange={handleChange}
                        value={values.learning_material_file}
                        name="learning_material_file"
                        types={paperFileTypes}
                      />
                    </div>
                    {(props.fileData.learning_material_file) && (
                      <p>{props.fileData.learning_material_file.name}</p>
                    )}
                    {(errors.learning_material_file)
                      && (touched.learning_material_file)
                      && <div style={{ color: '#ff3d57' }}>{`${errors.learning_material_file}`}</div>}

                    {(props.formData.learning_material_file_url) && (
                      <>
                        <div className='uploaded-file'>
                          <a title={props.formData.learning_material_file_url} href={props.formData.learning_material_file_url} target='_blank'>
                            <SystemUpdateAltIcon />
                          </a>
                        </div>
                      </>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="question_paper_file" className='form-label'>Upload Question Paper</label>
                    <div className='file-grag-drop-wrap'>
                      <FileUploader
                        multiple={false}
                        label={'Drag & drop file here'}
                        handleChange={(file) => {
                          setFieldValue(inputName, file);
                          props.setFileData({ ...props.fileData, ['question_paper_file']: file })
                        }
                        }
                        // onChange={handleChange}
                        value={values.question_paper_file}
                        name="question_paper_file"
                        types={paperFileTypes}
                      />
                    </div>
                    {(props.fileData.question_paper_file) && (
                      <p>{props.fileData.question_paper_file.name}</p>
                    )}
                    {(errors.question_paper_file)
                      && (touched.question_paper_file)
                      && <div style={{ color: '#ff3d57' }}>{`${errors.question_paper_file}`}</div>}

                    {(props.formData.paper_file_url) && (
                      <>
                        <div className='uploaded-file'>
                          <a title={props.formData.paper_file_url} href={props.formData.paper_file_url} target='_blank'>
                            <SystemUpdateAltIcon />
                          </a>
                        </div>
                      </>
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <label htmlFor="answer_paper_file" className='form-label'>Upload Answer Paper</label>
                    <div className='file-grag-drop-wrap'>
                      <FileUploader
                        multiple={false}
                        label={'Drag & drop file here'}
                        handleChange={(file) =>
                          props.setFileData({ ...props.fileData, ['answer_paper_file']: file })
                        }
                        name="answer_paper_file"
                        types={paperFileTypes}
                      />
                    </div>
                    {(props.fileData.answer_paper_file) && (
                      <p>{props.fileData.answer_paper_file.name}</p>
                    )}
                    {(errors.answer_paper_file)
                      && (touched.answer_paper_file)
                      && <div style={{ color: '#ff3d57' }}>{`${errors.answer_paper_file}`}</div>}
                    {(props.formData.answer_file_url) && (
                      <>
                        <div className='uploaded-file'>
                          <a title={props.formData.answer_file_url} href={props.formData.answer_file_url} target='_blank'>
                            <SystemUpdateAltIcon />
                          </a>
                        </div>
                      </>
                    )}
                  </Grid>
                </>
              )}

              <Grid item sm={6} xs={12}>
                <label htmlFor="indian_state_abbreviation" className='form-label'>Upload Paper Thumbnail</label>
                <div className='file-grag-drop-wrap'>
                  <FileUploader
                    label={'Drag & drop file here'}
                    handleChange={(file) =>
                      props.setFileData({ ...props.fileData, ['paper_thumbnail_file']: file })
                    }
                    multiple={false}
                    name="paper_thumbnail_file"
                    types={thumbFileTypes}
                  />
                </div>
                {(props.fileData.paper_thumbnail_file) && (
                  <p>{props.fileData.paper_thumbnail_file.name}</p>
                )}
                {(errors.paper_thumbnail_file)
                  && (touched.paper_thumbnail_file)
                  && <div style={{ color: '#ff3d57' }}>{`${errors.paper_thumbnail_file}`}</div>}

                {(props.formData.thumb_nail_url) && (
                  <>
                    <div className='uploaded-file'>
                      <img src={props.formData.thumb_nail_url} style={{ width: '100px' }} title={props.formData.answer_file_url} alt={props.formData.answer_file_url} />
                    </div>
                  </>
                )}

              </Grid>
            </Grid>

            <Grid container style={{ padding: '30px 20px 0px' }} spacing={4}>
              <Grid item sm={6} xs={12} lg={2}>
                <Button
                  color="inherit"
                  variant="contained"
                  type='button'
                  onClick={() => props.prev(values)}
                  sx={{ mb: 2, mt: 1 }}
                >
                  <NavigateBeforeIcon /> Back
                </Button>
              </Grid>
              <Grid item sm={6} xs={12} lg={4}>
              </Grid>
              <Grid item sm={4} xs={12} lg={3}>
                <Button
                  fullWidth
                  disabled={loading}
                  variant="contained"
                  type='button'
                  sx={{ mb: 2, mt: 1 }}
                  onClick={() => handleSaveAsDraftSubmit(values)}
                >
                  Save as Draft
                </Button>
              </Grid>
              <Grid item sm={4} xs={12} lg={3}>
                <Button
                  fullWidth
                  disabled={loading}
                  variant="contained"
                  type='submit'
                  sx={{ mb: 2, mt: 1 }}
                >
                  Next <NavigateNextIcon />
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>

  );
}

export default UploadQuestionPaper;
