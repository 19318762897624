import React, { useState, useEffect } from 'react';
import {
  Button, Dialog,
  Grid, Select, MenuItem, TextField, FormControl,
} from '@mui/material';

import swal from 'sweetalert';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './EditProfile.scss';

const validationSchema = Yup.object().shape({
});
function EditProfile({ userProfileInfo, open, setOpen, handleProfileUpdate }) {
  const handleClose = () => setOpen(false);
  const [formData, setFormData] = useState(userProfileInfo);
  const [organizationType, setOrganizationType] = useState(false);
  const [qualification, setQualification] = useState(false);

  useEffect(() => {
    fetchData();
    setFormData(userProfileInfo);
  }, [userProfileInfo]);

  const fetchData = () => {
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/dashboard/get-organization-types').then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            setOrganizationType(data.data);
          } else if (data.response_code === '401') {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
      axios.post('/api/dashboard/get-qualifications').then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.response_code === 200) {
            setQualification(data.data);
          } else if (data.response_code === '401') {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  }
  const handleFormSubmit = (values, errors) => {
    axios.get('/sanctum/csrf-cookie').then(async response => {
      axios.post('/api/user/update-user-profile', values).then((res) => {
        var data = res.data;
        if (data !== undefined) {
          if (data.status_code === 200) {
            swal({
              // title: "Done!",
              text: data.message,
              icon: "success",
              buttons: false,
              timer: 2000
            });
            setOpen(false);
            localStorage.setItem('profile', JSON.stringify(data.data));
            handleProfileUpdate(true);
          } else if (data.status_code === '401') {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          } else {
            swal({
              title: "Server not responding!",
              text: 'Please try again!',
              icon: "warning",
            });
          }
        }
      });
    });
  };
  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <div className="editProfile">
        <div className="editProfile__head">
          <h3>Edit Profile</h3>
        </div>
        <div className="editProfile__content">
          <Formik
            onSubmit={(values, errors) => {
              handleFormSubmit(values, errors);
            }
            }
            initialValues={formData}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, inputName }) => (
              <form onSubmit={handleSubmit}>
                <div className="editProfile__details">
                  {(formData.user_type === 2 || formData.user_type === '2') ? (
                    <>
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <label htmlFor="qualification" className='form-label'>Name <span className='req-star'>*</span></label>
                          <TextField
                            fullWidth
                            size="small"
                            type="text"
                            name="name"
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.name}
                            onChange={handleChange}
                            helperText={touched.name && errors.name}
                            error={Boolean(errors.name && touched.name)}
                            sx={{ mb: 3 }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <label htmlFor="qualification" className='form-label'>Education/Qualification</label>
                          <TextField
                            fullWidth
                            size="small"
                            type="text"
                            name="qualification"
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.qualification}
                            onChange={handleChange}
                            helperText={touched.qualification && errors.qualification}
                            error={Boolean(errors.qualification && touched.qualification)}
                            sx={{ mb: 3 }}
                          />
                          {/* <FormControl fullWidth
                            sx={{ mb: 3 }}
                            size="small"
                          >
                            <Select
                              displayEmpty
                              labelId="qualification"
                              id="qualification"
                              name="qualification"
                              value={values.qualification}
                              onChange={handleChange}
                              variant="outlined"
                              onBlur={handleBlur}
                              error={Boolean(errors.qualification && touched.qualification)}
                            >
                              <MenuItem value='' key="emp_sub">Select Qualification</MenuItem>
                              {qualification && qualification.map((row) =>
                                <MenuItem value={row.id} key={`quali_${row.id}`}>{row.name}</MenuItem>
                              )}
                            </Select>
                            {(errors.qualification)
                              && (touched.qualification)
                              && <p
                                className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                              >
                                {`${errors.qualification}`}
                              </p>
                            }
                          </FormControl> */}
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <label htmlFor="organization_type" className='form-label'>Organization Type</label>
                          <FormControl fullWidth
                            sx={{ mb: 3 }}
                            size="small"
                          >
                            <Select
                              displayEmpty
                              labelId="organization_type"
                              id="organization_type"
                              name="organization_type"
                              value={values.organization_type}
                              onChange={handleChange}
                              variant="outlined"
                              onBlur={handleBlur}
                              error={Boolean(errors.organization_type && touched.organization_type)}
                            >
                              <MenuItem value='' key="org_type__sub">Select Organization</MenuItem>
                              {organizationType && organizationType.map((row) =>
                                <MenuItem value={row.id} key={`org_type_${row.id}`}>{row.name}</MenuItem>
                              )}
                            </Select>
                            {(errors.organization_type)
                              && (touched.organization_type)
                              && <p
                                className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                              >
                                {`${errors.organization_type}`}
                              </p>
                            }
                          </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <label htmlFor="experience_as_teacher" className='form-label'>Experience as a Teacher</label>
                          <FormControl fullWidth
                            sx={{ mb: 3 }}
                            size="small"
                          >
                            <Select
                              displayEmpty
                              labelId="experience_as_teacher"
                              id="experience_as_teacher"
                              name="experience_as_teacher"
                              value={values.experience_as_teacher}
                              onChange={handleChange}
                              variant="outlined"
                              onBlur={handleBlur}
                              error={Boolean(errors.experience_as_teacher && touched.experience_as_teacher)}
                            >
                              <MenuItem value='' key="exp__sub">Select Experience</MenuItem>
                              <MenuItem value='1' key="exp__sub">0 to 1 year</MenuItem>
                              <MenuItem value='2' key="exp__sub">1 to 2 year</MenuItem>
                              <MenuItem value='5' key="exp__sub">2 to 5 year</MenuItem>
                              <MenuItem value='10' key="exp__sub">5 to 10 year</MenuItem>
                              <MenuItem value='15' key="exp__sub">10 to 15 year</MenuItem>
                            </Select>
                            {(errors.experience_as_teacher)
                              && (touched.experience_as_teacher)
                              && <p
                                className=' Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root'
                              >
                                {`${errors.experience_as_teacher}`}
                              </p>
                            }
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <label htmlFor="about_me" className='form-label'>About Me</label>
                          <TextField
                            fullWidth
                            multiline={true}
                            minRows={3}
                            size="small"
                            type="text"
                            name="about_me"
                            variant="outlined"
                            onBlur={handleBlur}
                            placeholder='Professional summary: Provide a brief overview of your teaching experience, qualifications, and teaching philosophy. Highlight your key strengths and areas of expertise.'
                            value={values.about_me}
                            onChange={handleChange}
                            helperText={touched.about_me && errors.about_me}
                            error={Boolean(errors.about_me && touched.about_me)}
                            sx={{ mb: 3 }}
                          />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <label htmlFor="key_strengths" className='form-label'>Highlight your key strengths</label>
                          <TextField
                            fullWidth
                            multiline={true}
                            minRows={3}
                            size="small"
                            type="text"
                            name="key_strengths"
                            variant="outlined"
                            onBlur={handleBlur}
                            placeholder='Highlight your key strengths and areas of expertise with (,) separated'
                            value={values.key_strengths}
                            onChange={handleChange}
                            helperText={touched.key_strengths && errors.key_strengths}
                            error={Boolean(errors.key_strengths && touched.key_strengths)}
                            sx={{ mb: 3 }}
                          />
                        </Grid>
                      </Grid>
                    </>

                  ) : (
                    //For Student
                    <Grid container spacing={2}>
                      <Grid item sm={12} xs={12}>
                        <label htmlFor="qualification" className='form-label'>Name <span className='req-star'>*</span></label>
                        <TextField
                          fullWidth
                          size="small"
                          type="text"
                          name="name"
                          variant="outlined"
                          onBlur={handleBlur}
                          value={values.name}
                          onChange={handleChange}
                          helperText={touched.name && errors.name}
                          error={Boolean(errors.name && touched.name)}
                          sx={{ mb: 3 }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
                <div className="editProfile__buttons">
                  <Button
                    variant="outlined"
                    type="button"
                    size="small" onClick={handleClose}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="sublit"
                    size="small" onClick={handleSubmit}>
                    Update
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>

      </div>
    </Dialog >

  );
}

export default EditProfile;
