import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './../../../contexts/AuthContext';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function LandingBanner() {
  const { setOpenAuth, setIsSignUp, auth_user_type, setUserType } = useAuth();
  const handleOpen = (authState) => {
    if (!auth_user_type) {
      setIsSignUp(true);
      setOpenAuth(true)
      setUserType(2);
    }
  }
  const options = {
    nav: true,
    dots: false,
    // autoplay: true,
    loop: true,
    margin: 0,
    navText: ['<i class="ion-chevron-left"></i>', '<i class="ion-chevron-right"></i>'],
    responsiveClass: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      }
    },
  };
  return (
    <>
      <div className="banner_section home_banner slide_medium shop_banner_slider staggered-animation-wrap banne-slide">
        <OwlCarousel className='carousel slide owl-carousel owl-theme'
          {...options}>
          <div className="item carousel-inner">
            <img src={process.env.PUBLIC_URL + '/assets/images/banner-landing.jpg'} alt="Landing Banner" className="d-block" style2={{ margin: '0px auto', width: 'auto' }} />
            <div className="banner_slide_content">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-9">
                    <div className="banner_content overflow-hidden">
                      <h2 className="staggered-animation" data-animation="slideInLeft" data-animation-delay="1s">Share your knowledge with India</h2>
                      <h5 className="mb-3 staggered-animation font-weight-light" data-animation="slideInLeft" data-animation-delay="0.5s">
                        <Link to='#'>Sell on Irevize and make an impact in Student lives - Make a name for yourself</Link>
                      </h5>
                      <Link onClick={() => handleOpen(true)} to='#' className="btn btn-fill-blue rounded-0 staggered-animation" data-animation="slideInLeft" data-animation-delay="1.5s">
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div >
    </>
  );
}
export default LandingBanner;
