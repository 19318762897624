import React from 'react';
import { Link } from 'react-router-dom';
function Footer() {
    return (
        <>
            <footer className="footer_dark">
                <div className="footer_top pb_20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-12">
                                        <div className="widget">
                                            <h6 className="widget_title">Collections </h6>
                                            <ul className="widget_links">
                                                <li><Link to="/teach-on-irevize" title='Teach on iRevize'>Teach on iRevize</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-12">
                                        <h6 className="widget_title">Information</h6>
                                        <ul className="widget_links">
                                            <li><Link to="/about-us" title='About Us'>About Us</Link></li>
                                            <li><Link to="/contact-us" title='Contact Us'>Contact Us</Link></li>
                                            {/* <li><Link to="/faqs" title='FAQs'>FAQ's</Link></li> */}
                                        </ul>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-12">
                                        <h6 className="widget_title">Customer</h6>
                                        <ul className="widget_links">
                                            <li><Link to="/refund-policy" title='Refund Policy'>Refund Policy</Link></li>
                                            <li><Link to="/privacy-policy" title='Privacy Policy'>Privacy Policy</Link></li>
                                            <li><Link to="/terms-and-conditions" title='Terms And Conditions'>Terms And Conditions</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-12">
                                        <h6 className="widget_title">Contact Us </h6>
                                        <ul className="widget_links">
                                            <li>A-32b, Top floor, Vishwakarma colony, New Delhi - 110044</li>
                                            <li><Link to="mailto:Support@irevize.com" style={{ color: "#30C4FF" }}>support@irevize.com</Link></li>
                                            <li><Link to="tel:+919870107120" style={{ color: "#30C4FF" }}>+91 9717689100</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom_footer bg_dark4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <p style={{ marginBottom: "0px", lineHeight: "36px" }}>© 2023-24 iRevize</p>
                            </div>
                            <div className="col-md-6">
                                <ul className="social_icons rounded_social" style={{ float: "right" }}>
                                    <li><Link to="https://www.facebook.com/profile.php?id=100095474738499&mibextid=ZbWKwL" target='_blank' className="sc_facebook" title='Facebook Logo'><i className="ion-social-facebook"></i></Link></li>
                                    <li><Link to="https://www.linkedin.com/in/irevize-services-524754285/?originalSubdomain=in" target='_blank' className="sc_twitter" title='linkedIn Logo'><i className="ion-social-linkedin-outline"></i></Link></li>
                                    <li><Link to="https://www.youtube.com/@Irevizes" target='_blank' className="sc_youtube" title='Youtube Logo'><i className="ion-social-youtube-outline"></i></Link></li>
                                    <li><Link to="https://www.instagram.com/irevize_?igsh=MWlxdHl3emw0bGp2cw==" target='_blank' className="sc_instagram" title='Instagram Logo'><i className="ion-social-instagram-outline"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default Footer;
